// src/store/modules/lookup.js
import apiClient from '@/helpers/api.js';
import axios from 'axios';

const state = {
  lookup: null,
  quickTranslation: null,
  lookupLoadingCount: 0,
  translationsLoadingCount: 0,
  lookupSource: null,
  showLookup: false,
};

const getters = {
  lookup: (state) => state.lookup,
  quickTranslation: (state) => state.quickTranslation,
  lookupLoadingCount: (state) => state.lookupLoadingCount,
  translationsLoadingCount: (state) => state.translationsLoadingCount,
  showLookup: (state) => state.showLookup,
};

const actions = {
  lookupWord({ commit, state }, { selectedWord, selectedSentence, textChunkID, textID }) {
    commit('setLookup', null);
    commit('setQuickTranslation', null);
    commit('setShowLookup', true);
    
    if(state.lookupSource) { // If there is a lookup request already in progress, cancel it
        state.lookupSource.cancel('Operation canceled by the user.');
    }
    
    const CancelToken = axios.CancelToken; // Create a new cancel token for this request
    commit('setLookupSource', CancelToken.source());
    
    commit('incrementLookupLoadingCount');
    commit('incrementTranslationsLoadingCount');
    
    apiClient.post("/api/translate", {
        word: selectedWord,
        sentence: selectedSentence,
    }, {
        cancelToken: state.lookupSource.token,
    }).then(response => {
        commit('setQuickTranslation', JSON.parse(response.data));
    }).catch(error => {
        console.error(error);
    }).finally(() => {
        commit('decrementTranslationsLoadingCount');
    })
    
    // Create a data object and conditionally add textID and textChunkID if they are provided
    let data = {
        word_context: selectedWord,
        sentence_context: selectedSentence,
    };
    if (textID) data.text_id = textID;
    if (textChunkID) data.text_chunk_id = textChunkID;
    
    apiClient.post("/api/lookup", data, {
        cancelToken: state.lookupSource.token,
    }).then(threadResponse => {
        commit('setLookup', threadResponse.data);
    }).catch(error => {
        console.error(error);
    }).finally(() => {
        commit('decrementLookupLoadingCount');
    })
  },
  hideLookup({ commit }) {
    commit('setShowLookup', false);
    commit('setLookup', null);
  },
};

const mutations = {
  setLookup(state, lookup) {
    state.lookup = lookup;
  },
  setQuickTranslation(state, quickTranslation) {
    state.quickTranslation = quickTranslation;
  },
  setLookupSource(state, lookupSource) {
    state.lookupSource = lookupSource;
  },
  setShowLookup(state, showLookup) {
    state.showLookup = showLookup;
  },
  incrementLookupLoadingCount(state) {
    state.lookupLoadingCount++;
  },
  decrementLookupLoadingCount(state) {
    state.lookupLoadingCount--;
  },
  incrementTranslationsLoadingCount(state) {
    state.translationsLoadingCount++;
  },
  decrementTranslationsLoadingCount(state) {
    state.translationsLoadingCount--;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
