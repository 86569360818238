<template>
  <div class="chat-container">
    <!-- <div class="chats-history">
      <h2>Chats History</h2>
      <div v-for="thread in chatThreads" :key="thread.id">
        <ul>
          <li>
            <div class="name">{{ thread.word_context }}</div>
            <button @click="startChat(thread.word_context, thread.sentence_context)">Continue chat</button>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="chat">
      <div class="title"  @click="$emit('close')">
          AI Assistant
          <img src="/src/assets/navigation/back.png" alt="Down Arrow" />
      </div>
      <div class="messages" v-scroll-bottom="shouldAutoScroll" ref="messagesDiv">
        <div v-if="thread">
          <div class="message assistant">Do you have a question about this word or sentence?</div>
          <div>
            <div v-for="message in thread.display_messages" :key="message.id">
              <!-- <div :class="message.role" class="message" :class="message.notSent ? 'not_sent' : ''">{{ message.content }}</div> -->
              <div :class="[{[message.role]: true}, (message.not_sent ? 'not_sent' : '')]" class="message">{{ message.content }}</div>
            </div>
            <div class="clear"></div>
            <div class="message assistant" v-if="writing">
              <div class="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="messages-loading">
          Messages Loading
          <div class="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="bottom" v-if="thread">
        <button @click="scrollToBottom"
              class="scroll-to-bottom" :class="{'fade-out': scrolledToBottom}">
              ↓ Scroll to bottom
      </button>

        <div class="description">Quick chat</div>
        <div class="suggestions" v-if="thread">
          <div class="inner">
            <button v-for="suggestion in thread.suggestedPrompts" :key="suggestion.display" :disabled="writing" @click="sendSuggestedPrompt(suggestion.prompt)">{{ suggestion.display }}</button>
            <button v-if="suggestionsLoadingCount > 0" disabled>Loading more...</button>
          </div>
        </div>
        <form @submit.prevent="submitMessage">
          <input placeholder="Message" type="text" v-model="inputData" required />
          <button class="button" type="submit" :disabled="writing">
            <img class="icon" src="/src/assets/icons/send-white.png" alt="Chat Icon" />
            Send
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/helpers/api.js';
import helpers from '@/helpers/helpers.js';

export default {
  props: ['thread'],
  data() {
    return {
      inputData: '', // The input for the message the user types
      writing: false, // A loading indicator to show when the bot is writing a response
      scrolledToBottom: true, // Whether the user has scrolled to the bottom of the chat
      shouldAutoScroll: true, // Determines if the scroll-to-bottom directive should fire, so that it only happens when adding a new message, not any other variable change.
      autoScrollTimeout: null,
      suggestionsLoadingCount: 0,
      debounceTimeout: null,
    };
  },
  watch: {
		thread(newValue, oldValue) {
      if(newValue) {
  			this.init();
      }
		}
	},
  methods: {
    handleScroll: helpers.debounce(function() {
        this.checkScrollPosition();
    }, 200),
    checkScrollPosition() {
          this.scrolledToBottom = (this.$refs.messagesDiv.scrollTop + this.$refs.messagesDiv.clientHeight >= this.$refs.messagesDiv.scrollHeight);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.messagesDiv.scrollTop = this.$refs.messagesDiv.scrollHeight;
        this.scrolledToBottom = true;
      });
    },
		submitMessage() {
      // Send message to existing thread
      let message = this.inputData;
      this.send(message);
      this.inputData = "";
		},
		send(message) {
      this.shouldAutoScroll = true;
			this.thread.display_messages.push({
				content: message,
				role: 'user',
			});

			this.writing = true;
      this.$emit('data-update');

      this.$nextTick(() => {
        this.shouldAutoScroll = false;
      });

			apiClient.post(`/api/chat/threads/${this.thread.id}/messages`, {
				message: message,
			}).then(response => {
        this.shouldAutoScroll = true;
				this.thread.display_messages.push(response.data);
			}).catch(error => {
				console.error(error);
        this.shouldAutoScroll = true;
        // Add "not sent" alert to the message
        this.thread.display_messages[this.thread.display_messages.length - 1].not_sent = true;
			}).finally(() => {
				this.writing = false;
        this.$emit('data-update');
        this.shouldAutoScroll = false;
			});
		},
		sendSuggestedPrompt(prompt) {
			this.send(prompt);
			this.thread.suggestedPrompts =  this.thread.suggestedPrompts.filter((p) => p.prompt !== prompt);
      this.$emit('data-update');
			this.fetchSuggestions();
		},
		fetchSuggestions(force = false) {
			if(!this.thread.suggestedPrompts || this.thread.suggestedPrompts.length < 2 || force) {
        this.suggestionsLoadingCount++;
				return apiClient.post(`/api/chat/threads/${this.thread.id}/suggest`)
				.then(suggestResponse => {
          let prompts = JSON.parse(suggestResponse.data).prompts;
          let mappedPrompts = prompts.map((prompt) => {
            return { display: prompt, prompt: prompt };
          });

					if(!this.thread.suggestedPrompts)
            this.thread.suggestedPrompts =  mappedPrompts;
					else {
						this.thread.suggestedPrompts = this.thread.suggestedPrompts.concat(mappedPrompts);
					}
          this.suggestionsLoadingCount--;
          this.$emit('data-update');
				}).catch(error => {
          this.suggestionsLoadingCount--;
					console.error(error);
          this.$emit('data-update');
				});
			} else {
				return Promise.resolve();
			}
		},
		init() {
      this.thread.suggestedPrompts = [
        {
          display: 'Grammar',
          prompt: 'Explain the grammar of this word in this sentence',
        },
        {
          display: 'Synonyms',
          prompt: 'What are some synonyms for this word?',
        },
        {
          display: 'Antonyms',
          prompt: 'What are some antonyms for this word?',
        },
        {
          display: 'Etymology',
          prompt: 'What is the etymology of this word?',
        },
        {
          display: 'Usage',
          prompt: 'In what contexts can this word be used? Is it more formal or informal?',
        },
        {
          display: 'Examples',
          prompt: 'Can you give me some example sentences using this word?',
        },
      ]
      this.$emit('data-update');
			this.fetchSuggestions(true);
		}
  },
  created() {
    if(this.thread) {
        this.init();
    }
    this.autoScrollTimeout = setTimeout(() => {
        this.shouldAutoScroll = false;
    }, 3000);
  },
  mounted() {
    this.$refs.messagesDiv.addEventListener('scroll', this.handleScroll.bind(this), { passive: false });

  },
  beforeUnmount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.$refs.messagesDiv.removeEventListener('scroll', this.handleScroll);
    if (this.autoScrollTimeout) {
      clearTimeout(this.autoScrollTimeout);
    }
  },
};
</script>

<!-- Add your CSS styles here -->
<style scoped>
.chat-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.chat-container .chats-history {
  max-width: 200px;
  padding: 5px;
}
.chat-container .chats-history ul {
  padding-left: 15px;
}
.chat-container .chats-history .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-container .info {
  flex-grow: 1;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.chat-container .info .title {
  font-size: 1.4em;
  font-weight: bold;
}
.chat-container .chat {
  background-color: white;
  width: 100%;
  max-height: calc(min(95vh, 800px));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  position: relative;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.chat .title {
  height: 46px;
  font-weight: bold;
  font-size: 1.2em;
  color: black;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #ccc;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.chat .title img {
  transform: rotate(-90deg);
  height: 20px;
}
.chat .messages {
  min-height: 120px;
  padding: 20px;
  padding-bottom: 10px;
  flex-grow: 1;
  overflow-y: auto;
  text-align: left;
  mask-image: linear-gradient(to bottom, transparent, black 30px, black calc(100% - 30px), transparent);
}
.chat .messages .messages-loading {
  text-align: center;
  padding: 10px;
}
.chat .messages .messages-loading .typing-indicator {
  margin-top: 25px;
}
.chat-container .chat .message {
  display: inline-flex;
  flex-wrap: wrap;
  clear: both;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 80%;
  position: relative;
}
.chat-container .chat .message.not_sent {
  padding-right: 30px;
}
.chat-container .chat .message.not_sent::after {
  content: "!";
  position: absolute;
  top: 14px;
  right: 3px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
}
.chat-container .chat .scroll-to-bottom {
  position: absolute;
  top: -50px;
  right: 30px;
  background-color: var(--accent-color-light); /* Feel free to style as you like */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
  opacity: 1;
  transition: opacity 100ms;
}
.chat-container .chat .scroll-to-bottom.fade-out {
  opacity: 0;
  /* Remove all interaction */
  pointer-events: none;
  cursor: pointer;
}
.chat .bottom {
  padding: 20px 20px;
  display:flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.chat .bottom .suggestions {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* Fade out to the right and left, so you can tell the contained items continue. Without background as this doesn't work. Fade should effect 50px */
  mask-image: linear-gradient(to left, transparent, black 50px, black calc(100% - 30px), transparent);
  padding: 0 20px;
  margin-bottom: 8px;
  text-align: right;
}
.chat .bottom .suggestions .inner {
  text-align: left;
  white-space: nowrap;
}
.chat .bottom .description {
  font-size: 0.75em;
  font-weight: bold;
  color: grey;
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 20px;
}
.chat .bottom .suggestions button {
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-flex;
}
.chat .bottom .suggestions button:not(:disabled) {
  background-color: var(--accent-color-light);
}
.chat .bottom form {
  display: flex;
}
.chat .bottom input {
  flex-grow: 1;
  margin-right: 10px;
  border-radius: 50px;
  background-color: var(--grey-color);
  padding: 0 20px;
}

.chat-container .chat .user {
  background-color: #0b93f6;
  color: white;
  float: right;
}
.chat-container .chat .assistant {
  background-color: #e5e5ea;
  color: black;
  float: left;
}

/* Typing indicator container */
.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
.typing-indicator.on {
  display:flex;
}
.typing-indicator.off {
  display:none;
}

/* Typing indicator dots */
.typing-indicator span {
  width: 6px;
  height: 6px;
  background-color: #999;
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  animation: typing 1.5s infinite;
}

/* Typing indicator dot animations */
.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}
.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
