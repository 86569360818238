<template>
  <router-link
    :to="{ name: 'review-flashcards', params: { language: language } }"
  >
    <div class="container">
      <div class="card main" :style="{ backgroundImage: backgroundImageStyle }">
        <div class="content">
          <div class="reviews-info">
            <!-- <p>Total Reviews: {{ dueReviews }}</p> -->
            <div class="flex-container">
              <div class="vertical-flex left">
                <span>Ready for review</span>
                <div class="flex-container">
                  <div class="item">
                    <div class="value">{{ newReviews }}</div>
                    <div class="label">New cards</div>
                  </div>
                  <div class="item">
                    <div class="value">{{ learningReviews + matureReviews }}</div>
                    <div class="label">Reviews</div>
                  </div>
                </div>
              </div>
              <!-- <p>Mature Reviews: {{ matureReviews }}</p> -->
              <div v-if="newReviewsNotIncluded > 0 || otherReviewsNotIncluded > 0" class="vertical-flex right">
                <span  v-if="newReviewsNotIncluded > 0 || otherReviewsNotIncluded > 0">Not included</span>
                <div class="flex-container">
                  <div v-if="newReviewsNotIncluded > 0" class="item">
                    <div class="value">{{ newReviewsNotIncluded }}</div>
                    <div class="label">New</div>
                  </div>
                  <div v-if="otherReviewsNotIncluded > 0" class="item">
                    <div class="value">{{ otherReviewsNotIncluded }}</div>
                    <div class="label">Reviews</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="language" v-if="languageProperties">{{ languageProperties.fullNameOriginal }}</div>

          <div class="clear"></div>
        </div>
      </div>
      <div
        v-if="dueReviews > 1"
        class="card backdrop first-backdrop"
        :style="{
          ...backdropStyle(firstCardTransform),
          backgroundImage: backdropBackgroundImageStyle,
        }"
      ></div>
      <div
        v-if="dueReviews > 2"
        class="card backdrop second-backdrop"
        :style="{
          ...backdropStyle(secondCardTransform),
          backgroundImage: subBackdropBackgroundImageStyle,
        }"
      ></div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

interface Transform {
  x: number;
  y: number;
  rotate: number;
}

export default defineComponent({
  props: {
    language: {
      type: String,
      required: true
    },
    dueReviews: {
      type: Number,
      required: true
    },
    newReviews: {
      type: Number,
      required: true
    },
    learningReviews: {
      type: Number,
      required: true
    },
    matureReviews: {
      type: Number,
      required: true
    },
    newReviewsNotIncluded: {
      type: Number,
      required: true
    },
    otherReviewsNotIncluded: {
      type: Number,
      required: true
    },
  },
  data(): {
    firstCardTransform: Transform;
    secondCardTransform: Transform;
  } {
    return {
      firstCardTransform: {
        x: this.getRandomOffset(8, 8),
        y: this.getRandomOffset(3, -8),
        rotate: this.getRandomOffset(-5, -1)
      },
      secondCardTransform: {
        x: this.getRandomOffset(10, 10),
        y: this.getRandomOffset(3, -8),
        rotate: this.getRandomOffset(1, 5)
      }
    };
  },
  computed: {
    ...mapState('settings', ['selectedLanguage']),
    ...mapGetters({
      supportedLanguages: 'settings/supportedLanguages',
    }),
    languageProperties(): any {
      let properties = this.supportedLanguages.find((x: any) => (x.code == this.language));
      if(properties) {
        return properties;
      }
      return null;
    },
    backgroundImageStyle(): string {
      return `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url('/flags/${this.language}-flag.webp')`;
    },
    backdropBackgroundImageStyle(): string {
      return ` url('/flags/${this.language}-flag.webp')`;
    },
    subBackdropBackgroundImageStyle(): string {
      return ` url('/flags/${this.language}-flag.webp')`;
    },
  },
  methods: {
    backdropStyle(transform: Transform): { transform: string } {
      return {
        transform: `translate(${transform.x}px, ${transform.y}px) rotate(${transform.rotate}deg)`,
      };
    },
    getRandomOffset(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
});
</script>

<style scoped>
a {
  display: inline-block;
}
a:hover {
  text-decoration: none;
}

.container {
  position: relative;
  overflow: visible;
  text-align: left;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.card {
  position: relative;
  width: 280px;
  height: 170px;
  background-color: var(--background-color);
  /* border: 1px solid #ccc; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transform: translate(0, 0);
  z-index: 3;
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-sizing: border-box;
  overflow: hidden;
}
.card.main {
  background-position: center calc(50% - 32px);
}

.card .content {
  padding: 1rem;
  font-size: 1rem;
  color: var(--white-color);
}
.card .content .language {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
}
.card .content .reviews-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  background-color: var(--white-color);
  color: var(--text-color);
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px;
  height: 55px;
  padding-top: 5px;
}

.card .content .reviews-info .vertical-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  text-align: right;
  font-size: 0.6rem;
  font-weight: 600;
}
.card .content .reviews-info .vertical-flex .item .value {
  font-size: 0.7rem;
}
.card .content .reviews-info .vertical-flex .item .label {
  font-size: 0.6rem;
}
.card .content .reviews-info .vertical-flex.left {
  text-align: left;
  align-items: center;
}
.card .content .reviews-info .vertical-flex.right {
  text-align: right;
  align-items: flex-end;
}
.card .content .reviews-info .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 10px;;
}
.card .content .reviews-info .items {
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
}
.card .content .reviews-info .items .item {
  display: flex;
  flex-direction: column;
  margin-top: auto
}
.card .content .reviews-info strong {
  font-size: 0.5rem;
}
.card .content .reviews-info .item .label {
  font-size: 0.6rem;
  color: var(--text-color);
  text-align: center;
  font-weight: normal;
}
.card .content .reviews-info .item .value {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-color);
  text-align: center;
}

.backdrop {
  position: absolute;
  background-color: #f7f7f7;
  z-index: 1;
  border-radius: 10px;
  top: 0;
  background-color: var(--background-color);
}

.backdrop.first-backdrop {
  transform: translate(8px, -7px) rotate(2deg); /* Slight rotation and random offset */
  z-index: 2;
}

.backdrop.second-backdrop {
  transform: translate(12px, -8px) rotate(-3deg); /* Slight rotation and random offset */
}


</style>
