import { createStore } from 'vuex';
import user from './modules/user';
import voice from './modules/voice';
import error from './modules/error';
import settings from './modules/settings';
import navigation from './modules/navigation';
import openChats from './modules/openChats';
import lookup from './modules/lookup';
import texts from './modules/texts';
import flashcards from './modules/flashcards';
import tutorial from './modules/tutorial';

export default createStore({
  modules: {
    user,
    voice,
    error,
    settings,
    navigation,
    openChats,
    lookup,
    texts,
    flashcards,
    tutorial,
  },
});
