<template>
  <div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper">
      <div v-if="loading">Loading...</div>
      <div v-else-if="error">
        <div class="heading-container">
          <h2>Something went wrong</h2>
        </div>
        <p>{{ error }}</p>
      </div>
      <div v-else>
        <div class="heading-container">
          <h2>Success!</h2>
        </div>
        <p>Email successfully verified!</p>
      </div>
      <router-link class="button" :to="{ name: 'home' }">Go to home</router-link>
    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<style scoped>
.button {
  margin-top: 15px;
}
</style>

<script>
import { mapActions } from 'vuex';
import BottomNav from '@/components/BottomNavigation.vue'
import TopNav from '@/components/TopNavigation.vue'

export default {
  components: {
    BottomNav,
    TopNav,
  },
  data() {
    return {
      loading: true,
      error: null
    }
  },
  async created() {
    const { id, hash } = this.$route.params

    try {
      await this.verifyEmail({ id, hash });
      this.loading = false
    } catch (error) {
      this.error = error.response.data.message
      this.loading = false
    }
  },
  methods: {
    ...mapActions('user', ['verifyEmail']),
  }
}
</script>
