<template>
	<div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper">
      <div class="heading-container">
        <h1>Privacy & Cookie Policy</h1>
      </div>

      <p class="spacer"><strong>Last Updated: 7th September 2023</strong></p>
      
      <h3>1. Introduction</h3>
      <p class="spacer">Welcome to PolyglotPal. We respect your privacy and are committed to protecting your personal data. This Privacy & Cookie Policy will inform you about how we handle your personal data and your privacy rights when you use our web app.</p>

      <h3>2. Data We Collect</h3>
      <p class="spacer">We collect a minimal amount of data necessary to provide you with our services. This may include:</p>
      <ul>
          <li>Information you provide when signing up or making a purchase (e.g., name, email address, payment details).</li>
          <li>Data related to your usage of our website, collected through Piwik for analytics purposes.</li>
          <li>Information you provide when signing up for our newsletter.</li>
      </ul>

      <h3>3. How We Use Your Data</h3>
      <p class="spacer">We use the data we collect:</p>
      <ul>
          <li>To provide and improve our services.</li>
          <li>To process transactions through Stripe.</li>
          <li>For analytics to understand how our web app is used and to improve user experience.</li>
          <li>To send you newsletters, if you have opted in for this service.</li>
      </ul>

      <h3>4. Cookies</h3>
      <p class="spacer">Cookies are small text files placed on your device to store data.</p>
      <ul>
          <li><strong>Piwik Cookies:</strong> We use Piwik for analytics purposes. Piwik may set cookies to collect anonymous data about how you use our web app. You will be provided with a banner to give or withhold consent for these cookies.</li>
          <li><strong>Stripe Cookies:</strong> Our payment processor, Stripe, uses cookies to help process transactions securely. These are essential for the functioning of the payment features and cannot be turned off. For more details on how Stripe uses cookies, please visit <a href="https://stripe.com/cookies-policy/legal" target="_blank">Stripe's Cookie Policy</a>.</li>
      </ul>

      <h3>5. Your Rights</h3>
      <p class="spacer">You have various rights related to the personal data we hold:</p>
      <ul>
          <li>Access to the personal data we hold about you.</li>
          <li>Correction of your personal data.</li>
          <li>Deletion of your personal data.</li>
          <li>Object to the processing of your data.</li>
          <li>Data portability.</li>
      </ul>
      <p class="spacer">To exercise any of these rights, please contact us at <a href="mailto:contact@polyglotpal.co.uk">contact@polyglotpal.co.uk</a>.</p>

      <h3>6. Newsletter</h3> <!-- New Section -->
      <p class="spacer">When you sign up for our newsletter, we collect your email address to send you periodic updates about our services, offers, and other news. Your email address is stored securely and will not be shared with third parties for marketing purposes without your explicit consent.</p>
      <p class="spacer">You can unsubscribe from our newsletter at any time by clicking the "Unsubscribe" link at the bottom of any newsletter email. Alternatively, you can contact us at <a href="mailto:contact@polyglotpal.co.uk">contact@polyglotpal.co.uk</a> to opt out.</p>

      <h3>7. Changes to This Policy</h3>
      <p class="spacer">We may update this Privacy & Cookie Policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>

      <h3>8. Contact Us</h3>
      <p class="spacer">For any questions about this policy or our privacy practices, please contact us at [Your Contact Email].</p>

      <div id='ppms_cm_privacy_settings' class='ppms_cm_privacy_settings_widget' data-editor-centralize='true' data-main-container='true' data-root='true'><div class='ppms_cm_privacy_settings_widget_content' data-disable-select='true'><h1 class='ppms_cm_privacy_settings_form_link_header' id='ppms_cm_privacy_settings_form_link_header_id'>Privacy settings</h1><p class='ppms_cm_privacy_settings_form_link_text' id='ppms_cm_privacy_settings_form_link_text_id'>We collect and process your data on this site to better understand how it is used. We always ask you for consent to do that. You can change your privacy settings here.</p><button class='ppms_cm_privacy_settings_button_show' id='ppms_cm_privacy_settings_button'>Manage settings</button></div></div>

      <div class="clear spacer"></div>
      
    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<script>
import TopNav from '@/components/TopNavigation.vue'
import BottomNav from '@/components/BottomNavigation.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    TopNav,
    BottomNav
  }
}
</script>