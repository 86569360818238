<template>
  <div class="outer-wrapper">
    <header>
      <SubTopNav :backRoute="{ name: 'list-texts' }" :settingsRoute="{ name: 'settings', params: { section: 'texts' } }">
        <div class="top-nav-area" v-if="text" @click="showPageDropdown = !showPageDropdown">
          <div class="text-title" v-if="text.title">{{ text.title }}</div>
          <div class="page-select">
            <div class="current" v-if="page && totalPages && totalPages > 1"
              :class="showPageDropdown ? 'open' : 'closed'">
              Page {{ page }} / {{ totalPages }}
              <!-- Down indicator -->
              <img src="/src/assets/navigation/back.png" alt="Down Arrow" />
            </div>

            <div class="dropdown" v-if="showPageDropdown" @click.stop>
              <!-- Clicking this should not close the dropdown -->
              <div class="inner-wrapper">
                <span v-if="page && totalPages">
                  Go to page <input type="number" v-model="changePage" :placeholder="page" :max="totalPages" min="1">
                  <button class="small" @click="loadPage(changePage, true)">Go</button>
                </span>
              </div>
            </div>
            <div class="overlay" v-if="showPageDropdown"></div>
          </div>
        </div>
      </SubTopNav>
    </header>
    <main>
      <div v-for="tutorial in tutorials" v-if="showTutorial">
        <TooltipOverlay :key="tutorial.id" v-if="!tutorial.done && arePreviousTutorialsDone('ViewText', tutorial.id)"
          :tutorialId="tutorial.id" :message="tutorial.message" :targetSelector="tutorial.selector"
          @dismissed="dismissTutorial({ viewName: 'ViewText', tutorialId: tutorial.id })" @hideTutorial="hideTutorial">
        </TooltipOverlay>
      </div>
      <div v-if="textLoadingCount <= 0">
        <!-- <h2 v-if="page == 1">{{ text.title }}</h2> -->
        <div class="inner-wrapper content-container processing" v-if="!text">You do not have access.</div>
        <div class="inner-wrapper content-container processing" v-else-if="text && text.status == 'processing'">
          <div class="loader"></div>
          <strong class="loading-text">Processing...</strong>
          <p>This can take a minute.</p>
          <p class="spacer">Feel free to check back later.</p>
          <!-- <button @click="refresh(true)">Refresh</button> -->
        </div>
        <div class="content-container" v-else-if="text.status == 'failed'">
          <p class="loading">Sorry - looks like Something went wrong with this text.</p>
          <p class="loading spacer">You can delete this and try again.</p>
          <button class="bad" @click="deleteText()">
            <img class="icon" src="/src/assets/icons/trash-white.png" alt="Delete Icon" />
            Delete
          </button>
        </div>
        <div v-else-if="text.chunks">
          <!-- {{ sentences }} -->
          <!-- <pre>{{ text.lookups }}</pre> -->
          <!-- An input that takes a string -->
          <!-- <input type="text" v-model="lang" /> -->
          <div class="parallax" v-if="text.image_url">
            <div class="parallax-background" :style="`${text.image_url ? `background-image: url(${fullUrl(text.image_url)});` : ''}; transform: translateY(${scrollY * 0.4}px);`"></div>
            <div class="title">
              {{ text.title }}
            </div>
          </div>

          <div class="inner-wrapper content-container">
            <SelectableTextOutput :fontSize="customFontSize" v-if="text" ref="selectableTextOutput" :chunks="text.chunks"
              :languageCode="text.language ? text.language : selectedLanguage"
              :loadedPreviousHighlights="text.previouslyLookedUpWords" :textID="text.id"
              :autoPlayVoice="true"  
            />
          </div>
          <nav class="page-nav inner-wrapper">
            <button @click="loadPage(page - 1, true)" :class="!hasPrev ? 'hidden' : ''">Previous page</button>
            <button @click="loadPage(page + 1, true)" v-if="hasNext">Next page</button>
            <button class="good" v-if="page == totalPages" @click="finishText">Finished</button>
          </nav>
        </div>
        <div v-else class="inner-wrapper">
          <!-- This is hit if the text was loaded in the background without a loading indicator, so just say that it's loading -->
          <div class="loading">
            <div class="loader"></div>
            You do not have access to this text...
          </div>
        </div>

      </div>
      <div v-else class="inner-wrapper">
        <div class="loading">
          <div class="loader"></div>
          Loading...
        </div>
      </div>
    </main>
    <footer>
      <div class="voice-controls">
        <div class="container">
          <img src="/sound/next.png" style="transform: rotate(180deg)" alt="Play Previous Icon" @click="prev()" />
        </div>
        <div class="container">
          <img v-if="isPlaying || isLoading" src="/sound/stop.png" alt="Pause/Stop Icon" @click="stop()"
            class="stop-button" />
          <img v-else-if="!isPlaying && !isLoading" src="/sound/play.png" alt="Play Icon" @click="play()"
            class="play-button" />
        </div>
        <div class="container">
          <img src="/sound/next.png" alt="Play Next Icon" @click="next()" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import apiClient from '@/helpers/api.js';
import axios from 'axios'; // Used for passing cancelTokens to requests
import SelectableTextOutput from '@/components/SelectableTextOutput.vue';
import VoiceButton from '@/components/VoiceButton.vue';
import SubTopNav from '@/components/SubTopNavigation.vue';
import TooltipOverlay from '@/components/TooltipOverlay.vue';

export default {
  data() {
    return {
      lang: null,
      text: null,
      pollInterval: null, // For polling the server for updates if the text is processing
      hasNext: false,
      hasPrev: false,
      page: null,
      totalPages: null,
      showPageDropdown: false,
      changePage: null,

      // Create Flashcards
      selectedWord: '',
      fullSentence: '',
      currentHighlight: { sentenceIndex: -1, wordIndex: -1 },

      lastSentence: null, // The last chunk of text loaded - displayed as the current sentence

      textLoadingCount: 0,
      sentenceLoadingCount: 0,
      translationsLoadingCount: 0,

      intersectingChunks: [], // An array of chunkIDs that are currently in view
      observer: null, // Used to track the page scroll to save position based on chunkID
      saveTimeout: null, // Used to delay the saving of the progress until the user has stopped scrolling for a few seconds

      scrollY: 0, // Used for the parallax effect
    };
  },
  computed: {
    ...mapState('settings', ['settings', 'selectedLanguage']),
    ...mapState({
      voice: 'voice',
    }),
    ...mapState('tutorial', ['showTutorial']),
    ...mapGetters('tutorial', ['getTutorialsForView', 'arePreviousTutorialsDone']),
    tutorials() {
      return this.$store.getters['tutorial/getTutorialsForView']('ViewText');
    },
    ...mapGetters({
      isPlaying: 'voice/isPlaying',
      isLoading: 'voice/isLoading',
      supportedLanguages: 'settings/supportedLanguages',
      selectedLanguageProperties: 'settings/selectedLanguageProperties',
    }),
    settingsForSelectedLanguage() {
      return this.settings[this.selectedLanguage] || {};
    },
    customFontSize() {
      return this.settingsForSelectedLanguage.font_size ? this.settingsForSelectedLanguage.font_size + 'px' : false;
    },
  },
  components: {
    // TextPopup,
    // CreateFlashcard,
    SelectableTextOutput,
    VoiceButton,
    SubTopNav,
    TooltipOverlay
  },
  methods: {
    ...mapActions('tutorial', ['loadTutorialStatuses', 'dismissTutorial', 'hideTutorial', 'addTutorial', 'removeTutorial']),
    handleScroll() {
      // Update scrollY as you scroll
      this.scrollY = window.scrollY || window.pageYOffset;
    },
    play() {
      // This should call the playAudio function in SelectableTextOutput
      this.$refs.selectableTextOutput.playAudio();
    },
    stop() {
      // This should call the stopAudio function in SelectableTextOutput
      this.$refs.selectableTextOutput.stopAudio();
    },
    next() {
      // This should call the playNextAudio function in SelectableTextOutput
      this.$refs.selectableTextOutput.playNextAudio();
    },
    prev() {
      // This should call the playPreviousAudio function in SelectableTextOutput
      this.$refs.selectableTextOutput.playPreviousAudio();
    },
    fullUrl(url) {
      return `${import.meta.env.VITE_API_URL}${url}`;
    },
    async fetchText(userInitiated = false) {
      if (userInitiated) {
        this.textLoadingCount++;
      }

      return apiClient.get(`/api/texts/${this.$route.params.id}`)
        .then(response => {
          this.text = response.data;
          if (userInitiated) {
            this.textLoadingCount--;
          }
          Promise.resolve(response);
        })
        .catch(error => {
          console.error(error);
          if (userInitiated) {
            this.textLoadingCount--;
          }
          Promise.reject(error);
        });
    },

    loadPage(page = null, userInitiated = false) {
      if (userInitiated) {
        this.textLoadingCount++;
      }
      this.showPageDropdown = false;

      const params = {
        limit: 15,
      };

      if (page !== null) {
        params.page = page;

        if (page > this.totalPages) {
          params.page = this.totalPages;
        }
        if (page < 1) {
          params.page = 1;
        }
      }


      let oldPage = this.page;
      this.page = params.page;

      return apiClient.get(`/api/texts/${this.$route.params.id}/content`, {
        params: params,
      })
        .then(response => {
          this.handleLoadedPage(response);

          let chunks = response.data.chunks;
          this.text = {
            ...this.text,
            content: chunks.map((chunk, index) => {
              if (index === 0) {
                return chunk.content.replace(/^(\n)+/, '');
              }
              return chunk.content;
            }).join(''),
            chunks: chunks,
            previouslyLookedUpWords: response.data.previouslyLookedUpWords
          };
          if (userInitiated) {
            this.textLoadingCount--;
          }

          // Add observer for saving the progress
          this.$nextTick(() => {
            document.querySelectorAll('.chunk').forEach(chunk => {
              this.observer.observe(chunk);
            });

            // Scroll to the offset specified by the 'exact_offset' to make sure the user is at the right place
            if (response.data.exact_offset) {
              let ref = this.$refs.selectableTextOutput.$refs[`offset-${response.data.exact_offset}`];
              if (ref && ref.length > 0) {
                let targetChunk = ref[0];
                if (targetChunk) {
                  targetChunk.scrollIntoView();
                }
              }
            }
          });

          // Add tutorial
          if (this.text && this.text.status === 'processed') {
            let targetChunkOffset = response.data.exact_offset ? response.data.exact_offset : 0;

            // Check if the next chunk exists
            let targetChunk = this.text.chunks.find(chunk => chunk.order === targetChunkOffset + 1);
            // If it does, use the next one instead
            if (targetChunk && targetChunkOffset !== 0) { // Don't do this if it's the first chunk, if they are just starting the text then we want the first chunk
              targetChunkOffset = targetChunk.order;
            }
            if (targetChunkOffset !== undefined) {
              this.addTutorial({
                viewName: 'ViewText',
                tutorial: {
                  id: 'lookup',
                  message: 'Click on any word that you don\'t know to look it up.',
                  selector: `.chunk[data-offset="${targetChunkOffset}"] .sentence:first-child`, // Adjust the selector to target the first sentence within the chunk the user is currently on
                }
              });
            } else {
              // If for some reason targetChunk is not available, default to the first sentence
              this.addTutorial({
                viewName: 'ViewText',
                tutorial: {
                  id: 'lookup',
                  message: 'Click on any word that you don\'t know to look it up.',
                  selector: '.chunk:first-child .sentence:first-child',
                }
              });
            }
          }

        })
        .catch(error => {
          console.error(error);
          if (userInitiated) {
            this.textLoadingCount--;
          }
          this.showPageDropdown = true;
          this.page = oldPage;
        });
    },
    handleLoadedPage(response) {
      this.hasNext = response.data.totalPages > response.data.page;
      this.hasPrev = response.data.page > 1;
      this.totalPages = response.data.totalPages;
      this.page = parseInt(response.data.page);
    },
    finishText() {
      this.textLoadingCount++;
      return apiClient.get(`/api/texts/${this.$route.params.id}/finish`)
        .then(response => {
          this.textLoadingCount--;
          this.$router.push({ name: 'list-texts' });
        })
        .catch(error => {
          console.error(error);
          this.textLoadingCount--;
        });
    },
    saveTextProgress() {
      if (this.intersectingChunks.length > 0) {
        // Order the chunks by the order property
        this.intersectingChunks.sort((a, b) => a - b);

        // Replace this with your actual API call.
        apiClient.post(`/api/texts/${this.$route.params.id}/save-progress`, {
          offset: this.intersectingChunks[0],
        }).then(response => {
        }).catch(error => {
          console.error(error);
        });
      }
    },
    async refresh(userInitiated = false) {
      return this.fetchText(userInitiated).then(() => {
        if(this.text && this.text.status === 'processed') {
          this.loadPage(null, userInitiated);
        }
        if (this.text && this.text.status !== 'processing') {
          if (this.pollInterval) {
            // If the text is no longer processing, clear the interval
            clearInterval(this.pollInterval);
            this.pollInterval = null;
          }
        }
      });
    },
    deleteText() {
      this.textLoadingCount++;
      return apiClient.delete(`/api/texts/${this.$route.params.id}`)
        .then(response => {
          this.textLoadingCount--;
          this.$router.push({ name: 'list-texts' });
        })
        .catch(error => {
          console.error(error);
          this.textLoadingCount--;
        });
    },
  },

  created() {
    this.loadTutorialStatuses();
    this.refresh(true).then(() => {
      if (this.text && this.text.status === 'processing') {
        // Only start polling if the text status is 'processing'
        this.pollInterval = setInterval(this.refresh, 5000);
      }
    });
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        let offset = entry.target.dataset.offset;
        if (entry.isIntersecting) {
          if (!this.intersectingChunks.includes(offset)) {
            this.intersectingChunks.push(offset);
          }
        } else {
          let index = this.intersectingChunks.indexOf(offset);
          if (index !== -1) {
            this.intersectingChunks.splice(index, 1);
          }
        }
      });

      // Now intersectingChunks contains all offsets that are currently in view.

      // If a save operation is already scheduled, clear it
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }

      // Schedule a new save operation to run 2 seconds (2000 ms) from now
      this.saveTimeout = setTimeout(() => {
        this.saveTextProgress();
        this.saveTimeout = null; // Clear the timeout ID
      }, 4000);
    }, {
      threshold: [0.1]  // Change this to the percentage of the chunk that should be in view for it to count as "in view". Adjust as necessary.
    });
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);

    if (this.observer) {
      this.observer.disconnect();
    }

    // Clear the saveTimeout if it exists
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
      this.saveTimeout = null;
    }

    // Clear the pollInterval if it exists
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
      this.pollInterval = null;
    }

    // Remove the tutorial we added so that we don't get duplicates when navigating to/from this page
    this.removeTutorial({ viewName: 'ViewText', tutorialId: 'lookup' });
  },
};
</script>

<style scoped>
.loading {
  display: block;
  text-align: center;
  margin-top: 20px;
}
.loading .loader {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.parallax {
  position: relative;
  overflow: hidden; 
  padding-bottom: 100%;
  height: 0px;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom; 
  will-change: transform; 
}


/* @media (min-width: 400px) {
  .parallax {
    padding-bottom: 75%;
  }
}
@media (min-width: 550px) {
  .parallax {
    padding-bottom: 50%;
    background-position: top;
    background-attachment: scroll;
  }
} */
/* @media (min-width: 650px) {
  .parallax {
    background-size: 650px auto;
  }
} */
/* If the aspect ratio is wider than tall, set the padding-bottom to 0 and set the height to 100% */
@media (min-aspect-ratio: 1/1) {
  .parallax {
    padding-bottom: 0;
    height: 75vh;
    max-height: 645px;
  }
}

.parallax .title {
  position: absolute;
  bottom: 0;
  padding: 15px;
  color: white;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
  font-weight: bold;
  word-break: break-word;
  font-size: 1.8rem;
}

.sentence {
  line-height: 30px;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  /* Chrome, Safari, and newer versions of Opera */
  -moz-hyphens: auto;
  /* Firefox */
  -ms-hyphens: auto;
  /* Internet Explorer and Edge */
  hyphens: auto;
  /* Standard syntax */
}

.word {
  border-radius: 8px;
  padding: 0px 2px;

}

.highlight-current {
  background-color: lightblue;
  color: white;
}

.highlight-previous {
  background-color: #ccc;
  color: black;
}

.content-container {
  margin-top: 10px;
  position: relative;
  text-align: justify;
}

.content-container .title {
  font-size: 2rem;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-hyphens: auto;
  /* Chrome, Safari, and newer versions of Opera */
  -moz-hyphens: auto;
  /* Firefox */
  -ms-hyphens: auto;
  /* Internet Explorer and Edge */
  hyphens: auto;
  /* Standard syntax */
}

.content-container .create-flashcard {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background-color: lightgray;
  opacity: 1;
  z-index: 10;
}

.content-container .content {
  font-size: 1.5rem;
  white-space: pre-line;
}

.page-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin: 15px auto;
}

.page-nav .hidden {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.page-nav input {
  width: 100px;
}

.top-nav-area {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  padding: 5px 15px;
  cursor: pointer;
}

.text-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin: 0 auto;
  font-weight: bold;
}

.page-select {
  font-size: 0.8em;
  display: block;
}

.page-select .current {
  margin: 0 auto;
}

.page-select .current img {
  width: 10px;
  margin-left: 10px;
  vertical-align: bottom;
  transform: rotate(-90deg)
}

.page-select .current.open img {
  transform: rotate(90deg);
}

.page-select .dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(var(--top-bar-height) - 1px);
  background-color: var(--grey-color-light);
  padding: 10px 0 15px 0;
  text-align: center;
  border-bottom: 1px solid var(--grey-color);
  z-index: 1;
}

.page-select .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: var(--top-bar-height);
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.page-select input {
  width: 100px;
  margin-right: 10px;
}


.voice-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--bottom-bar-height);
  background-color: var(--grey-color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  z-index: 10;
}

.voice-controls .container {
  width: 30px;
}

.voice-controls img {
  height: 20px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.voice-controls .play-button,
.voice-controls .stop-button,
.voice-controls .loading-button {
  height: 30px;
}

.processing .loader {
  margin-top: 20px;
  margin-bottom: 20px;
}
.processing {
  text-align: center;
}
.processing .loading-text {
  display: block;
}
</style>
