<template>
  <div class="outer-wrapper" style="padding-bottom: 100px;">
    <header>
      <SubTopNav
        :backRoute="{ name: 'list-flashcards' }"
        :settingsRoute="{ name: 'settings', params: { section: 'flashcards' } }"
      />
    </header>
    <main class="inner-wrapper">
      <!-- <RouterLink :to="{ name: 'list-flashcards' }">Edit Flashcards</RouterLink> -->
      <!-- <pre>{{ currentFlashcard }}</pre> -->

      <div
        v-if="
          pageIsLoading ||
          (fetchFlashcardsLoadingCount > 0 && cachedFlashcards.length == 0) ||
          (markFlashcardLoadingCount > 0 && cachedFlashcards.length == 0)
        "
        class="loading"
      >
        <div class="loader"></div>
        Checking for flashcards...
      </div>
      
      <div v-else>
          <!-- Toggle reviewMode between 'classic' and 'sentence' -->
        <!-- <div class="review-mode">
          Review Mode: {{ reviewMode }}
          <button @click="reviewMode = 'classic'" :class="{ toggled: reviewMode == 'classic' }">Classic</button>
          <button @click="reviewMode = 'sentence'" :class="{ toggled: reviewMode == 'sentence' }">Sentence</button>
        </div> -->

        <div class="summary">
          Remaining: {{ cachedFlashcards.length }}
        </div>
        <div v-if="cachedFlashcards.length > 0 && currentFlashcard">

          <div class="flashcard-container" v-if="!showSentenceReviewMode">
            <div class="flashcard-text front">

              <div class="contain">
                <VoiceButton v-if="currentFlashcard.flashcardable.definition.language != ''" :content="currentFlashcard.flashcardable.definition.word.word" :languageCode="currentFlashcard.flashcardable.definition.language"/>

                <h1 class="word">{{ currentFlashcard.flashcardable.definition.word.word }}</h1><div class="base">{{ currentFlashcard.flashcardable.definition.base }} <span class="type" v-if="currentFlashcard.flashcardable.definition.type && currentFlashcard.flashcardable.definition.type != ''">({{ currentFlashcard.flashcardable.definition.type }})</span></div>
                <!-- <div class="gender" v-if="currentFlashcard.flashcardable.definition.gender && currentFlashcard.flashcardable.definition.gender !== ''">Gender: {{ genderOutput(currentFlashcard.flashcardable.definition.gender) }}</div> -->
              </div>
              <div class="expression" v-if="currentFlashcard.flashcardable.definition.expression && currentFlashcard.flashcardable.definition.expression != ''">"{{ currentFlashcard.flashcardable.definition.expression }}"</div>

              <div class="sentences">
                <div v-for="(sentence) in currentFlashcard.flashcardable.sentences" :key="sentence.id">
                  <div class="sentence">
                      <SelectableTextOutput
                        v-if="sentence"
                        :content="sentence.sentence"
                        :languageCode="sentence.language"
                        :isSelectable="sentence.language == currentFlashcard.learning_language"
                        :highlightPhrase="currentFlashcard.flashcardable.definition.word.word"
                      />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="showBackText" class="flashcard-text back">
              <!-- <VoiceButton v-if="currentFlashcard.flashcardable.related_definition.language != ''" :content="currentFlashcard.flashcardable.related_definition.word.word" :languageCode="currentFlashcard.flashcardable.related_definition.language" /> -->

              <!-- <h1 class="word">{{ currentFlashcard.flashcardable.related_definition.word.word }}</h1> <span class="base">{{ currentFlashcard.flashcardable.related_definition.base }} <span class="type" v-if="currentFlashcard.flashcardable.related_definition.type && currentFlashcard.flashcardable.related_definition.type != ''">({{ currentFlashcard.flashcardable.related_definition.type }})</span></span>
              <div class="gender" v-if="currentFlashcard.flashcardable.related_definition.gender">Gender: {{ genderOutput(currentFlashcard.flashcardable.related_definition.gender) }}</div>
              <div class="expression" v-if="currentFlashcard.flashcardable.related_definition.expression && currentFlashcard.flashcardable.related_definition.expression != ''">"{{ currentFlashcard.flashcardable.related_definition.expression }}"</div> -->

              <!-- <div class="sentences">
                <div v-for="(sentence) in currentFlashcard.flashcardable.sentences" :key="sentence.id">
                  <div class="sentence">
                      <SelectableTextOutput
                        v-if="sentence"
                        :content="sentence.translations[0].sentence"
                        :languageCode="sentence.translations[0].language"
                        :isSelectable="sentence.translations[0].language == currentFlashcard.learning_language"
                        :highlightPhrase="currentFlashcard.flashcardable.related_definition.word.word"
                        @selectWord="(selectedWord, fullSentence) => lookupWord(selectedWord, fullSentence, sentence.id)"
                      />
                  </div>
                </div>
              </div> -->

              <DefinitionTranslation
                :sourceLanguage="currentFlashcard.learning_language"
                :definition="currentFlashcard.flashcardable.definition"
                :relatedDefinition="currentFlashcard.flashcardable.related_definition"
                :definitionTranslationID="currentFlashcard.flashcardable.flashcardable_id"
                :sentences="currentFlashcard.flashcardable.sentences"
                :notes="currentFlashcard.flashcardable.notes"
                :tips="currentFlashcard.flashcardable.tips"
                :showDeleteButton="true"
                @deleted="handleDefinitionTranslationDeleted"
              />

              <!-- <div class="related-definitions" v-if="false">
                <h2 class="definitions-heading">Related Definitions</h2>
                <div v-for="(definition) in currentFlashcard.related_definition_translations" :key="definition.id">
                  <div v-for="(definitionTranslation) in definition.translations" :key="definitionTranslation.id">
                    <DefinitionTranslation
                      :sourceLanguage="currentFlashcard.learning_language"
                      :definition="definition"
                      :relatedDefinition="definitionTranslation.related_definition"
                      :definitionTranslationID="definitionTranslation.id"
                      :sentences="definitionTranslation.sentences"
                      :showDeleteButton="true"
                      @deleted="handleDefinitionTranslationDeleted"
                    />

                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <div v-if="showSentenceReviewMode">
            <SentenceReview
              :flashcard="currentFlashcard"
              @next="markFlashcard"
            />
          </div>

          <div class="flashcard-buttons" v-if="!showSentenceReviewMode">
            
            <div v-for="tutorial in tutorials" v-if="showTutorial">
              <TooltipOverlay
                :key="tutorial.id"
                v-if="!tutorial.done && arePreviousTutorialsDone(tutorial.id)"
                :tutorialId="tutorial.id"
                :message="tutorial.message"
                :targetSelector="tutorial.selector"
                :containerSelector="'.flashcard-buttons'"
                :delay="tutorial.delay ? tutorial.delay : 0"
                @dismissed="dismissTutorial({ viewName: 'ReviewFlashcards', tutorialId: tutorial.id })"
                @hideTutorial="hideTutorial"
              ></TooltipOverlay>
            </div>

            <button v-if="!showBackText" @click="toggleBackText" id="show-button" class="flashcard-button">Show</button>
            <div v-if="showBackText" class="result">
              <button class="fail flashcard-button" @click="markFlashcard(false)">Fail</button>
              <button class="flashcard-button" @click="markFlashcard(true)">Success<span class="next-review">Next review: {{ currentFlashcard.nextReviewIfSuccessful }}</span></button>
            </div>
           
          </div>

        </div>
        <div v-else>
          <p>No flashcards are ready for review. Check back tomorrow!</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import apiClient from "@/helpers/api.js";
import SentenceReview from "@/components/SentenceReview.vue";
import SelectableTextOutput from '@/components/SelectableTextOutput.vue';
import DefinitionTranslation from '@/components/DefinitionTranslation.vue';
import VoiceButton from '@/components/VoiceButton.vue';
import SubTopNav from "@/components/SubTopNavigation.vue";
import language from "@/helpers/language.js";
import TooltipOverlay from '@/components/TooltipOverlay.vue';

export default {
  data() {
    return {
      language: this.$route.params.language
        ? this.$route.params.language
        : null,
      cachedFlashcards: [],
      pendingFlashcards: [], // The flashcards that have been reviewed, but the backend hasn't returned a response yet
      pageIsLoading: true,
      fetchFlashcardsLoadingCount: false,
      showChatArea: true,
      showBackText: false, // Add this property
      thread: null,

      reviewMode: 'classic', // Classic or sentence

      markFlashcardLoadingCount: 0,
      chatLoadingCount: 0,

      autoplay: false,
    };
  },
  computed: {
    ...mapState({
      voice: 'voice'
    }),
    tutorials() {
      return this.$store.getters['tutorial/getTutorialsForView']('ReviewFlashcards');
    },
    arePreviousTutorialsDone() {
      return (tutorialId) => this.$store.getters['tutorial/arePreviousTutorialsDone']('ReviewFlashcards', tutorialId);
    },
    ...mapState('tutorial', ['showTutorial']),
    currentFlashcard() {
      if (this.cachedFlashcards.length == 0) {
        return null;
      }

      return this.cachedFlashcards[0];
    },
    showSentenceReviewMode() {
      return this.reviewMode == 'sentence';
    }
  },
  components: {
    SentenceReview,
    SelectableTextOutput,
    DefinitionTranslation,
    VoiceButton,
    SubTopNav,
    TooltipOverlay
  },
  async created() {
    this.loadTutorialStatuses();

    await this.fetchFlashcards(); // Fetch the first batch of flashcards
    this.pageIsLoading = false;
    
    const appSettings = JSON.parse(localStorage.getItem('appSettings') ?? '{}');
    this.autoPlay = appSettings.voice_autoplay ? appSettings.voice_autoplay : false;
    if(this.autoPlay) {
      this.autoSpeech();
    }
  },
  methods: {
    genderOutput(g) {
      return language.genderOutput(g);
    },
    ...mapActions('voice', ['speech', 'stop']),
    ...mapActions('tutorial', ['loadTutorialStatuses', 'dismissTutorial', 'hideTutorial', 'addTutorial', 'removeTutorial']),
    autoSpeech() {
      if(this.currentFlashcard) {
        if(this.currentFlashcard.learning_language != '') { // Only play if the language is defined (backwards compatible)
          if(this.currentFlashcard.flashcardable.definition.language == this.currentFlashcard.learning_language) { // Only play if it is the learning language, not the source language
            let text = this.currentFlashcard.flashcardable.definition.word.word;
            let language = this.currentFlashcard.flashcardable.definition.language;

            this.speech( { id: `${text}_${language}` , text: text, languageCode: language})
          }
        }
      }

    },
    async fetchFlashcards() {
      // Set isLoading to true before making the request
      this.fetchFlashcardsLoadingCount++;

      try {
        const response = await apiClient.get("/api/flashcards/fetch", {
          params: {
            language: this.language,
          },
        });
        this.cachedFlashcards = this.cachedFlashcards.concat(
          response.data.flashcards.filter(
            (flashcard) => !this.pendingFlashcards.includes(flashcard.id)
          )
        );
        // remove duplicates based on the ID property
        this.cachedFlashcards = this.cachedFlashcards.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.id === thing.id)
        );

        // // temp disable sentence review mode default
        // this.reviewMode = response.data.review_mode;
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        // Set isLoading to false after the request is complete
        this.fetchFlashcardsLoadingCount--;

        return true;
      }
    },
    async nextFlashcard() {
      this.stop(); // Stop the voice

      this.showChatArea = false;
      if (this.cachedFlashcards.length > 0) {
        this.cachedFlashcards.shift(); // Remove the current flashcard from the array
      }

      // Auto play the next flashcard
      if(this.autoPlay) {
        this.autoSpeech();
      }
    },
    async markFlashcard(result) {
      this.dismissTutorial({ viewName: 'ReviewFlashcards', tutorialId: 'mark' });

      this.markFlashcardLoadingCount++;
      let flashcardID = this.cachedFlashcards[0].id;
      this.pendingFlashcards.push(flashcardID);
      apiClient
        .post(`/api/flashcards/${flashcardID}/review`, {
          isCorrect: result,
        })
        .then((response) => {
          if (response.status === 200) {
          } else {
            console.error(response);
          }
          this.pendingFlashcards = this.pendingFlashcards.filter(
            (id) => id !== flashcardID
          );

          // If we are running low on flashcards, check backend for more.
          if (this.cachedFlashcards.length == 0) {
            this.fetchFlashcards();
          }
          this.markFlashcardLoadingCount--;
        })
        .catch((error) => {
          console.error(error);
          this.markFlashcardLoadingCount--;
          alert("Something went wrong. Please try again later.");
        })
        .finally(() => {
          // console.log(`Marked as ${result}`);
        });
      this.showBackText = false;
      this.nextFlashcard();
    },
    toggleBackText() {
      this.showBackText = !this.showBackText;
      this.dismissTutorial({ viewName: 'ReviewFlashcards', tutorialId: 'flip' });

      this.addTutorial({
        viewName: 'ReviewFlashcards',
        tutorial: { 
          id: 'mark', 
          message: 'Choose if you got it correct or not! <br><br> We will use this information to determine when to show you this card again, to optimise your learning.', 
          selector: '.result', 
          delay: 3000,
        }
      });
    },
    // This function tries to update all local flashcards that are related to the deleted definitionTranslation (e.g. delete any definitions/flashcards that no longer have any translations after the deletion). It's not perfect and there are some edge cases that it doesn't handle, but it's better than nothing. Alternatively, we can just force a full re-fetch of flashcards after a definitionTranslation is deleted.
    handleDefinitionTranslationDeleted(definitionTranslationID) {
      // Get the flashcardable
      let flashcardable = this.cachedFlashcards[0].flashcardable;
      if(flashcardable.flashcardable_id != definitionTranslationID) {
        return;
      }

      // // First, remove any related flashcards from the cachedFlashcards array
      // Get the related flashcard based on the flashcardable id and type
      let relatedFlashcards = this.cachedFlashcards.filter(flashcard => flashcard.flashcardable.flashcardable_id === flashcardable.flashcardable_id && flashcard.flashcardable.flashcardable_type === flashcardable.flashcardable_type);
      // Don't include the currentFlashcard in the relatedFlashcards array
      relatedFlashcards = relatedFlashcards.filter(flashcard => flashcard.id !== this.cachedFlashcards[0].id);

      let flashcardsToRemove = [];
      // Loop through related flashcards and remove the translation
      for(let i = 0; i < relatedFlashcards.length; i++) {
        let relatedFlashcard = relatedFlashcards[i];
        flashcardsToRemove.push(relatedFlashcard);
      }

      // After the for loop, where you modify relatedFlashcards
      // Filter out the removed flashcards from this.cachedFlashcards
      this.cachedFlashcards = this.cachedFlashcards.filter(flashcard => !flashcardsToRemove.includes(flashcard));

      // Handle the current viewed flashcard, after related flashcards are done.
      // // Just skip it should do the trick
      this.showBackText = false;
      this.nextFlashcard();
    },
  },

};
</script>

<style scoped>

.loading {
  display: block;
  text-align: center;
  margin-top: 20px;
}
.loading .loader {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.summary {
  font-style: italic;
  text-align: right;
  margin-bottom: 5px;
}
.flashcard-container {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  max-width: 800px;
}

.flashcard-text {
  margin-bottom: 10px;
}
.flashcard-text .contain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}
.flashcard-text .contain .word {
  display: inline-block;
  margin: 0px 5px;
  margin-right: 0px;
}
.flashcard-text .contain .base {
  /* Make this element in the flex wrapper align with the bottom */
  align-self: flex-end;
  line-height: 38px;
  margin-left: 5px;
  font-style: italic;
}
.flashcard-text.front .sentence {
  margin-bottom: 5px;
}

.flashcard-text.back {
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
}

.sentences {
  margin-top: 10px;
}
.definitions-heading {
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}
.chat-comp {
  margin-bottom: var(--bottom-bar-height);
}
</style>
