<template>
  <div class="bottom-nav">
    <router-link
      class="nav-item"
      v-for="(item, index) in items"
      :key="index"
      :to="item.route"
    >
      <img :class="item.name" :src="`${item.icon}`" :alt="item.name + ' Icon'" class="icon">
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      items: [
        { name: 'Home', icon: new URL('/src/assets/navigation/home.png', import.meta.url), route: { name: 'home' } },
        { name: 'Texts', icon: new URL('/src/assets/navigation/texts.png', import.meta.url), route: { name: 'list-texts' } },
        { name: 'Flashcards', icon: new URL('/src/assets/navigation/flashcards.png', import.meta.url), route: { name: 'list-flashcards' } },
        { name: 'Menu', icon: new URL('/src/assets/navigation/menu.png', import.meta.url), route: { name: 'menu' } }
      ]
    }
  }
}
</script>

<style scoped>
.bottom-nav {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  /* max-width: var(--outer-wrapper-width); */
  width: 100%;
  height: var(--bottom-bar-height);
  background-color: var(--grey-color-light);
  border-top: 1px solid var(--grey-color);
  z-index: 3;
}

.nav-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Make text color same as non-link text */
  max-width: calc(var(--outer-wrapper-width) / 4);
}

.icon {
  height: 20px;
  width: auto;
  margin-right: 5px;
}
.icon.Menu {
  height: 14px;
}

.router-link-exact-active {
  background-color: var(--grey-color);
}
</style>
