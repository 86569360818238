<template>
  <div class="outer-wrapper">
    <header>
      <SubTopNav
        :backRoute="{ name: 'list-texts' }"
      />
    </header>
    <main class="inner-wrapper">
      <div class="heading-container">
        <h2>Define your story</h2>
      </div>
      <div :class="loadingCount > 0 ? 'no-edit' : ''">
        <button class="randomize" @click="randomizeAnswers">Randomize</button>
        <p class="spacer">
          <span v-for="(step, index) in filteredSteps" :key="index + '-' + transitionKeys[index]">
            <span v-if="index <= furthestStep" class="fade-in">
              {{ step.preText }}
            </span>
            <span :class="{ 'fade-in': index <= furthestStep }" @click="navigateToStep(index)" class="clickable-section">
              <!-- {{ index === currentStep && index < steps.length - 1 ? '______' : displayedTextForSteps[step.key] }} -->
              <CustomDropdown
                class="dropdown"
                v-if="index !== steps.length - 1"
                :options="step.options"
                :value="formData[step.key]"
                @input="updateFormData(step.key, $event); validateAndProceed(index);"
                :isOpen="currentStep === index"
                placeholder="..."
              />
            </span>
            <span v-if="index <= furthestStep" class="fade-in">
              {{ step.postText }}
            </span>
            <span v-if="errors[step.key]">({{ errors[step.key] }})</span>
          </span>
        </p>

        <div v-if="currentStep === steps.length - 1">
          <strong class="confirm-text spacer small">Ready to generate your story?</strong>
          <button class="generate-button" v-if="loadingCount <= 0" @click="submitForm">Generate</button>
          <button class="generate-button" v-if="loadingCount > 0" @click="submitForm" disabled>Loading...</button>
        </div>
      </div>
    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<style scoped>
.randomize {
  float: right;
  margin-left: 5px;
}
.no-edit {
  pointer-events: none;
  opacity: 0.8;
}
.clickable-section {
  color: var(--accent-color); /* This is Bootstrap's primary color, adjust as needed */
  cursor: pointer;
}

.dropdown {
  display: inline-block;
}

.confirm-text {
  display: inline-block;
}
.generate-button {
  display: block;
}
.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>

<style>
.dropdown.closed {
  border: none;
  width: auto;
  padding: 0;
}
.dropdown.closed .selected-option {
  padding: 0;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import apiClient from '@/helpers/api.js';
import BottomNav from '@/components/BottomNavigation.vue';
import SubTopNav from '@/components/SubTopNavigation.vue';
import CustomDropdown from '@/components/CustomDropdown.vue';

export default {
  components: {
    BottomNav,
    SubTopNav,
    CustomDropdown,
  },
  data() {
    return {
      loadingCount: 0,

      currentStep: 0,
      furthestStep: 0,

      openDropdownIndex: null,

      transitionKeys: [],

      formData: {
        theme: '',
        language: '',
        style: '',
        level: '',
      },
      errors: {
        theme: null,
        language: null,
        style: null,
        level: null,
      },
      steps: [
        {
          key: 'theme',
          label: 'Theme',
          preText: 'I want a ',
          postText: ' story ',
          options: [
            { value: 'adventure', text: 'Adventure' },
            { value: 'thriller', text: 'Thriller' },
            { value: 'crime', text: 'Crime' },
            { value: 'fantasy', text: 'Fantasy' },
            { value: 'sci-fi', text: 'Sci-Fi' },
            { value: 'drama', text: 'Drama' },
            { value: 'mystery', text: 'Mystery' },
            { value: 'western', text: 'Western' },
            { value: 'children', text: 'Children' },
          ],
        },
        {
          key: 'language',
          label: 'Language',
          preText: ' in ',
          postText: '. ',
          options: [ // These will be replaced with the supported languages from the API on created hook
            { value: 'en', text: 'English' },
            { value: 'es', text: 'Spanish' },
          ],
        },
        // {
        //   key: 'style',
        //   label: 'Writing Style',
        //   preText: ' written in the ',
        //   postText: ' perspective ',
        //   options: [
        //     { value: 'first-person', text: 'First Person' },
        //     { value: 'second-person', text: 'Second Person' },
        //     { value: 'third-person', text: 'Third Person' },
        //     { value: 'reported-speech', text: 'Reported Speech' },
        //     { value: 'stream-of-consciousness', text: 'Stream of Consciousness' },
        //     { value: 'objective', text: 'Objective' },
        //   ],
        // },
        {
          key: 'style',
          label: 'Style',
          preText: 'Write it in a ',
          postText: ' style ',
          options: [
            { value: 'dialogue-formal', text: 'Dialogue (formal)' },
            { value: 'dialogue-informal', text: 'Dialogue (informal)' },
            { value: 'story', text: 'Story' },
          ],
        },
        {
          key: 'level',
          label: 'Language Level',
          preText: ' at a ',
          postText: ' level of difficulty. ',
          options: [
            { value: 'A2', text: 'Beginner (A2)' },
            { value: 'B1', text: 'Lower Intermediate (B1)' },
            { value: 'B2', text: 'Upper Intermediate (B2)' },
            { value: 'C1', text: 'Advanced (C1)' },
          ],
        },
         {
          key: 'confirmation',
          label: '',
          preText: '',
          postText: '',
          options: []
        }
      ],
    };
  },
  computed: {
    filteredSteps() {
      return this.steps.slice(0, this.furthestStep + 1);
    },
    displayedTextForSteps() {
      return this.steps.reduce((acc, step) => {
        const selectedOption = step.options.find(option => option.value === this.formData[step.key]);
        acc[step.key] = selectedOption ? selectedOption.text : '';
        return acc;
      }, {});
    },
    ...mapState('settings', ['selectedLanguage']),
    ...mapGetters({
      supportedLanguages: 'settings/supportedLanguages',
    }),
  },
  methods: {
    updateFormData(key, value) {
      this.formData[key] = value;
    },
    navigateToStep(stepIndex) {
      this.currentStep = stepIndex;
      this.formData[this.steps[this.currentStep].key] = ''; // Reset the current (new) step's selection
    },
    validateAndProceed(stepIndex) {
      const step = this.steps[stepIndex];
      if (this.formData[step.key] === '' && step.key !== 'confirmation') {
        this.errors[step.key] = 'Please select a(n) ' + step.label.toLowerCase();
        return false;
      }
      this.errors[step.key] = null;

      // If the current step is before the furthest step the user has reached, skip ahead to the furthest step.
      if (stepIndex < this.furthestStep) {
        this.currentStep = this.furthestStep;
      } else if (stepIndex < this.steps.length - 1) {
        this.currentStep++;
        // Update furthestStep if the user progresses to a new step.
        if (this.currentStep > this.furthestStep) {
          this.furthestStep = this.currentStep;
        }
      }

      this.transitionKeys[stepIndex] = Date.now();
    },
    randomizeAnswers() {
      this.steps.forEach((step, index) => {
        if (index < this.steps.length - 1) {
          const randomOption = step.options[Math.floor(Math.random() * step.options.length)];
          this.formData[step.key] = randomOption.value;
        }
      });
      this.currentStep = this.steps.length - 1; // Move to the last step
      this.furthestStep = this.steps.length - 1; // Update furthestStep

      // this.transitionKey = Date.now(); // Force a transition
      this.transitionKeys = this.transitionKeys.map(() => Date.now());
    },
    submitForm() {
      this.loadingCount++;

      apiClient
        .post("/api/texts/generate", this.formData)
        .then((response) => {
          this.$router.push({ name: "view-text", params: { id: response.data.text.id } } );
        })
        .catch((error) => {
          console.error("Error submitting text generation request:", error);
          alert("Error generating story.");
        })
        .finally(() => {
          this.loadingCount--;
        });
    }
  },
  created() {
    // Update the 'language' step's options using the supportedLanguages
    const languageStep = this.steps.find(step => step.key === 'language');
    if (languageStep) {
      languageStep.options = this.supportedLanguages.map(language => ({
        value: language.code,
        text: language.fullNameEnglish
      }));
    }

    // Initialize the transitionKeys
    this.transitionKeys = Array(this.steps.length).fill(Date.now());
  },
};
</script>
