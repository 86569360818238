<template>
  <div class="outer-wrapper">
    <header>
      <SubTopNav
        :backRoute="backRoute"
        :settingsRoute="{ name: 'settings' }"
      />
    </header>
    <main class="inner-wrapper">
      <div class="settings">
        <h1>Full Menu</h1>

        <nav class="spacer">
          <ul>
            <li>
              <RouterLink to="/">Home</RouterLink>
            </li>
            <li>
              <RouterLink to="/texts">Texts</RouterLink>
            </li>
            <li>
              <RouterLink to="/flashcards">Flashcards</RouterLink>
            </li>
            <li v-if="isLoggedIn">
              <RouterLink to="/settings">Settings</RouterLink>
            </li>
             <li v-if="isLoggedIn">
              <RouterLink :to="{ name: 'settings', params: { section: 'logout' } }">Logout</RouterLink>
            </li>
          </ul>
        </nav>
        <div class="divider"></div>
        <nav class="spacer">
          <ul>
            <li>
              <RouterLink :to="{name: 'privacy-policy'}">Privacy Policy</RouterLink>
            </li>
            <li>
              <RouterLink :to="{name: 'terms-conditions'}">Terms & Conditions</RouterLink>
            </li>
          </ul>
        </nav>
        If you have any issues, questions of feedback, please don't hesitate to email us at <a href="mailto:contact@polyglotpal.co.uk">contact@polyglotpal.co.uk</a>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SubTopNav from '@/components/SubTopNavigation.vue';

export default {

  components: {
    SubTopNav
  },

  data() {
    return {

    };
  },

  computed: {
    ...mapState('navigation', ['latestNonSettingsRoute', 'latestSettingsRoute', 'navigatedFromSettings', 'settingsRouteStack']),
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },

    backRoute() {

      // if there is a latestNonSettingsRoute,
      // the back button should go to the latest non-settings route or home page
      if(this.latestNonSettingsRoute) {
        return this.latestNonSettingsRoute;
      }

      // Otherwise, the back button should go to the home page
      return { name: 'home' };
    }
  },

  methods: {

  },

  watch: {

  },

};
</script>

<style scoped>
  .divider {
    width: 200px;
    border-top: 1px solid var(--grey-color);
    padding-top: 10px;
  }
</style>
