<template>
  <div class="outer-wrapper home">
    <header>
      <TopNav />
    </header>
    <main>
      <div v-for="tutorial in tutorials" v-if="showTutorial">
        <TooltipOverlay
          :key="tutorial.id"
          v-if="!tutorial.done && arePreviousTutorialsDone(tutorial.id)"
          :tutorialId="tutorial.id"
          :message="tutorial.message"
          :targetSelector="tutorial.selector"
          @dismissed="dismissTutorial({ viewName: 'HomeView', tutorialId: tutorial.id })"
          @hideTutorial="hideTutorial"
        ></TooltipOverlay>
      </div>
        
      <div class="inner-wrapper">
        <div class="heading-container">
          <h1>Welcome!</h1>
        </div>

        <nav class="sub-nav">
          <RouterLink class="button" id="generate" :to="{ name: 'generate-text' }" @click="dismissTutorial({ viewName: 'HomeView', tutorialId: 'generate' })">
            <img class="icon" src="/src/assets/icons/book-white.png" alt="Generate Icon" />
            Generate Story
          </RouterLink>
          <RouterLink class="button" :to="{ name: 'upload-text' }" id="upload" @click="dismissTutorial({ viewName: 'HomeView', tutorialId: 'upload' })">
            <img class="icon" src="/src/assets/icons/upload-white.png" alt="Upload Icon" />
            Upload Text
          </RouterLink>
        </nav>
        <div class="items heading-container" v-if="userTexts.length > 0">
          <h2>Continue Reading</h2>
          <div v-if="textsLoadingCount > 0" class="loader"></div>
          <router-link :to="{ name: 'list-texts' }">
            <button class="small">More...</button>
          </router-link>
        </div>
      </div>
      <div class="outer-wrapper" v-if="userTexts.length > 0">
        <div>
          <div class="texts-container">
            <!-- The Carousel starts here -->
            <Carousel v-bind="settings" :breakpoints="breakpoints">
              <Slide v-for="text in userTexts" :key="text.id">
                <TextCard
                  class="text"
                  :text="text"
                />
              </Slide>
              <template #addons>
                <Navigation v-if="showUserTextsNavigation"/>
              </template>
            </Carousel>
            <!-- The Carousel ends here -->
          </div>
        </div>
      </div>
      <div class="inner-wrapper">
        <div class="items heading-container">
          <h2>Browse Library</h2>
          <div v-if="textsLoadingCount > 0" class="loader"></div>
          <router-link :to="{ name: 'list-texts' }">
            <button class="small">More...</button>
          </router-link>
        </div>
      </div>
      <div class="outer-wrapper">
        <div>
          <div v-if="publicTexts.length > 0" class="texts-container">
            <!-- The Carousel starts here -->
            <Carousel v-bind="settings" :breakpoints="breakpoints">
              <Slide v-for="text in publicTexts" :key="text.id">
                <TextCard
                  class="text"
                  :text="text"
                />
              </Slide>
              <template #addons >
                <Navigation v-if="showPublicTextsNavigation"/>
              </template>
            </Carousel>
            <!-- The Carousel ends here -->
          </div>
          <div v-else-if="textsLoadingCount === 0" class="inner-wrapper">
            <div>There isn't anything here! You can generate more stories, or upload your own, at the top of the page.</div>
          </div>
        </div>
      </div>
      <div class="inner-wrapper">
        <div class="items heading-container">
          <h2>Flashcards</h2>
          <div v-if="flashcardsLoadingCount > 0" class="loader"></div>
          <router-link :to="{ name: 'list-flashcards' }">
            <button class="small">More...</button>
          </router-link>
        </div>
      </div>
      <div class="outer-wrapper">
        <div>
          <div v-if="orderedLanguageGroupedFlashcards.length > 0" class="flashcard-container">
            <!-- The Carousel starts here -->
            <Carousel v-bind="settings" :breakpoints="breakpoints">
              <Slide v-for="(item, index) in orderedLanguageGroupedFlashcards" :key="index">
                 <FlashcardCategory
                    :id="'flashcards-' + index"
                    :language="item.language"
                    :dueReviews="item.flashcardsData.flashcards.length"

                    :newReviews="item.flashcardsData.total_new_flashcards"
                    :learningReviews="item.flashcardsData.total_learning_flashcards"
                    :matureReviews="item.flashcardsData.total_learned_flashcards"

                    :newReviewsNotIncluded="item.flashcardsData.not_included_new_flashcards_count"
                    :otherReviewsNotIncluded="item.flashcardsData.not_included_reviewable_flashcards_count"

                    @click="dismissTutorial({ viewName: 'HomeView', tutorialId: 'flashcards' })"
                  />
              </Slide>
              <template #addons>
                <Navigation v-if="showFlashcardsNavigation"/>
              </template>
            </Carousel>
            <!-- The Carousel ends here -->
          </div>
          <div v-else-if="flashcardsLoadingCount === 0" class="inner-wrapper">
            <div>Start reading a text and flashcards will automatically be created!</div>
          </div>
        </div>
      </div>

    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<script>
import apiClient from '@/helpers/api.js';
import BottomNav from '@/components/BottomNavigation.vue'
import TopNav from '@/components/TopNavigation.vue'
import TextCard from '@/components/TextCard.vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import FlashcardCategory from '@/components/FlashcardCategory.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import TooltipOverlay from '@/components/TooltipOverlay.vue';

export default {
  name: 'HomeView',
  components: {
    TopNav,
    BottomNav,
    TextCard,
    FlashcardCategory,
    Carousel,
    Slide,
    Navigation,
    TooltipOverlay
  },
  data() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
        mouseDrag: false,
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        320: {
          itemsToShow: 1,
        },
        370: {
          itemsToShow: 1.2,
        },
        395: {
          itemsToShow: 1.3,
        },
        440: {
          itemsToShow: 1.4,
        },
        470: {
          itemsToShow: 1.5,
        },
        510: {
          itemsToShow: 1.6,
        },
        540: {
          itemsToShow: 1.7,
        },
        570: {
          itemsToShow: 1.8,
        },
        600: {
          itemsToShow: 1.9,
        },
        640: {
          itemsToShow: 2,
        },
        // 700: {
        //   itemsToShow: 2.2,
        // },
        // 750: {
        //   itemsToShow: 2.3,
        // },
        // 800: {
        //   itemsToShow: 2.5,
        // },
      },
    }
  },
  computed: {
    ...mapState('settings', ['selectedLanguage']),
    ...mapState('texts', ['texts', 'textsLoadingCount']),
    ...mapGetters('texts', ['userTexts', 'publicTexts']),
    ...mapState('flashcards', ['languageGroupedFlashcards', 'flashcardsLoadingCount']),
    tutorials() {
      return this.$store.getters['tutorial/getTutorialsForView']('HomeView');
    },
    ...mapState('tutorial', ['showTutorial']),
    arePreviousTutorialsDone() {
      return (tutorialId) => this.$store.getters['tutorial/arePreviousTutorialsDone']('HomeView', tutorialId);
    },
    orderedLanguageGroupedFlashcards() {
      const result = [];

      // Add selected language to the front if it exists
      if (this.selectedLanguage && this.languageGroupedFlashcards[this.selectedLanguage]) {
        result.push({
          language: this.selectedLanguage,
          flashcardsData: this.languageGroupedFlashcards[this.selectedLanguage]
        });
      }

      // Add the rest of the languages
      const languages = Object.keys(this.languageGroupedFlashcards);
      languages.forEach(language => {
        if (language !== this.selectedLanguage) {
          result.push({
            language,
            flashcardsData: this.languageGroupedFlashcards[language]
          });
        }
      });

      return result;
    },
    // Determine how many items to show based on the current viewport width
    currentItemsToShow() {
      const viewportWidth = window.innerWidth;
      const breakpointValues = Object.values(this.breakpoints);
      const breakpointKeys = Object.keys(this.breakpoints);

      for (let i = breakpointKeys.length; i >= 0; i--) {
        if (viewportWidth > breakpointKeys[i]) {
          return breakpointValues[i].itemsToShow;
        }
      }

      // Default value if no breakpoints match
      return this.settings.itemsToShow;
    },

    // Determine if navigation arrows should be shown
    showUserTextsNavigation() {
      // Only show navigation if there are more texts than what's currently visible
      return this.userTexts.length > this.currentItemsToShow;
    },
    showPublicTextsNavigation() {
      return this.publicTexts.length > this. currentItemsToShow;
    },
    showFlashcardsNavigation() {
      return this.orderedLanguageGroupedFlashcards.length > this.currentItemsToShow;
    },

  },
  methods: {
    ...mapActions('texts', ['fetchTexts']),
    ...mapActions('flashcards', ['fetchFlashcards']),
    ...mapActions('tutorial', ['loadTutorialStatuses', 'dismissTutorial', 'hideTutorial', 'addTutorial', 'removeTutorial']),
    calculateCardsToShow() {
        const cardWidth = 300;  // assuming each card is 300px wide
        const viewportWidth = window.innerWidth;
        this.cardsToShow = Math.floor(viewportWidth / cardWidth);
    }
  },
  async created() {
    this.loadTutorialStatuses();
    
    // Execute both functions at the same time and await both promises
    await Promise.all([this.fetchTexts({ language: this.selectedLanguage }), this.fetchFlashcards()]);
    if(!this.orderedLanguageGroupedFlashcards.length > 0) {
      return;
    }
    if(!this.orderedLanguageGroupedFlashcards[0].flashcardsData.flashcards.length > 5) { // Wait until there are 5 flashcards to review
      return;
    }
    this.addTutorial({
      viewName: 'HomeView',
      tutorial: { 
        id: 'flashcards', 
        message: 'You have flashcards ready for review! Try to review them every day.', 
        selector: '#flashcards-0', 
      }
    });
  },
  mounted() {
    this.calculateCardsToShow();
    window.addEventListener('resize', this.calculateCardsToShow);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateCardsToShow);

    this.removeTutorial({ viewName: 'HomeView', tutorialId: 'flashcards' });
  },
}
</script>

<style scoped>

</style>

<style scoped>
.home .carousel__track {
  margin: 0 !important;
}
.home .carousel__icon:hover {
  color: grey;
}
.text {
  margin-right: 10px;
  min-width: 300px;
}
</style>
