<template>
  <div>
    <router-link :to="{ name: 'view-text', params: { id: text.id } }">
      <div class="text-card" :style="`background-color: ${color}; ${text.image_url ? `background-image: url(${fullUrl(text.image_url)});` : ''}`">
        <div class="title">
          {{ text.title ? text.title : 'Processing...' }}
        </div>
        <div class="details">
          <!-- <span class="chip" v-if="supportedLanguages">{{ supportedLanguages.find(x => x.code == text.language).fullNameOriginal }}</span> -->
          <span class="chip" v-if="text.theme">{{ text.theme }}</span>
          <span class="chip style" v-if="text.style">{{ text.style }}</span>
          <span class="chip level" v-if="text.level">{{ text.level }} level</span>
        </div>
        <div class="info" :class="text.lookups_count !== null && text.lookups_count !== undefined &&  text.progress_percent !== null && text.progress_percent !== undefined ? 'bar' : ''">
          <div class="status" v-if="text.lookups_count !== null && text.lookups_count !== undefined &&  text.progress_percent !== null && text.progress_percent !== undefined">
            <div class="item" v-if="text.status !== 'processing' && text.lookups_count !== null && text.lookups_count !== undefined">
              <div class="value">{{ text.lookups_count }}</div>
              <div class="label">Lookups</div>
            </div>
            <div class="item" v-if="text.status !== 'processing' && text.progress_percent !== null && text.progress_percent !== undefined">
              <div class="value">{{ text.progress_percent }}%</div>
              <div class="label">Progress</div>
            </div>
            <div class="item right" @click.prevent="showDeletePopup = true" v-if="text.can_delete">
              <div class="value"><img src="/src/assets/icons/trash.png" alt="Delete Icon"></div>
              <div class="label">Delete</div>
            </div>
          </div>
          <div class="language">
            <svg class="progress-ring" width="50" height="50">
              <circle class="progress-ring__circle"
                stroke="var(--action-good)"
                stroke-width="4"
                fill="transparent"
                r="28"
                cx="30"
                cy="30"
                :style="circleStyle"></circle>
            </svg>
            <div class="flag-container">
              <img v-if="languageProperties.code" :src="`/flags/${languageProperties.code}-flag.webp`" :alt="languageProperties.fullNameEnglish + ' Flag'" />
            </div>
          </div>
          <!-- <div class="delete">
            <button @click="deleteText">Delete</button>
          </div> -->
          <div class="clear"></div>
        </div>
      </div>
    </router-link>
    <PopupModal class="popup" :showModal="showDeletePopup" @close="showDeletePopup = false">
      <h3 class="spacer">Are you sure you want to delete this text?</h3>
      <div class="buttons" v-if="!loading">
        <button class="button bad" @click="deleteText">Delete</button>
        <button class="button neutral" @click="showDeletePopup = false">Cancel</button>
      </div>
      <div v-else class="loader"></div>
    </PopupModal>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import PopupModal from '@/components/PopupModal.vue';
  import apiClient from '@/helpers/api.js';

  export default {
    name: 'TextCard',
    props: {
      text: {
        type: Object,
        required: true
      }
    },
    components: {
      PopupModal
    },
    data() {
      return {
        showDeletePopup: false,
        loading: false,
      };
    },
    computed: {
      ...mapState('settings', ['supportedLanguages', 'selectedLanguage']),
      ...mapGetters({
        supportedLanguages: 'settings/supportedLanguages',
      }),
      circleStyle() {
        let progress_percent = this.text.progress_percent;
        if(!this.text.progress_percent) {
          progress_percent = 0;
        }
        const radius = 28;
        const circumference = 2 * Math.PI * radius;
        const offset = circumference - (progress_percent / 100 * circumference);
        return {
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: offset
        };
      },
      languageProperties() {
        let languageProperties = this.supportedLanguages.find(item => item.code === this.text.language);
        if(!languageProperties) {
          languageProperties = {
            fullNameEnglish: 'Unknown',
          };
        }
        return languageProperties;
      },
      color() {
        // Generate a random color based on the integer ID, such that it is always the same color for a given ID, in RGB format with opacity 0.4
        
        // Simple formula to spread out the integer IDs
        let hash = (this.text.id * 2654435761) % 4294967296;
        
        const c = (hash & 0x00FFFFFF)
          .toString(16)
          .toUpperCase();

        return `#${"00000".substring(0, 6 - c.length)}${c}66`;
      }
    },
    methods: {
      fullUrl(url) {
        return `${import.meta.env.VITE_API_URL}${url}`;
      },
      decodeImageData(imageData) {
        return `data:image/jpeg;base64, ${imageData}`;
      },
      async deleteText() {
        this.loading = true;
        try {
          await this.$store.dispatch('texts/deleteText', this.text.id)
        } catch (error) {
          console.error('Error deleting text:', error);
          alert('Issue deleting text. Please try again later.');
        } finally {
          this.loading = false;
          this.showDeletePopup = false;
        }
      },
    }
  }
</script>

<style scoped>
.popup {
  position: fixed;

}
a {
  display: inline-block;
  width: 100%;
}
a:hover {
  text-decoration: none;
}
.text-card {
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 75px;
  border-radius: 10px;
  text-align: left;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}
.text-card .title {
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.4em; /* for example */
  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  position: relative;
  margin-bottom: 10px;
}
.text-card .title .button {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: normal;
  font-size: 1rem;
}

.text-card .details {
  display: flex;
  gap: 8px;
}
.text-card .details .chip {
  border-radius: 20px;
  background-color: var(--secondary-color);
  color: white;
  padding: 0px 5px;
  font-size: 0.9rem;
  text-transform: capitalize;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
}
.text-card .details .chip.level {
  background-color: rgb(179, 102, 255);
}
.text-card .details .chip.style {
  background-color: rgb(255, 102, 102);
}
.text-card .info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  height: 40px;
  box-sizing: border-box;
  padding: 10px;
}
.text-card .info.bar {
  background-color: var(--white-color);
  border-top: 1px solid #ccc;
  height: 55px;
}
.text-card .info .language {
  position: absolute;
  right: 10px;
  top: -25px;
}
.text-card .info .language .flag-container {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #ccc;
  color: var(--text-color);
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}
.text-card .info .language .flag-container img  {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.text-card .info .language .progress-ring {
  z-index:3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;  /* Increased size */
  height: 60px; /* Increased size */
}
.text-card .info .language .progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.text-card .info .progress {
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 50%;
  color: var(--text-color);
  position: absolute;
  right: 10px;
  width: 50px;
  text-align: center;
  top: 30px;
}

.text-card .processing {
  font-weight: 600;
  color: var(--text-color);
  height: 100%;
  line-height: 35px;
}
.text-card .status {
  display: flex;
  padding-right: 55px;
  gap: 0.5rem;
}
.text-card .status .item .label {
  font-size: 0.7rem;
  color: var(--text-color);
  margin-bottom: 0.2rem;
  text-align: center;
}
.text-card .status .item .value {
  font-weight: 600;
  color: var(--text-color);
  text-align: center;
  height: 24px;
}
.text-card .status .item.right {
  margin-left: auto;
}
.item img {
  height: 20px;
}

.loader {
  display: block;
  margin: 0 auto;
}

</style>
