<template>
  <div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper login-container">
      <h1>Forgot Password</h1>
      <form @submit.prevent="submit">
        <div class="input-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <!-- Add an error message element -->
        <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
        <div v-if="successMessage" class="success-message text small">{{ successMessage }}</div>
        <button v-if="!loading" class="login-button" type="submit">Reset Password</button>
        <button v-else class="login-button" type="submit" disabled>Loading...</button>
      </form>

      <div class="text small">Remember your password? <RouterLink :to="{name: 'login'}">Login here</RouterLink></div>

    </main>
  </div>
</template>

<script>
import TopNav from "@/components/TopNavigation.vue";
import apiClient from "@/helpers/api.js"

export default {
  components: {
    TopNav,
  },
  data() {
    return {
      email: "",

      errorMessage: "",
      successMessage: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        this.errorMessage = "";
        this.successMessage = "";
        let response = await apiClient.post("/api/forgot-password", {
          email: this.email,
        });

        if(response.data.message) {
          this.successMessage = response.data.message;
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        // Set the error message based on the error response
        if (error.response && error.response.status === 400 || error.response.status === 422) {
          this.errorMessage = error.response.data.message ? error.response.data.message : "Something went wrong. Please try again later.";
        } else {
          this.errorMessage = "An error occurred. Please try again later.";
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
input {
  width: 300px;
}

</style>
