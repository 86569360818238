<template>
  <div class="outer-wrapper">
    <header>
      <SubTopNav
        :backRoute="backRoute"
      />
    </header>
    <main class="inner-wrapper">
      <div v-if="section == 'general'" class="settings">
        <h1 class="spacer">General Settings</h1>
       
        <div>
          <div class="input-group spacer">
            <label for="playback-speed">Tutorial:</label>
            <button @click="resetTutorial(); resetTutorialMessage = 'Tutorial reset successfully'">Reset Tutorial</button>
            <div v-if="resetTutorialMessage" class="success-message text small">{{ resetTutorialMessage }}</div>
          </div>

          <!-- <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
          <div v-if="successMessage" class="success-message text small">{{ successMessage }}</div>
          <button v-if="!settingsLoading" type="submit">Save Settings</button>
          <button v-else type="submit" disabled>Loading...</button> -->
        </div>
      </div>
      <div v-else-if="section == 'flashcards'" class="settings">
        <h1 class="spacer">Flashcard Settings for {{ selectedLanguageProperties.fullNameEnglish }} ({{ selectedLanguageProperties.fullNameOriginal }})</h1>
       
        <form @submit.prevent="save">
          <div class="input-group spacer">
            <label for="playback-speed">Voice playback speed:</label>
            <input id="playback-speed" v-model.number="appSettings.voice_playback_speed" type="range" min="0.3" max="2" step="0.1"> {{ appSettings.voice_playback_speed }}x
          </div>

          <div class="input-group spacer">
            <label for="playback-speed">Automatically play text to speech:</label>
            <span class="text small">(When viewing a new flashcard)</span>
            <v-switch v-model="appSettings.voice_autoplay" color="#12969b" :label="`${appSettings.voice_autoplay ? 'On' : 'Off'}`"></v-switch>
          </div>

          <strong class="spacer">The below settings only apply to {{ selectedLanguageProperties.fullNameEnglish}}! Change language in the top right if needed.</strong>
          <div class="clear spacer"></div>
          
          <div class="input-group spacer">
            <label for="max-reviews">Max reviews per day:</label>
            <input id="max-reviews" v-model.number="localSettings.max_reviews_per_day" type="number" min="0">
          </div>

          <div class="input-group spacer">
            <label for="new-flashcards">New flashcards per day:</label>
            <input id="new-flashcards" v-model.number="localSettings.max_new_flashcards_per_day" type="number" min="0">
          </div>

          <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
          <div v-if="successMessage" class="success-message text small">{{ successMessage }}</div>
          <button v-if="!anythingLoading" type="submit">Save Settings</button>
          <button v-else type="submit" disabled>Loading...</button>
        </form>
      </div>

      <div v-else-if="section == 'texts'" class="settings">
        <h1 class="spacer">Text Settings</h1>
        <form @submit.prevent="save">
          <div class="input-group">
            <label for="playback-speed">Voice playback speed:</label>
            <input id="playback-speed" v-model.number="appSettings.voice_playback_speed" type="range" min="0.3" max="2" step="0.1"> {{ appSettings.voice_playback_speed }}x
          </div>

          <div class="input-group">
            <label for="playback-speed">Automatically play next sentence:</label>
            <span class="text small">(When a sentence finishes playing)</span>
            <v-switch v-model="appSettings.voice_autocontinue" color="#12969b" :label="`${appSettings.voice_autocontinue ? 'On' : 'Off'}`"></v-switch>
          </div>
          
          <div class="input-group spacer">
            <label for="max-reviews">Font Size:</label>
            <input id="max-reviews" v-model.number="localSettings.font_size" type="number" min="8" max="56">
          </div>

          <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
          <div v-if="successMessage" class="success-message text small">{{ successMessage }}</div>
          <button v-if="!anythingLoading" type="submit">Save Settings</button>
          <button v-else  type="submit" disabled>Loading...</button>
        </form>
      </div>

      <div v-else-if="section == 'email'" class="settings">
        <h1 class="spacer">Email Preferences</h1>
        
        <div v-if="isGuest">
          <p class="spacer">You are currently logged in as a guest.</p> 
          <p>Please <router-link class="button neutral" :to="{ name: 'login' }">login</router-link> or <router-link class="button good" :to="{ name: 'signup' }">sign up</router-link> to manage your email preferences.</p>
        </div>

        <form v-else @submit.prevent="saveEmailPreferences">
          <div class="input-group spacer">
            <label>
              <input type="checkbox" v-model="localSettings.flashcard_reminders" :disabled="anythingLoading">
              Flashcard Reminders
            </label>
            <span class="text small">Daily reminder to review flashcards</span>
          </div>

          <div class="input-group spacer">
            <label>
              <input type="checkbox" v-model="localSettings.updates" :disabled="anythingLoading">
              App Updates
            </label>
            <span class="text small">Information about new features</span>
          </div>

          <div class="input-group spacer">
            <label>
              <input type="checkbox" v-model="localSettings.uploaded_text_processed" :disabled="anythingLoading">
              Uploaded Text Processed
            </label>
            <span class="text small">An alert when an uploaded text has finished processing. For long texts, it can take a while.</span>
          </div>

          <div class="input-group spacer">
            <label>
              <input type="checkbox" v-model="localSettings.generated_text_processed" :disabled="anythingLoading">
              Generated Text Processed
            </label>
            <span class="text small">An alert when a generated text has finished processing. This is usually pretty quick.</span>
          </div>


          <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
          <div v-if="successMessage" class="success-message text small">{{ successMessage }}</div>
          <button v-if="!anythingLoading" type="submit">Save Settings</button>
          <button v-else  type="submit" disabled>Loading...</button>
        </form>
      </div>


      <div v-else-if="section == 'manage-subscription'" class="settings">
        <ManageSubscription />
      </div>

      <div v-else-if="section == 'payment-method'" class="settings">
        <PaymentMethod />
      </div>

      <div v-else-if="section == 'subscribe'" class="settings">
        <Subscribe />
      </div>

      <div v-else-if="section == 'logout'" class="settings">
        <Logout />
      </div>

      <div v-else class="settings">
        <h1 class="spacer">Settings</h1>
        <nav class="spacer">
          <ul>
            <li>
              <RouterLink :to="{ name: 'settings', params: { section: 'general' } }">
                General Settings
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="{ name: 'settings', params: { section: 'flashcards' } }">
                Flashcard Settings
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="{ name: 'settings', params: { section: 'texts' } }">
                Text Settings
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="{ name: 'settings', params: { section: 'manage-subscription' } }">
                Subscription Settings
              </RouterLink>
            </li>
            <li v-if="!isGuest">
              <RouterLink :to="{ name: 'settings', params: { section: 'email' } }">
                Email Preferences
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="{ name: 'settings', params: { section: 'logout' } }">
                Logout
              </RouterLink>
            </li>
            <li v-if="this.$store.state.settings.deferredPrompt">
              <button @click="$store.dispatch('settings/setShowInstallPrompt', true)">Install app!</button>
            </li>
          </ul>
        </nav>
        If you have any issues, questions of feedback, please don't hesitate to email us at <a href="mailto:contact@polyglotpal.co.uk">contact@polyglotpal.co.uk</a>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SubTopNav from '@/components/SubTopNavigation.vue';
import ManageSubscription from '../views/subscription/ManageSubscription.vue';
import PaymentMethod from '../views/subscription/PaymentMethod.vue';
import Subscribe from '../views/subscription/Subscribe.vue';
import Logout from '../views/auth/Logout.vue';
import apiClient from '@/helpers/api.js';

export default {
  components: {
    SubTopNav,
    ManageSubscription,
    PaymentMethod,
    Subscribe,
    Logout,
  },

  data() {
    return {
      section: this.$route.params.section ? this.$route.params.section : null, // Which settings "page" to show
      // Local copy of settings stored in backend
      localSettings: {
        // Flashcards
        max_reviews_per_day: null,
        max_new_flashcards_per_day: null,
        // Texts
        font_size: null,

        // Multiple areas
        voice_playback_speed: 1,

        // Email Preferences
        flashcard_reminders: null,
        updates: null,
        uploaded_text_processed: null,
        generated_text_processed: null,
      },

      // Settings stored in localstorage
      appSettings: {
        voice_playback_speed: 1,
        voice_autoplay: false,
      },

      // Tutorial
      resetTutorialMessage: false,

      loading: false,
      errorMessage: '',
      successMessage: '',
    };
  },

  computed: {
    ...mapState('settings', ['settings', 'selectedLanguage', 'requestsInProgress']),
    ...mapState('navigation', ['latestNonSettingsRoute', 'latestSettingsRoute', 'navigatedFromSettings', 'settingsRouteStack']),
    ...mapGetters({
      settingsLoading: 'settings/loading',
      supportedLanguages: 'settings/supportedLanguages',
      selectedLanguageProperties: 'settings/selectedLanguageProperties',
      updateLatestNonSettingsRoute: 'navigation/updateLatestNonSettingsRoute',
    }),
    ...mapGetters({
      isGuest: 'user/isGuest',
    }),
    anythingLoading() {
      return this.settingsLoading || this.loading;
    },
    settingsForSelectedLanguage() {
      return this.settings[this.selectedLanguage] || {};
    },
    backRoute() {
      // If there are more than one routes in the settingsRouteStack,
      // the back button should go to the second-to-last route in the stack
      if(this.settingsRouteStack.length > 1) {
        return this.settingsRouteStack[this.settingsRouteStack.length - 2];
      }

      // Otherwise, if there is a latestNonSettingsRoute,
      // the back button should go to the latest non-settings route or home page
      if(this.latestNonSettingsRoute) {
        return this.latestNonSettingsRoute;
      }

      // Otherwise, the back button should go to the home page
      return { name: 'home' };
    }
  },

  methods: {
    ...mapActions('settings', ['setSettings', 'saveSettings']),
    ...mapActions('tutorial', ['resetTutorial']),
    save() {
      this.errorMessage = '';
      this.successMessage = '';

      // Save app settings to localstorage
      localStorage.setItem('appSettings', JSON.stringify(this.appSettings));

      this.saveSettings(this.localSettings)
        .then(() => {
          this.localSettings = this.settingsForSelectedLanguage;
          this.successMessage = 'Settings saved successfully';
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = 'Something went wrong.';
        });
    },
    async saveEmailPreferences() {
      try {
        this.loading = true;
        await apiClient.put('/api/email-preferences', {
          flashcard_reminders: this.localSettings.flashcard_reminders,
          updates: this.localSettings.updates,
          uploaded_text_processed: this.localSettings.uploaded_text_processed,
          generated_text_processed: this.localSettings.generated_text_processed,
          // ... include other settings as needed
        });
        this.successMessage = 'Settings successfully updated';
      } catch (error) {
        this.errorMessage = 'An error occurred while updating settings';
      } finally {
        this.loading = false;
      }
    }
  },

  watch: {
    settingsForSelectedLanguage: {
      handler(newVal) {
        this.localSettings = { ...newVal };
      },
      deep: true,
    },
    // Watch URL for change
    '$route.params.section': {
      handler(newVal) {
        this.section = newVal;
      },
      immediate: true,
    },
    section: {
      handler(newVal) {
        this.errorMessage = '';
        this.successMessage = '';
      },
    }
  },

  async created() {
    // Local settings are a copy of the settings for the selected language, which are stored on the user in the backend
    this.localSettings = this.settingsForSelectedLanguage;

    // App settings are stored in localstorage, and are not language specific
    const appSettings = localStorage.getItem('appSettings');
    if (appSettings) {
      this.appSettings = JSON.parse(appSettings);
    }

    // Email settings are stored in the backend, in a separate table
    try {
      this.loading = true;
      const response = await apiClient.get('/api/email-preferences');

      // Update localSettings with the fetched data
      this.localSettings.flashcard_reminders = response.data.flashcard_reminders;
      this.localSettings.updates = response.data.updates;
      this.localSettings.uploaded_text_processed = response.data.uploaded_text_processed;
      this.localSettings.generated_text_processed = response.data.generated_text_processed;

      this.loading = false;
      // ... Fetch other settings if needed
    } catch (error) {
      this.loading = false;
      console.error("An error occurred while fetching settings:", error);
    }
  }

};
</script>

<style scoped>
input {
  width: 100px;
}

#playback-speed {
  width: 200px;
  vertical-align: middle;
}

.v-switch {
  height: 60px;
}

input[type="checkbox"] {
  width:20px;
}

</style>
