<template>
  <div class="custom-dropdown" :class="isOpen ? 'open' : 'closed'" @click="handleDropdownClick">

    <div class="selected-option" :class="!selectedOption ? 'placeholder' : ''">
      {{ selectedOption ? selectedOption.text : placeholder }}
    </div>
    <div v-if="isOpen" class="options-list">
      <div
        v-for="option in options"
        :key="option.value"
        @click.stop="selectOption(option)"
        class="dropdown-option"
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-dropdown {
  position: relative;
  border: 1px solid #ccc;
  width: 200px;
  cursor: pointer;
}

.selected-option {
  padding: 5px;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}
.selected-option.placeholder {
  color: var(--grey-color-dark);
}

.dropdown-option {
  padding: 5px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #eaeaea;
}
</style>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: [String, Number],
    placeholder: {
      type: String,
      default: "Select..."
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.value === this.value);
    }
  },
 methods: {
    handleDropdownClick(event) {
      if (event.target.classList.contains('selected-option')) {
        this.$emit('toggle-dropdown'); // Signal to parent to toggle the dropdown
      }
    },
    selectOption(option) {
      this.$emit('input', option.value);
      this.$emit('change', option.value);
      this.$emit('dropdown-clicked'); // Signal that an option was selected
    }
}

};
</script>
