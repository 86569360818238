<template>
  <div class="top-nav">
    <div class="outer-wrapper top-nav-inner">
      <div class="left positioned">
        <router-link
          class="home-link"
          :to="{ name: 'home' }"
        >
        <img class="logo" src="/src/assets/logo.png" alt="Logo">
        <span class="brand-name">Polyglot Pal</span>
        </router-link>
      </div>

      <div class="right positioned" v-if="showSettings && isLoggedIn">
        <LanguageDropdown v-if="selectedLanguage" :selected="selectedLanguage" @language-selected="updateLanguage" />

        <router-link
          v-if="isLoggedIn"
          class="settings"
          :to="settingsRoute"
        >
          <img class="settings icon" src="/src/assets/navigation/settings.png" alt="Settings Icon">
        </router-link>

      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import LanguageDropdown from '@/components/LanguageDropdown.vue';

export default {
  props: {
    selected: {
      type: String,
      default: ''
    },
    showSettings: {
      type: Boolean,
      default: true,
    },
    settingsRoute: {
      type: Object,
      default: () => ({ name: 'settings' }),
    }
  },
  components: {
    LanguageDropdown,
  },
  data() {
    return {
      languages: [],
      localSelectedLangauge: null,

      showDropdown: false, // whether the dropdown is currently shown
    }
  },
  computed: {
    ...mapState('settings', ['selectedLanguage', 'supportedLanguages']),
    ...mapGetters({
      errors: 'error/errors',
      settingsLoading: 'settings/loading',
      selectedLanguageProperties: 'settings/selectedLanguageProperties',
    }),
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  methods: {
    ...mapActions({
      removeError: 'error/removeError',
      setSelectedLanguage: 'settings/setSelectedLanguage',
      fetchSupportedLanguages: 'settings/fetchSupportedLanguages',
    }),
    closeDropdown() {
      this.showDropdown = false;
    },
    updateLanguage(language) {
      this.showDropdown = false; // close the dropdown

      // Update local language immediately but keep the previous language in case the update fails
      this.previousSelectedLanguage = this.selectedLanguage;
      this.localSelectedLangauge = language;

      this.setSelectedLanguage(language)
        .then(() => {
          // this.localSelectedLangauge = this.selectedLanguage;
        })
        .catch(() => {
          // If the update fails, revert to the previous language
          this.localSelectedLangauge = this.previousSelectedLanguage;
        });
    },
  },
  watch: {
    selectedLanguage(newVal) {
      this.localSelectedLangauge = newVal;
    },
  },
  created() {
    this.localSelectedLangauge = this.selectedLanguage;
  },
}

</script>

<style scoped>
.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--grey-color-light);
  border-top: 1px solid var(--grey-color);
  z-index: 100;
}
.top-nav a {
  width: 30px;
}
.top-nav-inner {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.top-nav .left {
  padding-left: 10px;
}
.top-nav .home-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--black-color);
  width: auto;
  height: 100%;
}
.top-nav .left .logo {
  height: 35px;
  margin-bottom: 5px;
}
.top-nav .left .brand-name {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
}
.top-nav .right {
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-nav .right img {
  display: block;
  margin: 0 auto;
}
.top-nav .settings.icon {
  height: 20px;
}
</style>
