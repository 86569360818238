<template>
  <div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper login-container">
      <h1>Sign Up</h1>
      <form @submit.prevent="register">
        <div class="input-group">
          <label>Email:</label>
          <input type="email" v-model="email" required />
        </div>
        <div class="input-group">
          <label>Password:</label>
          <input type="password" v-model="password" required />
        </div>
        <div class="input-group">
          <label>Confirm Password:</label>
          <input type="password" v-model="password_confirmation" required />
        </div>
        <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
        <div class="text small">By continuing, you agree to our <RouterLink :to="{name: 'terms-conditions'}">Terms & Conditions</RouterLink>.</div>
        <button v-if="!loading" type="submit">Sign Up</button>
        <button v-else type="submit" disabled>Loading...</button>

      </form>

      <div class="text small">Already have an account? <RouterLink :to="{name: 'login'}">Login here</RouterLink></div>
    </main>
  </div>
</template>

<script>
import TopNav from "@/components/TopNavigation.vue";

export default {
  components: {
    TopNav,
  },

  data() {
    return {
      email: "",
      password: "",
      password_confirmation: "",

      errorMessage: "", // Add an errorMessage property to the data
      loading: false,

      redirect: this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : false,
    };
  },
  methods: {
    async register() {
      
      try {
        this.loading = true;
        this.errorMessage = "";
       
        await this.$store.dispatch("user/register", {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });

        if(this.redirect) {
          this.$router.push(this.redirect);
          return;
        }
        
        this.$router.push({ name: "home" });
        this.loading = false;
      } catch (error) {
        // Set the error message based on the error response
        if (error.response && error.response.status === 422) {
          this.errorMessage = error.response.data.message ? error.response.data.message : "Something went wrong. Please try again later.";
        } else {
          this.errorMessage = "An error occurred. Please try again later.";
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
input {
  width: 300px;
}
button {
  margin-bottom: 10px;
}

</style>
