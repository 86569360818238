import apiClient from '@/helpers/api.js';

const state = {
  languageGroupedFlashcards: [],
  flashcardsLoadingCount: 0
};

const mutations = {
  SET_FLASHCARDS(state, payload) {
    state.languageGroupedFlashcards = payload;
  },
  INCREMENT_FLASHCARDS_LOADING_COUNT(state) {
    state.flashcardsLoadingCount++;
  },
  DECREMENT_FLASHCARDS_LOADING_COUNT(state) {
    state.flashcardsLoadingCount--;
  }
};

const actions = {
	async fetchFlashcards({ state, commit }, force = false) {
    const shouldShowLoading = force || !Object.keys(state.languageGroupedFlashcards).length;

    if (shouldShowLoading || 1) {
      commit('INCREMENT_FLASHCARDS_LOADING_COUNT');
    }
    
    try {
      const response = await apiClient.get('/api/flashcards/languages');
      commit('SET_FLASHCARDS', response.data.flashcards);
    } catch (error) {
      console.error(error);
    }

    if (shouldShowLoading || 1) {
      commit('DECREMENT_FLASHCARDS_LOADING_COUNT');
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
