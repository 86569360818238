export default {
  genderOutput(g) {
    switch (g) {
      case 'm':
      case 'male':
        return 'masculine';
      case 'f':
      case 'female':
        return 'feminine';
      case 'n':
      case 'neutral':
        return 'neuter';
      case 'p':
        return 'plural';
      default:
        return g;
    }
  }
}
