import apiClient from '@/helpers/api.js';

const state = {
	unfinishedTexts: [],
	finishedTexts: [],
	publicTexts: [],
	filteredTexts: [],
	textsLoadingCount: 0,

	filters: {
		language: 'de',
		themes: ['crime', 'love', 'fantasy', 'science-fiction', 'horror', 'humor', 'adventure', 'history', 'other'],
		level: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
		unseen: false,
	}
};

const mutations = {
  SET_UNFINISHED_TEXTS(state, payload) {
    state.unfinishedTexts = payload;
  },
  SET_FINISHED_TEXTS(state, payload) {
    state.finishedTexts = payload;
  },
  SET_PUBLIC_TEXTS(state, payload) {
	state.publicTexts = payload;
  },

  INCREMENT_TEXTS_LOADING_COUNT(state) {
    state.textsLoadingCount++;
  },
  DECREMENT_TEXTS_LOADING_COUNT(state) {
    state.textsLoadingCount--;
  },
  
  REMOVE_UNFINISHED_TEXT(state, id) {
    state.unfinishedTexts = state.unfinishedTexts.filter(text => text.id !== id);
  },
  REMOVE_FINISHED_TEXT(state, id) {
    state.finishedTexts = state.finishedTexts.filter(text => text.id !== id);
  },
};


const actions = {
	async fetchTexts({ state, commit }, { language = null, force = false }) {
		const shouldShowLoading = force || (state.unfinishedTexts.length === 0 && state.finishedTexts.length === 0);

		if (shouldShowLoading || 1) {
			commit('INCREMENT_TEXTS_LOADING_COUNT');
		}

		try {
			const params = language ? { language: language } : {};

			const [textsResponse, publicTextsResponse] = await Promise.all([
				apiClient.get('/api/texts/opened', { params }),
				apiClient.get('/api/texts/public', { params }),
			]);
	
			const unfinishedTexts = Object.values(textsResponse.data.unfinished_texts);
			const finishedTexts = Object.values(textsResponse.data.finished_texts);
			commit('SET_UNFINISHED_TEXTS', unfinishedTexts);
			commit('SET_FINISHED_TEXTS', finishedTexts);
	
			const publicTexts = Object.values(publicTextsResponse.data);
			commit('SET_PUBLIC_TEXTS', publicTexts);
		} catch (error) {
			console.error(error);
		}

		if (shouldShowLoading || 1) {
			commit('DECREMENT_TEXTS_LOADING_COUNT');
		}
	},

	async deleteText({ commit }, id) {
		try {
			await apiClient.delete(`/api/texts/${id}`);

			commit('REMOVE_UNFINISHED_TEXT', id);
			commit('REMOVE_FINISHED_TEXT', id);
		} catch (error) {
			console.error('Error deleting text:', error);
			// Handle errors if the deletion fails
		}
	}
};

const getters = {
	userTexts: state => [...state.unfinishedTexts, ...state.finishedTexts],
	publicTexts: state => state.publicTexts,
	allTexts: state => [...state.unfinishedTexts, ...state.finishedTexts, ...state.publicTexts],
	// filteredTexts: state => {
	// 	let texts = [];
	// 	if(state.filters.unseen) {
	// 		texts = state.publicTexts;
	// 	} else {
	// 		texts = [...state.unfinishedTexts, ...state.finishedTexts, ...state.publicTexts];
	// 	}
	// 	console.log(texts);

	// 	if (state.filters.language) {
	// 	  texts = texts.filter(text => text.language === state.filters.language);
	// 	}
	  
	// 	if (state.filters.themes.length > 0) {
	// 	  texts = texts.filter(text => state.filters.themes.includes(text.theme));
	// 	}
	  
	// 	if (state.filters.level.length > 0) {
	// 	  texts = texts.filter(text => state.filters.level.includes(text.level));
	// 	}
	  
	// 	return texts;
	// }
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
