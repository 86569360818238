import apiClient from "@/helpers/api.js";

const state = {
  settings: {},
  selectedLanguage: null,
  supportedLanguages: [],
  requestsInProgress: 0,

  initialLoading: true, // Is true only on first load of settings, for knowing when to display the splash screen

  deferredPrompt: null, // This is the install PWA prompt. Once we have this, we can prompt the user to install the PWA
  showInstallPrompt: false,
};

const getters = {
  settings: (state) => state.settings,
  selectedLanguage: (state) => state.selectedLanguage,
  selectedLanguageProperties: (state) => {
    // Find the language object in the supportedLanguages array where the code matches the selected language
    const language = state.supportedLanguages.find(
      (lang) => lang.code === state.selectedLanguage
    );
    // If a matching language was found, return its properties. Otherwise, return an empty object.
    return language || {};
  },
  supportedLanguages: (state) => state.supportedLanguages,
  loading: (state) => state.requestsInProgress > 0,
  initialLoading: (state) => state.initialLoading,

  deferredPrompt: (state) => state.deferredPrompt,
  showInstallPrompt: (state) => state.showInstallPrompt,
};

const actions = {
  setSettings({ commit }, { language, settings }) {
    commit("setSettings", { language, settings });
  },
  async init({ dispatch, commit }) {
    commit("setInitialLoading", true);
    await dispatch("fetchSupportedLanguages");
    await dispatch("fetchSettings");
    commit("setInitialLoading", false);
  },
  async setSelectedLanguage({ commit }, language) {
    commit("incrementRequestsInProgress");
    return apiClient
      .post("/api/current-language", { language: language })
      .then((response) => {
        commit("setSelectedLanguage", language);
        commit("setSettings", { language: language, settings: response.data });
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      })
      .finally(() => {
        commit("decrementRequestsInProgress");
      });
  },
  async fetchSupportedLanguages({ commit, state }) {
    commit("incrementRequestsInProgress");
    return apiClient
      .get("/api/supported-languages")
      .then((response) => {
        commit("setSupportedLanguages", response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        commit("decrementRequestsInProgress");
      });
  },
  async fetchSettings({ commit, state }) {
    commit("incrementRequestsInProgress");
    return apiClient
      .get("/api/user-settings")
      .then((response) => {
        commit("setSelectedLanguage", response.data.selected_language);
        commit("setSettings", {
          language: response.data.selected_language,
          settings: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        commit("decrementRequestsInProgress");
      });
  },
  async setInitialLoading({ commit }, loading) {
    commit("setInitialLoading", loading);
  },
  async saveSettings({ commit, state }, settings) {
    commit("incrementRequestsInProgress");
    return apiClient
      .post("/api/user-settings", {
        settings: settings,
        language: state.selectedLanguage,
      })
      .then(() => {
        commit("setSettings", {
          language: state.selectedLanguage,
          settings: settings,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        commit("decrementRequestsInProgress");
      });
  },

  setDeferredPrompt({ commit }, promptEvent) {
    commit("setDeferredPrompt", promptEvent);
  },
  setShowInstallPrompt({ commit }, value) {
    commit("setShowInstallPrompt", value);
    // delete localstorage flag if user has dismissed the prompt
  },
  async handleBeforeInstallPrompt({ commit, dispatch, getters }, event) {
    event.preventDefault();
    commit("setDeferredPrompt", event);

    let hasSeen = await dispatch("checkIfSeenInstallPrompt");
    if (!hasSeen) {
      // Note the change here
      dispatch("setShowInstallPrompt", true);
    }
  },
  setInstallPromptFlag(_, days = 1) {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + days); // Expires in x days
    localStorage.setItem(
      "installPromptSeen",
      JSON.stringify({ expiryDate: expiryDate.toISOString() })
    );
  },
  checkIfSeenInstallPrompt({ commit }) {
    const flag = localStorage.getItem("installPromptSeen");
    let hasSeen = false;
    if (flag) {
      const expiryDate = new Date(JSON.parse(flag).expiryDate);
      if (expiryDate > new Date()) {
        hasSeen = true;
      }
    }
    return hasSeen;
  },
  promptInstall({ state, commit, dispatch }) {
    if (state.deferredPrompt) {
      state.deferredPrompt.prompt();
      state.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          commit("setShowInstallPrompt", false);
        } else {
          dispatch("setInstallPromptFlag", 1);
          commit("setShowInstallPrompt", false);
          commit("setDeferredPrompt", null);
        }
      });
    }
  },
};

const mutations = {
  setSettings(state, { language, settings }) {
    if (!state.settings[language]) {
      state.settings[language] = {};
    }
    state.settings[language] = { ...state.settings[language], ...settings };
  },
  setSelectedLanguage(state, language) {
    state.selectedLanguage = language;
  },
  setSupportedLanguages(state, languages) {
    state.supportedLanguages = languages;
  },
  incrementRequestsInProgress(state) {
    state.requestsInProgress++;
  },
  decrementRequestsInProgress(state) {
    state.requestsInProgress--;
  },
  setInitialLoading(state, loading) {
    state.initialLoading = loading;
  },

  setDeferredPrompt(state, payload) {
    state.deferredPrompt = payload;
  },
  setShowInstallPrompt(state, payload) {
    state.showInstallPrompt = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
