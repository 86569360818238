<template>
  <div>
    <!-- <pre>{{ flashcard }}</pre> -->
    <pre>{{ flashcard.word.word }}</pre>
    <div v-if="isLoading">Loading...</div>
    <!-- <pre>{{ flashcard.original.sentences }}</pre> -->
    <div v-else>
      Click the words you don't know!
      <SelectableTextOutput
        :content="generatedSentence"
        @selectWord="selectWord"
      />

     <div v-if="translationsLoadingCount <=0">
      <Lookup
        v-if="lookup"
        :lookup="lookup"
        :selectedWord="selectedWord"
        :fullSentence="fullSentence"
      />
      </div>
      <div v-else>Lookup Loading...</div>

      <div class="review-details" v-if="showResult && translationsLoadingCount <= 0 && !isLoading">
        <strong class="title">Result</strong>
        <div>The word being tested was: <span>{{ flashcard.word.word }}</span></div>
        <div v-if="matchedFlashcardWord">It looks like you didn't know this word, so we suggest you mark it as failed.</div>
        <div v-else-if="!matchedFlashcardWord">It looks like you already knew this word! So we suggest you mark it as successful.</div>
        <div>We might not always be correct, so please update the result below if so.</div>
        <div>
          <input type="radio" id="successful" value="true" v-model="successfulResult">
          <label for="successful">Mark result as Successful</label>
        </div>
        <div>
          <input type="radio" id="failed" value="false" v-model="successfulResult">
          <label for="failed">Mark result as Failed</label>
        </div>
        <button @click="next()">Next!</button>
      </div>

       <div class="flashcard-buttons" v-if="translationsLoadingCount <= 0 && !isLoading">
          <button class="next" @click="showResult = true">Done! Next!</button>
        </div>
    </div>
  </div>
</template>

<script>
  import apiClient from "@/helpers/api.js";
  import SelectableTextOutput from "@/components/SelectableTextOutput.vue";
  import Lookup from '@/components/Lookup.vue';

  export default {
    props: {
      flashcard: {
        type: Object,
        required: true,
      },
    },
    components: {
      SelectableTextOutput,
      Lookup
    },
    data() {
      return {
        generatedSentence: null,
        lookup: null,
        selectedWord: '',
        fullSentence: '',
        showResult: false,
        matchedFlashcardWord: false,
        successfulResult: true,

        isLoading: false,

        translationsLoadingCount: 0,
      };
    },
    methods: {
      next() {
        this.showResult = false; // Reset showResult
        this.lookup = null; // Reset lookup
        this.$emit('next', JSON.parse(this.successfulResult));
      },
      async generateSentence() {
        try {
          this.isLoading = true;
          const response = await apiClient.post("/api/flashcards/generateSentence", {
            word: this.flashcard.word.word,
            word_data: JSON.stringify(this.flashcard.word),
            example_sentences: JSON.stringify(this.flashcard.sentences),
          });

          this.generatedSentence = JSON.parse(response.data).sentence;
        } catch (error) {
          // Handle error
          console.error(error);
        } finally {
          // Set isLoading to false after the request is complete
          this.isLoading = false;
        }
      },
      selectWord(selectedWord, fullSentence) {
        this.selectedWord = selectedWord;
        this.fullSentence = fullSentence;

      // Lookup word and start a chat with the selected word / sentence
        this.lookupWord(selectedWord, fullSentence);

        if(selectedWord == this.flashcard.original.word.word) {
          this.$emit('lookedUpFlashcardWord');
        }
      },
      lookupWord(selectedWord, selectedSentence) {
        // if(!this.text.chat_threads.some((thread) => thread.word_context === selectedWord && thread.sentence_context === selectedSentence)) {
        this.translationsLoadingCount++;
        apiClient.post("/api/lookup", {
          word_context: selectedWord,
          sentence_context: selectedSentence,
          related_word: this.flashcard.original.word.word, // uses the word we are testing against to check if the word is the same (other than case, declination etc)
          related_sentence: this.flashcard.translations[0].sentences[0].sentence.sentence,
        }).then(threadResponse => {
          this.lookup = threadResponse.data.lookup;
          this.matchedFlashcardWord = threadResponse.data.matchedRelatedWord ? true : this.matchedFlashcardWord; // Set to true if the word matches the flashcard word. Leave it as it is if not.
          if(this.matchedFlashcardWord) {
            this.successfulResult = false;
          }
          this.translationsLoadingCount--;
          // this.startChat(this.selectedWord, this.fullSentence);
        }).catch(error => {
          this.translationsLoadingCount--;
          console.error(error);
        });
      },
    },
    created() {
      // this.generateSentence();
    },
    // Also call generateSentence when the flashcard prop changes
    watch: {
      flashcard: {
        handler: "generateSentence",
        immediate: true,
      },
    },

  }
</script>

<style scoped>

</style>
