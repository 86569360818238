<template>
  <div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper login-container">
      <div class="heading-container">
        <h1>Welcome!</h1>
      </div>
      <button class="button good spacer" @click="continueAsGuest">Continue as guest</button>
      <div class="text small">By continuing, you agree to our <RouterLink :to="{name: 'terms-conditions'}">Terms & Conditions</RouterLink>.</div>
      <div class="spacer">or</div>

      <form @submit.prevent="submit">
        <div class="heading-container spacer">
          <h2>Login</h2>
        </div>
        <div class="input-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="input-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <!-- Add an error message element -->
        <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
        <button v-if="!loading" class="login-button" type="submit">Login</button>
        <button v-else class="login-button" type="submit" disabled>Loading...</button>
      </form>


	    <div class="text small">Don't have an account yet? <RouterLink :to="{name: 'signup'}">Sign up here</RouterLink></div>
	    <div class="text small">Forgot your password? <RouterLink :to="{name: 'forgot-password'}">Reset password here</RouterLink></div>
    </main>
  </div>
</template>

<script>
import TopNav from "@/components/TopNavigation.vue";

export default {
  components: {
    TopNav,
  },
  data() {
    return {
      email: "",
      password: "",

      errorMessage: "", // Add an errorMessage property to the data
      loading: false,

      redirect: this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : false,
    };
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        this.errorMessage = "";
        await this.$store.dispatch("user/login", {
          email: this.email,
          password: this.password,
        });

        // Redirect to a protected route or update the UI
        if(this.redirect) {
          this.$router.push(this.redirect);
          return;
        }
        this.$router.push({ name: "home" });
        this.loading = false;
      } catch (error) {
        console.error(error);
        // Set the error message based on the error response
        if (error.response && error.response.status === 422) {
          this.errorMessage = error.response.data.message ? error.response.data.message : "Something went wrong. Please try again later.";
        } else {
          this.errorMessage = "An error occurred. Please try again later.";
        }
        this.loading = false;
      }
    },

    continueAsGuest() {
      this.$store.dispatch("user/continueAsGuest")
        .then(() => {
          this.$router.push({ name: "account-setup" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>

</style>
