<template>
  <div>
    <div class="heading-container">
      <h2>Are you sure you want to logout?</h2>
    </div>
    <button @click="logout" v-if="!loading">Logout</button>
    <button v-if="loading" disabled>Loading</button>
  </div>
</template>

<script>
import SubTopNav from '@/components/SubTopNavigation.vue';

export default {
  components: {
    SubTopNav,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async logout() {
      this.loading = true;
      try {
        await this.$store.dispatch('user/logout');

        // Redirect to the login page or update the UI
        this.$router.push({ name: 'login' });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
