export default {
	debounce(func, wait, immediate) {
		return function executedFunction(...args) {
			const context = this;

			const later = function () {
				this.debounceTimeout = null;
				if (!immediate) func.apply(context, args);
			};

			const callNow = immediate && !this.debounceTimeout;

			clearTimeout(this.debounceTimeout);

			this.debounceTimeout = setTimeout(later, wait);

			if (callNow) func.apply(context, args);
		};
	}
}
