<template>
  <div>
    <div class="heading-container">
      <h1>Manage Subscription</h1>
    </div>

    <div v-if="isGuest">
      <p class="spacer">You are currently logged in as a guest.</p> 
      <p>Please <router-link class="button neutral" :to="{ name: 'login', query: { redirect: encodeURIComponent('/settings/manage-subscription') } }">login</router-link> or <router-link class="button good" :to="{ name: 'signup', query: { redirect: encodeURIComponent('/settings/manage-subscription') } }">sign up</router-link> to manage your subscription.</p>
    </div>

    <div v-else>
      <div v-if="loading">Loading...</div>

      <div v-if="!loading" class="section">
        <!-- show their subscription price (in case price has changed and they are on an old plan)-->
        <div class="heading-container" v-if="subscription && subscription.status == 'active'">
          <h2>Plan: {{ activePlan.name }} (£{{ activePlan.amount }} {{ activePlan.currency.toUpperCase() }})</h2>
        </div>
        <!-- otherwise show the plan we are actively selling -->
        <div class="heading-container" v-else>
          <h2>Plan: {{ plan.name }} (£{{ plan.amount }} {{ plan.currency.toUpperCase() }})</h2>
        </div>
        <!-- <pre>
        {{ subscription }}
        </pre> -->
        <div v-if="subscription && subscription.status == 'active'">
          <!-- <p>Plan: Premium Plan</p> -->
        
          <div v-if="subscription.cancel_at_period_end">
            <p>Status: <strong class="status error-message">Set to expire</strong> {{ subscription.cancel_at_period_end ? " at period end - " + formatDate(subscription.current_period_end) : ""}}</p>
            <strong class="neutral-message">Your recurring payments have been cancelled, and your subscription will end on the {{ formatDate(subscription.current_period_end) }}.</strong>
            <p class="neutral-message spacer">You can resume your subscription at any time before this date.</p>
            <button class="button good" v-if="subscription.cancel_at_period_end" @click="resumeSubscription">
              <img class="icon" src="/src/assets/icons/tick-white.png" alt="Tick Icon" />
              Resume Subscription
            </button>
          </div>

          <div v-else>
            <p>Status: <strong class="status success-message">Active</strong> {{ subscription.cancel_at_period_end ? "(Set to cancel at period end - " + formatDate(subscription.current_period_end) + ")" : ""}}</p>
            <p class="success-message">Your subscription is active and will renew on the {{ formatDate(subscription.current_period_end) }}.</p>
            <button class="button bad" v-if="!subscription.cancel_at_period_end" @click="cancelSubscription">Cancel Subscription</button>
          </div>
        </div>
        <div v-else>
          <p class="spacer">You do not have an active subscription. <span v-if="!hasPaymentMethod">To subscribe, please add a payment method below!</span></p>
          <div v-if="hasPaymentMethod">
            <router-link class="button good" :to="{ name: 'settings', params: { section: 'subscribe' } }">
              <img class="icon" src="/src/assets/icons/tick-white.png" alt="Tick Icon" />
              Subscribe Now!
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="!loading" class="section">
        <div class="heading-container">
          <h2 class="spacer">Saved Payment Method</h2>
        </div>
        <router-link class="button" v-if="!hasPaymentMethod" :to="{ name: 'settings', params: { section: 'payment-method' } }">Add a Payment Method</router-link>
        <div v-if="hasPaymentMethod">
          <!-- output the payment details -->
          <p>Payment Method: {{ paymentMethod.brand }} ****{{ paymentMethod.last4 }}</p>
          <p class="spacer">Expires: {{ paymentMethod.exp_month }}/{{ paymentMethod.exp_year }}</p>

          <router-link class="button" v-if="hasPaymentMethod" :to="{ name: 'settings', params: { section: 'payment-method' } }">
            <img class="icon" src="/src/assets/icons/cards-white.png" alt="Payment Icon" />
            Change Payment Method
          </router-link> <br>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.section {
  margin-bottom: 20px;
}
</style>

<script>
import apiClient from "@/helpers/api.js";
import SubTopNav from "@/components/SubTopNavigation.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    SubTopNav,
  },
  data() {
    return {
      hasPaymentMethod: false,
      paymentMethod: null,

      subscription: null,
      plan: null,

      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest',
    }),
  },
  methods: {
    formatDate(timestamp) {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString();
    },
    async getPlanDetails() {
      const response = await apiClient.get('/api/subscriptions/plan-details');
      this.plan = response.data;
    },
    async checkPaymentMethod() {
      const response = await apiClient.get('/api/subscriptions/has-payment-method');
      this.hasPaymentMethod = response.data.hasPaymentMethod;
      this.paymentMethod = response.data.paymentMethod;
    },
    async fetchSubscription() {
      try {
        const response = await apiClient.get('/api/subscriptions/current');
        this.subscription = response.data.subscription;
        this.activePlan = response.data.activePlan;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Handle the case where the user does not have a subscription - this is fine
          console.error("No active subscription found");
        } else {
          // Handle other errors
          console.error(error);
        }
      }
    },
    async cancelSubscription() {
      this.loading = true;
      await apiClient.delete('/api/subscriptions/cancel');
      await this.fetchSubscription();
      this.loading = false;
    },
    async resumeSubscription() {
      this.loading = true;
      await apiClient.post('/api/subscriptions/resume');
      await this.fetchSubscription();
      this.loading = false;
    },
    // async swapPlan(plan) {
    //   this.loading = true;
    //   await apiClient.post('/api/subscriptions/swap', { plan });
    //   await this.fetchSubscription();
    //   this.loading = false;
    // },
  },
  async mounted() {
    this.loading = true;
    await Promise.all([this.checkPaymentMethod(), this.getPlanDetails(), this.fetchSubscription()]);
    this.loading = false;
  },
};
</script>
