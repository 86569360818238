<template>
  <div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper">
      <div class="items heading-container">
        <h1>Flashcards</h1>
        <div v-if="flashcardsLoadingCount > 0" class="loader"></div>
      </div>
      <div>
        <div v-if="currentLanguageFlashcards">
          <FlashcardCategory
            :language="selectedLanguage"
            :dueReviews="currentLanguageFlashcards.flashcards.length"

            :newReviews="currentLanguageFlashcards.total_new_flashcards"
            :learningReviews="currentLanguageFlashcards.total_learning_flashcards"
            :matureReviews="currentLanguageFlashcards.total_learned_flashcards"

            :newReviewsNotIncluded="currentLanguageFlashcards.not_included_new_flashcards_count"
            :otherReviewsNotIncluded="currentLanguageFlashcards.not_included_reviewable_flashcards_count"
          />
        </div>
        <div v-else-if="flashcardsLoadingCount === 0">
          <h3>No flashcards for {{ selectedLanguageProperties.fullNameEnglish }} yet!</h3>
          <div class="spacer">Start creating flashcards by looking up words from a text!</div>
          <RouterLink class="button spacer" :to="{ name: 'list-texts' }">View Texts</RouterLink>
        </div>

        <div v-if="Object.keys(otherLanguageGroupedFlashcards).length > 0">
          <h3>Other Languages</h3>
        </div>
          <div class="flashcards-container">
          <FlashcardCategory
            v-for="(flashcards, language) in otherLanguageGroupedFlashcards" :key="language"
            :language="language"
            :dueReviews="flashcards.flashcards.length"

            :newReviews="flashcards.total_new_flashcards"
            :learningReviews="flashcards.total_learning_flashcards"
            :matureReviews="flashcards.total_learned_flashcards"

            :newReviewsNotIncluded="flashcards.not_included_new_flashcards_count"
            :otherReviewsNotIncluded="flashcards.not_included_reviewable_flashcards_count"
          />
        </div>
      </div>
      <!-- <RouterLink :to="{ name: 'create-flashcard' }">Create Flashcard</RouterLink> -->
    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<script>
import apiClient from '@/helpers/api.js';
import BottomNav from '@/components/BottomNavigation.vue';
import TopNav from '@/components/TopNavigation.vue';
import FlashcardCategory from '@/components/FlashcardCategory.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BottomNav,
    TopNav,
    FlashcardCategory,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('settings', ['selectedLanguage']),
    ...mapState('flashcards', ['languageGroupedFlashcards', 'flashcardsLoadingCount']),
    ...mapGetters({
      selectedLanguageProperties: 'settings/selectedLanguageProperties',
    }),
    currentLanguageFlashcards() {
      if(!this.selectedLanguage || !this.languageGroupedFlashcards) {
        return null;
      }
      return this.languageGroupedFlashcards[this.selectedLanguage];
    },
    otherLanguageGroupedFlashcards() {
      if(!this.selectedLanguage) {
        return this.languageGroupedFlashcards;
      }
      const { [this.selectedLanguage]: _, ...otherLanguages } = this.languageGroupedFlashcards;
      return otherLanguages;
    }
  },
  methods: {
    ...mapActions('flashcards', ['fetchFlashcards']),
    async deleteFlashcard(id, index) {
      if (confirm('Are you sure you want to delete this flashcard?')) {
        try {
          let response = await apiClient.delete(`/api/flashcards/${id}`);
          if (response.status === 204) {
            this.flashcards.splice(index, 1); // Remove locally
          } else {
            console.error('Error deleting flashcard:', response);
            alert('Issue deleting flashcard. Please try again later.');
          }
        } catch (error) {
          console.error('Error deleting flashcard:', error);
          alert('Issue deleting flashcard. Please try again later.');
        }
      }
    },
	  async init() {
      await this.fetchFlashcards();
    }
  },
  async created() {
    this.init();
  },
};
</script>

<style scoped>
.flashcards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
