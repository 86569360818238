import apiClient from '@/helpers/api.js';

function safelyParseJSON(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null; // or return an empty object {} depending on your needs
  }
}

const state = {
  token: localStorage.getItem('token') || '',
  user: safelyParseJSON(localStorage.getItem('user')) || '',
  guestID: localStorage.getItem('guest_id') || '',
  isLoggedIn: !!localStorage.getItem('token'), // Set to true if there's a token in localStorage
};

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  isGuest: (state) => state.guestID && state.guestID != '' ? true : false,
  getGuestID: (state) => state.guestID,
  user: (state) => state.user,
};

const actions = {
  // Store user and token data in localStorage and commit mutations
  setUserAndToken({ commit }, { user, token }) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));

    commit('setToken', token);
    commit('setUser', user);
    commit('setIsLoggedIn', true);
    
    // Check if user has guest_id and set isGuest accordingly
    if (user.guest_id) {
      commit('setGuestID', user.guest_id);
    } else {
      commit('setGuestID', '');
    }
  },

  // Register or login as guest based on localStorage
  async continueAsGuest({ dispatch, state }) {
    const guest_id = state.guestID;
    // If guest_id exists, login as guest
    if (guest_id) {
        return dispatch('guestLogin', { guest_id });
    }
    // If not, register as a new guest
    return dispatch('guestRegister');
  },

  async login({ dispatch }, { email, password }) {
    try {
		  await apiClient.get("/sanctum/csrf-cookie");
      const response = await apiClient.post("/api/login", {
        email: email,
        password: password,
      });

      if(response.response && response.response.status == 422) {
        return Promise.reject(response);
      }

      dispatch('setUserAndToken', response.data);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },

  async guestLogin({ dispatch }, { guest_id }) {
    try {
      await apiClient.get("/sanctum/csrf-cookie");
      const response = await apiClient.post("/api/guest-login", {
        guest_id: guest_id,
      });

      if(response.response && response.response.status == 422) {
        return Promise.reject(response);
      }

      dispatch('setUserAndToken', response.data);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },

  async register({ dispatch, state }, { email, password, password_confirmation }) {
    const response = await apiClient.post("/api/register", {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      guest_id: state.guestID ? state.guestID : null,
    });

    dispatch('setUserAndToken', response.data);
  },

  async guestRegister({ dispatch, commit }) {
    try {
      const response = await apiClient.post("/api/guest-register");

      if(response.response && response.response.status == 422) {
        return Promise.reject(response);
      }

      dispatch('setUserAndToken', response.data);
      commit('setGuestID', response.data.user.guest_id);

    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },

  async verifyEmail({ dispatch }, { id, hash }) {
    const response = await apiClient.get(`/api/email/verify/${id}/${hash}`);

    dispatch('setUserAndToken', response.data);
  },

  async wipeLoginData({ commit }) {
    commit('setToken', '');
    commit('setUser', '');
    commit('setIsLoggedIn', false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },

  async logout({ dispatch }) {
    await dispatch('wipeLoginData');

    return apiClient.post('/api/logout')
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, user) {
    state.user = user;
  },
  setIsLoggedIn(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  setIsGuest(state, value) {
    state.isGuest = value;
  },
  setGuestID(state, guest_id) {
    state.guestID = guest_id;
    // Add to localstorage
    localStorage.setItem('guest_id', guest_id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
