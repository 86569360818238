import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from "@sentry/vue";
import 'vue3-carousel/dist/carousel.css';
import './assets/main.css';
import RollbarPlugin from './plugins/rollbar.js'; // Path to your rollbar.js file

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App);

Sentry.init({
  dsn: "https://d79b81d0528ee1034e27b2da699b2b0f@o4506498112421888.ingest.sentry.io/4506671254536192",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api.polyglotpal\.co\.uk/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Custom directive to scroll to bottom of chat window
app.directive('scroll-bottom', {
  beforeMount(el) {
    el.scrollTop = el.scrollHeight;
  },
  updated(el, binding) {
    if (binding.value) { // Only fire when the provided value is truthy, so that it only happens for updates we want to trigger on
      el.scrollTop = el.scrollHeight;
    }
  },
});

if (import.meta.env.MODE === 'production') {
// Registering the Rollbar Error handler
  app.use(RollbarPlugin);

  window.onerror = function (message, source, lineno, colno, error) {
      rollbar.error(error);
  };
}
app.use(router);
app.use(store);
app.use(vuetify);

app.mount('#app');
