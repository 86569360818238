<template>
  <div class="outer-wrapper">
    <header>
      <SubTopNav
        :backRoute="{ name: 'list-flashcards' }"
        :settingsRoute="{ name: 'settings', params: { section: 'flashcards' } }"
      />
    </header>
    <main class="inner-wrapper">
      <h1>Create a Flashcard</h1>
      <nav>
        <a href="" @click.prevent="$router.go(-1)">&lt; Back</a>
        <RouterLink :to="{ name: 'list-flashcards' }">View Flashcards</RouterLink>
      </nav>
      <div v-if="loading">Loading...</div>
      <form @submit.prevent="createFlashcard" v-if="!loading">
        <div class="input-group">
          <label for="word">Word:</label>
          <textarea id="word" v-model="word" required></textarea>
        </div>
        <div class="input-group">
          <label for="sentence">Sentence example:</label>
          <textarea id="sentence" v-model="sentence"></textarea>
        </div>
        <button type="submit">Create Flashcard</button>
      </form>
    </main>
  </div>
</template>

<script>
import apiClient from '@/helpers/api.js';
import SubTopNav from '@/components/SubTopNavigation.vue';

export default {
  components: {
    SubTopNav,
  },
  data() {
    return {
      word: '',
      sentence: '',

      loading: false,
    };
  },
  methods: {
    async createFlashcard() {
      try {
        this.loading = true;
        apiClient.post("/api/lookup", {
          word_context: this.word,
          sentence_context: this.sentence,
        }).then(threadResponse => {
          alert('Flashcards created!');
        }).catch(error => {
          console.error(error);
        }).finally(() => {
          this.translationsLoadingCount--;
          this.loading = false;
        });

        // Wipe the inputs so they can make more cards.
        this.word = '';
        this.sentence = '';

      } catch (error) {
        console.log(error);
        console.error('something went wrong');
      }
    },
  },
};
</script>

<style scoped>
textarea {
  width: 250px;
  height: 100px;
}
</style>
