import apiClient from '@/helpers/api.js';
import axios from 'axios'; // Used for passing cancelTokens to requests

const state = {
  currentAudio: null,
  isAudioPlaying: false,
  isLoading: false,
  currentButtonID: null,
  buttons: {},  // Stores the state of each button

  // Autoplay functionality. This is used to determine if the next audio should be played automatically, and which component should be playing it.
  audioEndedNaturallyNotification: 0, // This is incremented when the audio ends uninterupted. It it watched so that auto-play next audio can be triggered in SelectableTextOutput.vue.
  shouldAutoPlay: false, // This is set to true when the user clicks the play button, if that component has the relevant prop. It is used to determine if the next audio should be played automatically.
  autoPlayComponentID: null, // This is set to the ID of the component that should auto-playing. It is used to determine if the next audio should be played automatically.

  cancelTokenSource: null, // Used to cancel requests
  audioContext: null,
  wasStoppedManually: false, // Used to determine if the audio was stopped manually or if it ended naturally
};

const getters = {
  // currentAudio: (state) => state.currentAudio,
  isLoading: (state) => state.isLoading,
  isPlaying: (state) => state.isAudioPlaying,
};

const actions = {
  async speech({ commit, state }, { id, text, languageCode }) {
    state.wasStoppedManually = false; // Reset the flag
    // if (state.isLoading) return;

    if (!state.audioContext) {
      commit('setAudioContext', new (window.AudioContext || window.webkitAudioContext)());
    }

    // iOS specific - if the audio context is suspended, resume it
    if (state.audioContext && state.audioContext.state === 'suspended') {
      await state.audioContext.resume();

    }

    // If there's an audio playing, stop it
    if (state.currentAudio) {
      state.wasStoppedManually = true;
      state.currentAudio.stop();
      // Don't do the rest of the function
    }

    // If it's already playing, treat like a stop button
    if(state.currentButtonID === id) {
      if(state.cancelTokenSource) { // If there is a lookup request already in progress, cancel it
        state.cancelTokenSource.cancel('Operation canceled by the user.');
      }
      commit('setCurrentButtonID', null);
      commit('resetButtonState', id);
      commit('setLoading', false);
      return;
    }

    commit('setCurrentButtonID', id);
    commit('setIsLoading', id);


    // Iterate over the buttons and reset their state
    for (let buttonId in state.buttons) {
      if (buttonId !== id) {
        delete state.buttons[buttonId];
      }
    }

    try {
      if(state.cancelTokenSource) { // If there is a lookup request already in progress, cancel it
        state.cancelTokenSource.cancel('Operation canceled by the user.');
      }

      const CancelToken = axios.CancelToken; // Create a new cancel token for this request
		  state.cancelTokenSource = CancelToken.source();

      const appSettings = JSON.parse(localStorage.getItem('appSettings') ?? '{}');
      const speed = appSettings.voice_playback_speed ?? 1;

      const response = await apiClient.post('/api/voice', { text, languageCode, speed }, { cancelToken: state.cancelTokenSource.token });
      let audioData = response.data.audio;
      let audioBlob = base64ToBlob(audioData, 'audio/mpeg'); // Make sure the MIME type is accurate
      let audioUrl = URL.createObjectURL(audioBlob);


      // Fetch the audio and decode it for the Web Audio API
      fetch(audioUrl).then(response => {
        URL.revokeObjectURL(audioUrl); // Revoke the URL after fetching for memory management.
        return response.arrayBuffer();
      }).then(buffer => {
        state.audioContext.decodeAudioData(buffer, function(decodedData) {
          let source = state.audioContext.createBufferSource();
          source.buffer = decodedData;
          source.connect(state.audioContext.destination);

          // Add an event listener for the "ended" event
          source.onended = () => {
            if (!state.wasStoppedManually) {
              commit('incrementAudioEndedNaturallyNotification');
            }
            state.wasStoppedManually = false; // Reset the flag
            commit('setCurrentAudio', { id: null, audio: null }); // Reset the current audio
            commit('setCurrentButtonID', null);
            commit('resetButtonState', id);
            commit('setIsAudioPlaying', false);
          };

          source.start(0);
          commit('setCurrentAudio', { id, audio: source }); // Store the source as it can be used to stop playback
          commit('setIsAudioPlaying', true);
          commit('setLoading', false);
        });
      });

    } catch (error) {
      if (axios.isCancel(error)) {
        commit('resetButtonState', id);
        state.cancelTokenSource = null; // Reset the cancel token
      } else {
        commit('resetButtonState', id);
        commit('setLoading', false);
        console.error(error);
      }
    }
  },

  async stop({ commit, state }, id) {
    state.wasStoppedManually = true;
    if (state.currentAudio) {
      state.currentAudio.stop();
      // commit('stopAudio');
    }

    if(state.cancelTokenSource) { // If there is a lookup request already in progress, cancel it
      state.cancelTokenSource.cancel('Operation canceled by the user.');
    }
    commit('setCurrentButtonID', null);
    commit('resetButtonState', id);
    commit('setLoading', false);
  },

  setShouldAutoPlay({ commit }, shouldAutoPlay) {
    commit('setShouldAutoPlay', shouldAutoPlay);
  },
  setAutoPlayComponentID({ commit }, id) {
    commit('setAutoPlayComponentID', id);
  },
};

const mutations = {
  setAudioContext(state, context) {
    state.audioContext = context;
  },
  setIsAudioPlaying(state, isPlaying) {
    state.isAudioPlaying = isPlaying;
  },
  setCurrentButtonID(state, id) {
    state.currentButtonID = id;
  },
  setCurrentAudio(state, { id, audio }) {
    state.buttons[id] = { isPlaying: true, isLoading: false };
    state.currentAudio = audio;
    state.currentButtonID = id;
    state.isLoading = false;
  },
  setIsLoading(state, id) {
    state.buttons[id] = { isPlaying: false, isLoading: true };
    state.isLoading = true;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  // setIsPlaying(state, id) {
  //   if (state.buttons[id]) {
  //     state.buttons[id].isPlaying = true;
  //     state.buttons[id].isLoading = false;
  //     state.isLoading = false;
  //   }
  // },
  resetButtonState(state, id) {
    if (state.buttons[id]) {
      state.buttons[id].isPlaying = false;
      state.buttons[id].isLoading = false;
    }
  },
  incrementAudioEndedNaturallyNotification(state) {
    state.audioEndedNaturallyNotification++;
  },
  setShouldAutoPlay(state, shouldAutoPlay) {
    state.shouldAutoPlay = shouldAutoPlay;
  },
  setAutoPlayComponentID(state, id) {
    state.autoPlayComponentID = id;
  },
  // stopAudio(state) {
  //   if (state.currentAudio) {
  //     state.currentAudio = null;
  //   }
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function base64ToBlob(base64, mime) {
  mime = mime || '';
  var sliceSize = 1024;
  var byteChars = window.atob(base64);
  var byteArrays = [];

  for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
    var slice = byteChars.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: mime});
}
