<template>
  <div class="outer-wrapper">
    <header>
      <SubTopNav
        :backRoute="{ name: 'list-texts' }"
        :settingsRoute="{ name: 'settings', params: { section: 'texts' } }"
      />
    </header>
    <main class="inner-wrapper">
      <h1>Upload</h1>

      <div v-if="loadingCount > 0">Loading...</div>
      <div v-else>
        <div class="input-group">
          <label>How would you like to upload?</label>
          <v-btn-toggle class="button" v-model="method" mandatory>
            <v-btn value="text">Paste Text</v-btn>
            <v-btn value="file">Upload File</v-btn>
          </v-btn-toggle>
        </div>

        <form @submit.prevent="upload">
          <div v-if="method === 'file'">
            <div class="input-group">
              <label>Choose a file</label>
              <span class="text small">Supported formats: .txt, .epub</span>
              <input type="file" @change="handleFileChange" accept=".txt,.epub,.zip" /> <br>
            </div>
          </div>

          <div v-if="method === 'text'">
            <div class="input-group">
              <label>Paste or type text below</label>
              <textarea v-model="pastedText" rows="5" cols="50"></textarea> <br>
            </div>
          </div>

          <div class="input-group spacer">
            <label>What language is this text in? </label>
            <CustomDropdown
              class="dropdown"
              v-if="supportedLanguages"
              :options="supportedLanguages.map((language) => ({ text: language.fullNameEnglish, value: language.code }))"
              :value="language"
              @input="selectLanguage"
              :isOpen="isOpen"
              @toggle-dropdown="isOpen = !isOpen"
              placeholder="Select a language"
            />
          </div> 

          <button type="submit" :disabled="method === 'file' ? !selectedFile : !pastedText">Upload Text</button>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import apiClient from '@/helpers/api.js';
import SubTopNav from '@/components/SubTopNavigation.vue';
import CustomDropdown from '@/components/CustomDropdown.vue';

export default {
  components: {
    SubTopNav,
    CustomDropdown,
  },
  data() {
    return {
      method: 'file', // default to "upload file"
      selectedFile: null,
      pastedText: '',

      language: null,
      isOpen: false,

      loadingCount: 0,
    };
  },
  computed: {
    ...mapState('settings', ['supportedLanguages', 'selectedLanguage']),
  },
  methods: {
    selectLanguage(item) {
      this.language = item;
      this.isOpen = false;
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async upload() {
      this.loadingCount++;

      if (this.method === 'file' && !this.selectedFile) {
        return;
      }

      if (this.method === 'text' && !this.pastedText) {
        return;
      }

      const formData = new FormData();
      if (this.method === 'file') {
        formData.append('file', this.selectedFile);
      } else {
        formData.append('text', this.pastedText);
      }
      formData.append('language', this.language);

      try {
        // Replace this URL with the actual API endpoint for uploading files in your application
        const response = await apiClient.post('/api/texts', formData);

        if (response.status === 200) {
          this.$router.push({ name: 'view-text', params: { id: response.data.text.id } });
        } else {
          alert('Failed to upload file. Please try again.');
        }

      } finally {
        this.loadingCount--;
      }
    },
  },
  created() {
    this.language = this.selectedLanguage;
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: inherit;
  letter-spacing: inherit; 
}
.v-btn-group {
  padding: 0;
  height: auto;
}
</style>
