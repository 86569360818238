import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'
import HomeView from '../views/HomeView.vue'
import ListTexts from '../views/texts/ListTexts.vue';
import ViewText from '../views/texts/ViewText.vue';
import FileUpload from '../views/texts/FileUpload.vue';
import GenerateStory from '../views/texts/GenerateStory.vue';
import ListFlashcards from '../views/flashcards/ListFlashcards.vue';
import CreateFlashcard from '../views/flashcards/CreateFlashcard.vue';
import ReviewFlashcards from '../views/flashcards/ReviewFlashcards.vue';
import Login from '../views/auth/Login.vue';
import Signup from '../views/auth/Signup.vue';
import AccountSetup from '../views/auth/AccountSetup.vue';
import VerifyEmail from '../views/auth/VerifyEmail.vue';
import ForgotPassword from '../views/auth/ForgotPassword.vue';
import ResetPassword from '../views/auth/ResetPassword.vue';
import Settings from '../views/Settings.vue';
import Menu from '../views/Menu.vue';
import NotFound from '../views/NotFound.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import TermsConditions from '../views/TermsConditions.vue';


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/texts',
      name: 'list-texts',
      component: ListTexts,
      meta: { title: 'Polyglot Pal | View Texts' }
    },
    {
      path: '/texts/:id',
      name: 'view-text',
      params: true,
      component: ViewText,
      meta: { title: 'Polyglot Pal | Read Text' }
    },
    {
      path: '/texts/upload',
      name: 'upload-text',
      component: FileUpload,
      meta: { title: 'Polyglot Pal | Upload Text' }
    },
    {
      path: '/texts/generate',
      name: 'generate-text',
      component: GenerateStory,
      meta: { title: 'Polyglot Pal | Generate Text' }

    },
    {
      path: '/flashcards',
      name: 'list-flashcards',
      component: ListFlashcards,
      meta: { title: 'Polyglot Pal | Flashcards' }
    },
    {
      path: '/flashcards/create',
      name: 'create-flashcard',
      component: CreateFlashcard,
      meta: { title: 'Polyglot Pal | Create Flashcard' }
    },
    {
      path: '/flashcards/review/:language',
      name: 'review-flashcards',
      params: true,
      component: ReviewFlashcards,
      meta: { title: 'Polyglot Pal | Review Flashcards' }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: 'Polyglot Pal | Login', public: true }
    },
    {
      path: "/signup",
      name: "signup",
      component: Signup,
      meta: { title: 'Polyglot Pal | Signup', public: true }
    },
    {
      path: '/account-setup',
      name: 'account-setup',
      component: AccountSetup,
      meta: { title: 'Polyglot Pal | Setup' }
    },
    {
      path: '/email/verify/:id/:hash',
      name: 'verify-email',
      component: VerifyEmail,
      meta: { title: 'Polyglot Pal | Verify Email', public: true }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: { title: 'Polyglot Pal | Forgot Password', public: true }
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: ResetPassword,
      meta: { title: 'Polyglot Pal | Reset Password', public: true }
    },
    {
      path: "/settings/:section?", // Defines which settings page to show
      name: "settings",
      component: Settings,
      meta: { title: 'Polyglot Pal | Settings' }
    },
    {
      path: "/menu",
      name: "menu",
      component: Menu,
      meta: { title: 'Polyglot Pal | Menu' }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFound,
      meta: { title: 'Polyglot Pal | Page Not Found' }
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: { title: 'Polyglot Pal | Privacy Policy', public: true }
    },
    {
      path: '/terms-conditions',
      name: 'terms-conditions',
      component: TermsConditions,
      meta: { title: 'Polyglot Pal | Terms & Conditions', public: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  // Set the page title
  const pageTitle = to.meta.title
  if (pageTitle) {
    document.title = pageTitle
  } else {
    document.title = 'Polyglot Pal'
  }

  // Check auth guards first
  const authRequired = !to.meta.public;
  const loggedIn = isLoggedIn();
  const guest = isGuest();

  if (authRequired && !loggedIn) {
    // Redirect to the login page if user is not authenticated and trying to access a page that requires authentication
    next({
      path: '/login',
      query: { redirect: encodeURIComponent(to.fullPath) }
    });
    return;
  } else if (!authRequired && loggedIn && !guest && (to.name === 'login' || to.name === 'signup' || to.name === 'forgot-password')) {
    // If the user is already logged in and trying to access the login or signup page, redirect them to logout
    next('/settings/logout');
    return;
  }

  // If the selectedLanguage is not set and the current route is not 'account-setup' (or other routes that should be accessible), redirect to 'account-setup'
  // There is also a redirect after fetchSettings is completed in App.vue, in case this is skipped due to the loading getter returning true
  // console.log('selectedLanguage: ' + store.state.settings.selectedLanguage);
  // console.log('loggedIn: ' + loggedIn);
  // console.log('loading: ' + store.getters['settings/loading']);
  // console.log('to.name: ' + to.name);
  // console.log('to.params.section: ' + to.params.section);
  // console.log(navigator.onLine);
  
  // if (navigator.onLine && loggedIn && store.state.settings.selectedLanguage === null && !store.getters['settings/loading'] && to.name !== 'account-setup' && to.name !== 'verify-email' && to.name !== 'signup' && !(to.name === 'settings' && to.params.section === 'logout')) {
  //   next('/account-setup');
  //   return;
  // }

  // NOTE: if you're trying to fix the menu -> settings -> back -> homepage navigation issue (skipping the menu when clicking back), don't bother. Unless we rework this considerably this is the best outcome.

  // If navigating from a settings page
  if (from.path.startsWith('/settings')) {
    store.dispatch('navigation/updateNavigatedFromSettings', true);

    // If also navigating to a settings page, check if we're going deeper
    if (to.path.startsWith('/settings') && !(to.name === 'settings' && to.params.section === 'logout')) { // Don't include logout page to prevent infinite loop when redirected from signup/login
      // Get the current length of the settings route stack
      const currentLength = store.state.navigation.settingsRouteStack.length;

      // Check if the "to" route is earlier in the settings route stack
      const toIndex = store.state.navigation.settingsRouteStack.findIndex(route => route.fullPath === to.fullPath);

      if (toIndex > -1 && toIndex < currentLength - 1) {
        // If the "to" route is found and it's earlier in the stack, we're navigating back
        // Pop the stack until we reach the "to" route
        while (store.state.navigation.settingsRouteStack.length > toIndex + 1) {
          store.dispatch('navigation/popSettingsRoute');
        }
      } else {
        // If not navigating back, push the "to" route onto the stack
        store.dispatch('navigation/pushSettingsRoute', to);
      }
    }
  } else {
    // If we weren't on settings, but now are, push the "to" route onto the stack
    if (to.path.startsWith('/settings') || to.path === '/menu') {
      store.dispatch('navigation/pushSettingsRoute', to);
    }
    // If not navigating from a settings page, reset the navigatedFromSettings flag
    store.dispatch('navigation/updateNavigatedFromSettings', false);

    // Update the latest non-settings route for navigation purposes
    if (!from.path.startsWith('/settings') && from.path !== '/menu' && from.name !== 'login' && from.name !== 'signup') { // We need to include menu as a settings page. Otherwise, navigating from menu to a settings page will break the menu back button. We need to include login and signup routes to prevent infinite back loop when redirected from signup/login
      store.dispatch('navigation/updateLatestNonSettingsRoute', from);
    }
  }

  if(!to.path.startsWith('/settings')) {
    // If not navigating to a settings page, clear the settings route stack
    store.dispatch('navigation/clearSettingsRouteStack');
  }

  next();
});


function isLoggedIn() {
	return store.getters['user/isLoggedIn'];
}
function isGuest() {
  return store.getters['user/isGuest'];
}

export default router
