import axios from 'axios';
import router from '../router';
import store from '../store/index.js'

// Get the token from localStorage
const token = localStorage.getItem('token')
const apiClient = axios.create({
	baseURL: import.meta.env.VITE_API_URL || 'http://localhost:8000',
	headers: {
		...(token && { 'Authorization': `Bearer ${token}` })
	},
});

apiClient.interceptors.request.use(
	(config) => {
		let stringRequest = JSON.stringify(config, null, 2);
		// console.log('Request:', stringRequest);
		const token = localStorage.getItem('token');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

apiClient.interceptors.request.use(request => {
	// Ensure the bearer token is included, if user logged in.
	const token = localStorage.getItem('token');
	if (token) {
		request.headers.Authorization = `Bearer ${token}`;
	}
	// console.log('Starting Request', JSON.stringify(request, null, 2))
	return request;
})

apiClient.interceptors.response.use(
	response => {
		let stringResponse = JSON.stringify(response, null, 2);
		// console.log('Response:', stringResponse);
		return response;
	},
	error => {
		// Check if error was due to request being canceled
		// In this case, we don't want to handle specific response types
		if (axios.isCancel(error)) {
			return Promise.reject(error);
		}

		if (error.response) {
			switch (error.response.status) {
				
				case 401:
					// Logout user
					store.dispatch('user/wipeLoginData');
					// Navigate to the login route
					router.push('/login');

					return Promise.reject("User not authorized");
				case 429: // Too many requests - user reached token or daily limit
					if (error.response.data.type == 'usage_limit' || error.response.data.type == 'token_limit') { // user reached token or daily limit
						store.dispatch('error/addError', {
							type: error.response.data.type,
							message: error.response.data.message || error.response.data.error,
							status: error.response.status
						});
						return Promise.reject("User reached daily limit");
					}
					break;
				case 503: // Service unavailable - assume try again later
					store.dispatch('error/addError', {
						type: error.response.data.type,
						message: error.response.data.message || error.response.data.error,
						status: error.response.status
					});
					break;
				default:
					return Promise.reject(error);
				// Other error codes?
			}
		}
		return Promise.reject(error);
	}
);

export default apiClient;
