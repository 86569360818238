<template>
  <div class="outer-wrapper">
    <header>
      <TopNav
        :settingsRoute="{ name: 'settings', params: { section: 'logout' } }"
      />
    </header>
    <main class="inner-wrapper">
      <h1>Which language are you learning?</h1>
      <p class="text spacer">Don't worry, you can change this later and swap around as much as you like!</p>
      <!-- Select a language -->
      <!-- <label>Language: </label> -->
      <!-- <CustomDropdown
        class="dropdown"
        v-if="supportedLanguages"
        :options="supportedLanguages.map((language) => ({ text: language.fullNameEnglish, value: language.code }))"
        :value="selectedLanguage"
        @input="selectLanguage"
        :isOpen="isOpen"
        @toggle-dropdown="isOpen = !isOpen"
        placeholder="Select a language"
      /> -->
      <div class="languages">
        <div v-for="language in supportedLanguages" :key="language.code" class="language-option" :class="selectedLanguage == language.code ? 'selected' : ''">
          <input type="radio" name="language-options" :id="language.code" :value="language.code" v-model="selectedLanguage" class="hidden-radio"/>
          <label :for="language.code">
            <img :src="`/flags/${language.code}-flag.webp`" :alt="language.fullNameEnglish + ' Flag'" class="language-flag"/>
            <span class="name">{{ language.fullNameEnglish }}</span>
            <!-- <span class="text small">{{ language.fullNameOriginal }}</span> -->
          </label>
        </div>
      </div>

      <!-- Add an error message element -->
      <div v-if="errorMessage" class="error-message text small">{{ errorMessage }}</div>
      <button v-if="!loading" class="save-button" type="submit" @click="save" :disabled="!selectedLanguage">Continue</button>
      <button v-else class="save-button" type="submit" disabled>Loading...</button>
    </main>
    <footer>
    </footer>
  </div>
</template>

<script>
import BottomNav from '@/components/BottomNavigation.vue';
import TopNav from '@/components/TopNavigation.vue';
import { mapState, mapActions } from 'vuex';
import LanguageDropdown from '@/components/LanguageDropdown.vue';
import CustomDropdown from '@/components/CustomDropdown.vue';

export default {
  name: 'AccountSetup',
  components: {
    BottomNav,
    TopNav,
    LanguageDropdown,
    CustomDropdown,
  },
  data() {
    return {
      selectedLanguage: null,
      isOpen: false,

      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapState('settings', ['supportedLanguages']),
  },
  methods: {
    ...mapActions('settings', ['setSelectedLanguage']),
    selectLanguage(item) {
      this.selectedLanguage = item;
      this.isOpen = false;
    },
    save() {
      this.loading = true;
      this.errorMessage = "";
      if (this.selectedLanguage) {
        this.setSelectedLanguage(this.selectedLanguage)
          .then((response) => {
            this.$router.push({name: 'home'});
            this.loading = false;
          })
          .catch((error) => {
           console.error(error);
            // Set the error message based on the error response
            if (error.response && error.response.status === 422) {
              this.errorMessage = error.response.data.message ? error.response.data.message : "Something went wrong. Please try again later.";
            } else {
              this.errorMessage = "An error occurred. Please try again later.";
            }
            this.loading = false;
          })
      } else {
        this.errorMessage = "Select a language before saving.";
        this.loading = false;
      }
    },
  },
}
</script>

<style scoped>
.save-button {
  margin-top: 10px;
  display: block;
}
.error-message {
  margin-top: 10px;
}
.dropdown {
  color: var(--accent-color);
}

.languages {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.language-option {
  width: 150px;
  max-width: calc(50% - 10px);
  display: inline-block;
  position: relative;
  box-sizing: border-box; /* ensure padding and border are included in the box's total dimensions */
  border: 2px solid #ccc;
  border-radius: 15px;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.language-option.selected {
  border: 3px solid green;
  margin-bottom: 0;
}

.language-option .hidden-radio {
  display: none;
}

.language-option label {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px; /* adds consistent spacing around flag and text */
  padding-bottom: 2px;
  cursor: pointer;
}

.language-option .language-flag {
  width: 80%;
  margin: 0 auto; /* centers the flag */
  display: block;
}

.language-option .name {
  display: block;
  overflow: hidden; /* hides overflow text */
  text-overflow: ellipsis; /* adds ... if the text overflows */
  white-space: nowrap; /* prevents wrapping */
  max-width: 100%; /* ensures the text doesn't exceed the box */
  margin-top: 5px;
}
.language-option .text {
  margin-top: -5px;
}

.language-option input[type="radio"]:checked + label {
  font-weight: bold;
  color: green;
}

</style>
