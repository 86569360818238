<template>
  <div :class="isSelectable ? 'selectable' : ''">
    <span v-if="isSelectable" class="indicator">
      Select to translate
    </span>
    <div v-if="processedChunks" class="content" :style="fontSize ? 'font-size: ' + fontSize + ';' : ''">
      <span v-for="chunk in processedChunks" :key="chunk.id" class="chunk" :data-offset="chunk.order" :ref="`offset-${chunk.order}`" >
        <span v-for="(sentence, sentenceIndex) in chunk.sentences" :key="'sentence-' + sentenceIndex" class="sentence" :class="isPlayingSentence(sentence.sentence, languageCode) ? 'playing' : ''">
          <span
            v-for="(word, wordIndex) in sentence.words"
            :key="'word-' + wordIndex"
          >
            <span v-if="wordIndex == 0" class="inline-container">
              <span v-if="isNotBlank(sentence.sentence) && languageCode" class="voice-icon" @click="setPlayingIndex(chunk.id, sentenceIndex); playAudio();">
                <img :src="getVoiceButtonImageSrc(sentence.sentence, languageCode)" alt="Voice button">
              </span>
              <span
                @click="!isPunctuationOrSpace(word) ? selectWord(chunk.id, sentenceIndex, wordIndex) : null"
                :class="{
                  'highlight-current': isCurrentHighlight(chunk.id, sentenceIndex, wordIndex),
                  'highlight-previous': isPreviousHighlight(chunk.id, sentenceIndex, wordIndex),
                  'word': !isPunctuationOrSpace(word),
                  'highlight-word': doesPhraseMatch(chunk.id, sentenceIndex, wordIndex)
                }"
              >{{ word }}</span>
            </span>
            <span v-else>
              <span
                @click="!isPunctuationOrSpace(word) ? selectWord(chunk.id, sentenceIndex, wordIndex) : null"
                :class="{
                  'highlight-current': isCurrentHighlight(chunk.id, sentenceIndex, wordIndex),
                  'highlight-previous': isPreviousHighlight(chunk.id, sentenceIndex, wordIndex),
                  'word': !isPunctuationOrSpace(word),
                  'highlight-word': doesPhraseMatch(chunk.id, sentenceIndex, wordIndex)
                }"
              >{{ word }}</span>
            </span>
          </span>
        </span>
      </span>
    </div>

    <!-- <pre>{{ processedChunks }}</pre> -->
  </div>
</template>

<script>
import VoiceButton from '@/components/VoiceButton.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    VoiceButton,
  },
  props: {
    content: {
      type: String, // basic string to output
      required: false,
      default: null,
    },
    chunks: { // array of content chunks to output. If this is provided, content is ignored. Each object in the array contains a 'content' property and 'id' property.
      type: Array,
      default: null,
    },
    languageCode: {
      type: String,
      default: null,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    loadedPreviousHighlights: {
      type: Array,
      default: () => [],
    },
    highlightPhrase: {
      type: String,
      default: null,
    },
    fontSize: {
      type: String,
      default: '1em',
    },
    textID: {
      type: Number,
      required: false,
      default: null,
    },
    autoPlayVoice: { // This can turn off the voice state autoplay. Both are required to be true.
      type: Boolean,
      required: false,
      default: false,
    }
  },
  created () {
      // Map content/chunks to sentences and words array of objects
      this.mapChunksData();
  },
  data() {
    return {
      currentHighlight: { chunkId: -1, sentenceIndex: -1, wordIndex: -1 },
      previousHighlights: [],

      // Audio
      playingChunkId: null,
      playingSentenceIndex: -1,


      uniqueId: Math.floor(Math.random() * 10000), // A random number for this component, used in the voiceButton ref so that we can ensure we only auto play audio that is in the same component, not another instance of it.

      processedChunks: [],
    };
  },
  computed: {
    ...mapState({
      audioEndedNaturallyNotification: state => state.voice.audioEndedNaturallyNotification,
      shouldAutoPlay: state => state.voice.shouldAutoPlay, // If the autoPlayVoice prop is true, then we should auto play if the voice state is also true.
      autoPlayComponentID: state => state.voice.autoPlayComponentID,
    }),
    ...mapState({
      voice: 'voice',
      lookup: 'lookup'
    }),
    isPlayingSentence() {
      return (sentence, languageCode) => {
          const id = `${sentence}_${languageCode}`;
          const buttonState = this.voice.buttons[id];
          if (buttonState) {
              return buttonState.isPlaying || buttonState.isLoading;
          }
          return false;
      };
    },
  },
  methods: {
    ...mapActions('voice', ['speech', 'stop', 'setShouldAutoPlay', 'setAutoPlayComponentID']),
    ...mapActions('lookup', ['lookupWord']),
    ...mapActions('tutorial', ['dismissTutorial']),
    isNotBlank(sentence) {
      return sentence.trim() !== '';
    },
    mapChunksData() {
      if(this.chunks) {
        this.processedChunks = this.chunks;
      } else if(this.content) {
        // Create chunks array from content string
        this.processedChunks = [{ content: this.content }];
      } else {
        return; // Content or chunks not ready yet.
      }

      for(let i = 0; i < this.processedChunks.length; i++) {
        // Clone and log
        let content = this.processedChunks[i].content;
        // Remove any whitespace at the beginning of the content
        content = content.trimStart();

        const cleanText = content.replace(/<\/?p>/g, '');

        let sentences = [];

        // Split the text into paragraphs array on newline characters - but keep the \n character
        let paragraphs = cleanText.split('\n');
        for (let i = 0; i < paragraphs.length - 1; i++) {
          paragraphs[i] = paragraphs[i].concat('\n');
        }

        // For each paragraph, split it into sentences and push them into the sentences array.
        paragraphs.forEach((paragraph, index) => {
          let paragraphSentences;
          // Split the paragraph into sentences on punctuation characters, but keep the punctuation characters.
          try {
            paragraphSentences = paragraph.split(/(?<=\.|\!|\?)(?=\s)/);
          } catch(error) {
            paragraphSentences = paragraph.replace(/(\.|\!|\?)\s/g, '$1|').split('|');
          }
          // Only add the sentences if they are not empty.
          paragraphSentences = paragraphSentences.filter(sentence => sentence !== '');
          for(let i = 0; i < paragraphSentences.length; i++) {
            let sentence = paragraphSentences[i];
            let words;

            try {
              // Try using the newer regex syntax
              words = sentence.match(/((?:\p{L}|\d|[-'])+|[\p{P}\s])/gu);
            } catch (error) {
              // If it fails, fall back to the older syntax - needed for iPhone safari. Doesn't catch all letters from all languages...
              words = sentence.match(/((?:[a-zA-Z0-9']|-)+|[^a-zA-Z0-9\s])/g);
            }
            sentences.push({
              words: words,
              sentence: sentence
            });
          }
        });

        this.processedChunks[i].sentences = sentences;
      }

      // Load the previous highlights into the current component
      this.loadedPreviousHighlights.forEach(highlight => {
        const word = highlight.word;
        const sentenceToFind = highlight.sentence.trim(); // Trim the sentence to find
        this.processedChunks.forEach((chunk) => {
          chunk.sentences.forEach((sentence, sentenceIndex) => {
            // Join the sentence array back into a string for comparison
            const sentenceAsString = sentence.sentence.trim(); // Trim the sentence as string
            if (sentenceAsString === sentenceToFind) {
              const wordIndex = sentence.words.indexOf(word);
              if (wordIndex !== -1) {
                this.previousHighlights.push({ chunkId: chunk.id, sentenceIndex, wordIndex });
              }
            }
          });
        });
      });

    },
    selectWord(chunkId, sentenceIndex, wordIndex) {
      if(!this.isSelectable) return;

      this.dismissTutorial({ viewName: 'ViewText', tutorialId: 'lookup' }); // Dismiss the tutorial if it happens to be there.

      if (this.currentHighlight.sentenceIndex !== -1) {
        this.previousHighlights.push({
          chunkId: this.currentHighlight.chunkId,
          sentenceIndex: this.currentHighlight.sentenceIndex,
          wordIndex: this.currentHighlight.wordIndex
        });
      }

      this.currentHighlight.chunkId = chunkId;
      this.currentHighlight.sentenceIndex = sentenceIndex;
      this.currentHighlight.wordIndex = wordIndex;

      let chunk = this.processedChunks.find(chunk => chunk.id === chunkId);
      const selectedWord = chunk.sentences[sentenceIndex].words[wordIndex];
      const fullSentence = chunk.sentences[sentenceIndex].sentence;

      // Pause the audio
      this.stopAudio();

      let lookupParams = { selectedWord, selectedSentence: fullSentence };
      // Optional parameters
      if(chunkId) lookupParams.textChunkID = chunkId;
      if(this.textID) lookupParams.textID = this.textID;
      
      this.lookupWord(lookupParams);
    },
    isCurrentHighlight(chunkId, sentenceIndex, wordIndex) {
      return (
        this.currentHighlight.chunkId === chunkId &&
        this.currentHighlight.sentenceIndex === sentenceIndex &&
        this.currentHighlight.wordIndex === wordIndex
      );
    },
    isPreviousHighlight(chunkId, sentenceIndex, wordIndex) {
      return this.previousHighlights.some(
        highlight => highlight.chunkId === chunkId && highlight.sentenceIndex === sentenceIndex && highlight.wordIndex === wordIndex
      );
    },
    isPunctuationOrSpace(word) {
      let punctuationOrSpaceRegex;
      try {
        punctuationOrSpaceRegex = /^[\p{P}\s]+$/u;
        return punctuationOrSpaceRegex.test(word);
      } catch (error) {
        punctuationOrSpaceRegex = /^[^\w\s]+$/;
        return punctuationOrSpaceRegex.test(word);
      }
    },
    doesPhraseMatch(chunkId, sentenceIndex, wordIndex) {
      if(!this.highlightPhrase) return false;

      let chunk = this.processedChunks.find(chunk => chunk.id === chunkId);

      const words = chunk.sentences[sentenceIndex].words;

      // The number of words in the phrase to highlight
      const numWordsInHighlight = this.highlightPhrase.split(' ').length;

      // The words in the sentence from wordIndex to (wordIndex + numWordsInHighlight)
      const wordsInSentence = words.slice(wordIndex, wordIndex + numWordsInHighlight);

      // The phrase from the sentence
      const sentencePhrase = wordsInSentence.join(' ');

      // Return whether the sentencePhrase matches the highlightPhrase
      return sentencePhrase.toLowerCase() === this.highlightPhrase.toLowerCase();
    },

    // Audio
    stopAudio() {
      // If there is not playingChunkId or playingSentenceIndex, set them to the first sentence in the first chunk
      if(this.playingChunkId === null || this.playingSentenceIndex === -1) {
        this.playingChunkId = this.processedChunks[0].id;
        this.playingSentenceIndex = 0;
      }

      // Get the sentence from the chunk and play it
      let sentence = this.processedChunks.find(chunk => chunk.id === this.playingChunkId).sentences[this.playingSentenceIndex].sentence;
      let languageCode = this.languageCode;

      this.stop(`${sentence}_${languageCode}`);
    },
    playAudio() {
      // If there is not playingChunkId or playingSentenceIndex, set them to the first sentence in the first chunk
      if(this.playingChunkId === null || this.playingSentenceIndex === -1) {
        this.playingChunkId = this.processedChunks[0].id;
        this.playingSentenceIndex = 0;
      }

      // Get the sentence from the chunk and play it
      let sentence = this.processedChunks.find(chunk => chunk.id === this.playingChunkId).sentences[this.playingSentenceIndex].sentence;
      let languageCode = this.languageCode;

      const appSettings = JSON.parse(localStorage.getItem('appSettings') ?? '{}');
      const autoPlay = appSettings.voice_autocontinue ? appSettings.voice_autocontinue : false;
      let shouldAutoPlay = autoPlay && this.autoPlayVoice;
      this.setShouldAutoPlay(shouldAutoPlay); // Check both the user setting and the prop to see if we should auto play.

      this.setAutoPlayComponentID(this.uniqueId);
      this.speech({ id: `${sentence}_${languageCode}`, text: sentence, languageCode: languageCode });
    },
    getVoiceButtonImageSrc(sentence, languageCode) {
      const id = `${sentence}_${languageCode}`;
      const buttonState = this.voice.buttons[id];
      if (buttonState) {
        if (buttonState.isLoading) {
          return '/sound/loading.png';
        } else if (buttonState.isPlaying) {
          return '/sound/stop.png';
        } else if (!buttonState.isPlaying) {
          return '/sound/play.png';
        }
      }
      return '/sound/play.png';
    },
    setPlayingIndex(chunkId, sentenceIndex) {
      this.playingChunkId = chunkId;
      this.playingSentenceIndex = sentenceIndex;
    },
    incrementIndices() {
      // Find the current chunk
      let currentChunkIndex = this.processedChunks.findIndex(chunk => chunk.id === this.playingChunkId);

      if (currentChunkIndex === -1) return;

      // Increment sentence index until we find a sentence that is not null or '\n' or reach the end of the sentences in the current chunk
      while ((this.processedChunks[currentChunkIndex].sentences[++this.playingSentenceIndex] == null || this.processedChunks[currentChunkIndex].sentences[this.playingSentenceIndex].sentence == '\n') && this.playingSentenceIndex < this.processedChunks[currentChunkIndex].sentences.length);

      // If we're at the end of the sentences in the current chunk
      if (this.playingSentenceIndex >= this.processedChunks[currentChunkIndex].sentences.length) {
        // Check if next chunk exists
        if (currentChunkIndex < this.processedChunks.length - 1) {
          // Move to the next chunk
          currentChunkIndex++;
          this.playingChunkId = this.processedChunks[currentChunkIndex].id;
          this.playingSentenceIndex = -1;

          // Increment sentence index until we find a sentence that is not null or '\n' or reach the end of the sentences in the new chunk
          while ((this.processedChunks[currentChunkIndex].sentences[++this.playingSentenceIndex] == null || this.processedChunks[currentChunkIndex].sentences[this.playingSentenceIndex].sentence == '\n') && this.playingSentenceIndex < this.processedChunks[currentChunkIndex].sentences.length);
        }

         // If we're still at the end of the sentences in the new chunk (i.e., no more sentences), stop
         if (this.playingSentenceIndex >= this.processedChunks[currentChunkIndex].sentences.length) {
          return;
        }
      }
    },

    decrementIndices() {
      if (this.autoPlayComponentID !== this.uniqueId) return;

      // Find the current chunk
      let currentChunkIndex = this.processedChunks.findIndex(chunk => chunk.id === this.playingChunkId);

      if (currentChunkIndex === -1) return;
      
      // Decrement sentence index until we find a sentence that is not null or '\n' or reach the beginning of the sentences in the current chunk
      while ((this.processedChunks[currentChunkIndex].sentences[--this.playingSentenceIndex] == null || this.processedChunks[currentChunkIndex].sentences[this.playingSentenceIndex].sentence == '\n') && this.playingSentenceIndex >= 0);

      // If we're at the beginning of the sentences in the current chunk
      if (this.playingSentenceIndex < 0) {
        // Check if previous chunk exists
        if (currentChunkIndex > 0) {
          // Move to the previous chunk
          currentChunkIndex--;
          this.playingChunkId = this.processedChunks[currentChunkIndex].id;
          // Set the sentence index to the last sentence in the previous chunk
          this.playingSentenceIndex = this.processedChunks[currentChunkIndex].sentences.length;

          // Decrement sentence index until we find a sentence that is not null or '\n' or reach the beginning of the sentences in the new chunk
          while ((this.processedChunks[currentChunkIndex].sentences[--this.playingSentenceIndex] == null || this.processedChunks[currentChunkIndex].sentences[this.playingSentenceIndex].sentence == '\n') && this.playingSentenceIndex >= 0);
        }

        // If we're still at the beginning of the sentences in the new chunk (i.e., no more sentences), stop
        if (this.playingSentenceIndex < 0) {
          return;
        }
      }
    },

    playNextAudio() {
      if(this.autoPlayComponentID !== this.uniqueId) return;
      this.incrementIndices();
      const currentChunk = this.processedChunks.find(chunk => chunk.id === this.playingChunkId);
      const nextSentence = currentChunk.sentences[this.playingSentenceIndex].sentence;
      this.playAudio(nextSentence, this.languageCode);
    },

    playPreviousAudio() {
      if (this.autoPlayComponentID !== this.uniqueId) return;
      this.decrementIndices();
      const currentChunk = this.processedChunks.find(chunk => chunk.id === this.playingChunkId);
      const previousSentence = currentChunk.sentences[this.playingSentenceIndex].sentence;
      this.playAudio(previousSentence, this.languageCode);
    },

  },

  watch: {
    // Watch the current playing audio button and fire the next audio if it is changed to null (no longer playing audio)
    audioEndedNaturallyNotification(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.incrementIndices();
        if(this.shouldAutoPlay && this.autoPlayComponentID === this.uniqueId && this.content === null) {
          const currentChunk = this.processedChunks.find(chunk => chunk.id === this.playingChunkId);
          if(this.playingSentenceIndex >= currentChunk.sentences.length) {
            return;
          }
          const nextSentence = currentChunk.sentences[this.playingSentenceIndex].sentence;
          this.playAudio(nextSentence, this.languageCode);
        }
      }
    },
    chunks: {
      handler(newValue, oldValue) {
        this.mapChunksData();
      },
      immediate: true, // this will cause the handler to run immediately after registration
    },
    content: {
      handler(newValue, oldValue) {
        this.mapChunksData();
      },
      immediate: true, // this will cause the handler to run immediately after registration
    }
},
};
</script>

<style>
.selectable {
  position: relative;
  padding: 5px 0;
  margin-top: 20px;
}
.selectable .indicator {
  font-size: 0.8em;
  color: #757575;
  background-color: #e5e5e5;
  position: absolute;
  left: 10px;
  top: -13px;
  line-height: 13px;
  font-style: normal;
}
.sentence {
  /* line-height: 30px; */
  max-width: 100%;
  /* word-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto; */
  cursor: default;
}
.sentence.playing {
  color: var(--accent-color-dark);
}
.word {
  border-radius: 8px;
  padding: 0px 2px;
  cursor: pointer;
}
.highlight-current {
  background-color: var(--accent-color);
  color: white;
}
.highlight-previous {
  background-color: var(--accent-color-light);
  color: white;
}
.content {
  /* font-size: 1.5rem; */
  white-space: pre-line;
}
.content .voice-icon {
  margin-left: 5px;
  margin-right: 5px;
}
.highlight-word {
  background-color: var(--accent-color-light);
  color: white;
}

.voice-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.voice-icon img {
  height: 20px;
  display: block;
  margin: 0 auto;
}
</style>
