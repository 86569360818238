<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-if="showModal">
      <div class="modal-content" @click.stop>
        <slot></slot>
        <button class="close-button" @click="closeModal">Close</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    $route() {
      // If the route changes, close the modal. Probably this is a link clicked in the content of the modal.
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2500;
  width: 100%; /* Use viewport width */
  height: 100%; /* Use viewport height */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 500px;
  background-color: white;
  padding: 20px;
  padding-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  max-height: 95%;
  overflow: auto;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 1rem;
  padding: 0;
}
</style>
