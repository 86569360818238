<template>
  <div class="non-fixed-content">
    <div v-if="initialLoading && isLoggedIn">
      <div class="loading-splash">
        <div class="centered">
          <img class="logo" src="/src/assets/logo.png" alt="Logo" />
          <span class="brand-name">Polyglot Pal</span>
          <p>Loading...</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="banner red" v-if="!online">
        <div class="outer-wrapper">
          Poor internet connection, content may be out of date. Please check
          your network settings!
        </div>
      </div>

      <div
        class="banner"
        v-if="isGuest && $route.name !== 'signup' && $route.name !== 'login'"
      >
        <div class="outer-wrapper">
          <strong>Guest mode.</strong> Progress will not be saved long term.
          <div class="signup">
            <RouterLink class="button small good" :to="{ name: 'signup' }"
              >Sign up</RouterLink
            >
            to save your progress.
          </div>
        </div>
      </div>

      <div
        v-if="
          isLoggedIn &&
          selectedLanguage === null &&
          $route.name != 'signup' &&
          $route.name != 'login' &&
          $route.name != 'settings' &&
          $route.name != 'login' &&
          $route.name != 'terms-conditions' &&
          $route.name != 'privacy-policy' &&
          $route.name != 'forgot-password'
        "
      >
        <AccountSetup />
      </div>
      <div v-else>
        <RouterView :key="$route.fullPath" />
      </div>
    </div>

    <Lookup />

    <div>
      <PopupModal
        v-for="error in errors"
        :key="error.message"
        :showModal="true"
        @close="closeModal(error)"
      >
        <div v-if="error.status === 429">
          <!-- Content for 429 errors -->
          <div v-if="error.type == 'usage_limit'">
            <div class="heading-container">
              <h2>Subscribe today</h2>
            </div>
            <p class="spacer">
              You have reached your daily limit! You can come back tomorrow to
              continue...
            </p>
            <p class="spacer">
              Or for only <strong>£{{ plan.amount }} per month</strong> you can
              generate more stories, lookup as many words as needed and talk to
              the AI as much as you'd like!
            </p>
            <RouterLink
              class="button good"
              :to="{
                name: 'settings',
                params: { section: 'manage-subscription' },
              }"
              >Subscribe Now</RouterLink
            >
          </div>
          <div v-else-if="error.type == 'token_limit'">
            <div class="heading-container">
              <h2>Too many requests</h2>
            </div>
            <p>
              Dear user, we're sorry to inform you that you have reached your
              daily limit for accessing certain features of our service. This
              limit is set to ensure fair usage and to prevent potential abuse
              of our system. In regular use, you shouldn't encounter this
              limitation. If you believe you have received this message in
              error, or if your usage needs exceed our current limit, please
              don't hesitate to reach out to our support team at
              <a href="mailto:support@polyglotpal.com"
                >support@polyglotpal.com</a
              >
            </p>
          </div>
        </div>
        <div v-else>
          <!-- Content for other errors -->
          <h2>Error</h2>
          <p>{{ error.message }}</p>
        </div>
      </PopupModal>
    </div>
    <PopupModal
      v-if="deferredPrompt && showInstallPrompt && isLoggedIn"
      :showModal="true"
      @close="
        $store.dispatch('settings/setShowInstallPrompt', false);
        $store.dispatch('settings/setInstallPromptFlag', 1);
      "
    >
      <h3>Install App?</h3>
      <div class="buttons">
        <button
          class="button good"
          @click="$store.dispatch('settings/promptInstall')"
        >
          Install
        </button>
        <button
          class="button neutral"
          @click="
            $store.dispatch('settings/setShowInstallPrompt', false);
            $store.dispatch('settings/setInstallPromptFlag', 1);
          "
        >
          Maybe Later
        </button>
        <button
          class="button bad"
          @click="
            $store.dispatch('settings/setShowInstallPrompt', false);
            $store.dispatch('settings/setInstallPromptFlag', 7);
          "
        >
          No Thanks
        </button>
      </div>
    </PopupModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import PopupModal from "@/components/PopupModal.vue";
import apiClient from "@/helpers/api.js";
import Lookup from "@/components/Lookup.vue";
import AccountSetup from "./views/auth/AccountSetup.vue";

export default {
  components: {
    PopupModal,
    Lookup,
    AccountSetup,
  },
  data() {
    return {
      plan: null,
      online: navigator.onLine,
    };
  },
  computed: {
    ...mapState("settings", ["selectedLanguage"]),
    ...mapGetters({
      errors: "error/errors",
      settingsLoading: "settings/loading",
      initialLoading: "settings/initialLoading",
      user: "user/user",
      isGuest: "user/isGuest",
      guestID: "user/getGuestID",
    }),
    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    },
    showInstallPrompt() {
      return this.$store.getters["settings/showInstallPrompt"];
    },
    deferredPrompt() {
      return this.$store.getters["settings/deferredPrompt"];
    },
  },
  methods: {
    ...mapActions({
      removeError: "error/removeError",
      initSettings: "settings/init",
      resetState: "settings/resetState",
    }),
    ...mapActions("texts", ["fetchTexts"]),
    ...mapActions("flashcards", ["fetchFlashcards"]),
    async getPlanDetails() {
      const response = await apiClient.get("/api/subscriptions/plan-details");
      this.plan = response.data;
    },
    async reloadUserData() {
      // Called on load and when the user logs in/out
      // Reset the initial load variable in settings
      await this.initSettings();
    },
    closeModal(error) {
      this.removeError(error);
    },
    updateOnlineStatus() {
      this.online = navigator.onLine;
    },
  },
  watch: {
    isLoggedIn(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === true) {
          this.reloadUserData();
        }
      }
    },
    selectedLanguage(newVal, oldVal) {
      if (newVal !== oldVal && oldVal != null) {
        // Don't fire on the first set of selectedLanguage, as the views will do this themselves on first load
        // Execute both functions at the same time and await both promises
        Promise.all([
          this.fetchTexts({ language: this.selectedLanguage }),
          this.fetchFlashcards(),
        ]);
      }
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.reloadUserData();
      this.getPlanDetails(); // This is only used on the popup for usage and so doesn't need loading indicator etc, and don't need to wait for it to finish. Popup shouldn't happen so quickly after load that it's an issue.
    }
  },
  mounted() {
    // Listen for online/offline changes
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);

    // Capture the event and show install button
    window.addEventListener("beforeinstallprompt", (e) => {
      this.$store.dispatch("settings/handleBeforeInstallPrompt", e);
    });
  },
  beforeUnmount() {
    // Cleanup
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style>
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("fonts/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("fonts/Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("fonts/Poppins-ExtraBold.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("fonts/Poppins-Black.ttf") format("ttf");
}

:root {
  --primary-color-light: #f62930;
  --primary-color: #e1272d;
  --primary-color-dark: #bf191e;
  --secondary-color: #f69332;
  --secondary-color-dark: #d9822c;
  --accent-color-light: #17c1c6;
  --accent-color: #13a6aa;
  --accent-color-dark: #12969b;

  --action-good: #45a049;
  --action-good-dark: #3c8b40;
  --action-neutral: #e7e7e7;
  --action-bad: #e53935;

  --text-color: #1c1c2e;
  --text-color-dark: #000000;
  --background-color: #e9e3da;
  --grey-color-light: #f8f8f8;
  --grey-color: #e7e7e7;
  --grey-color-dark: #989898;
  --white-color: #ffffff;

  --outer-wrapper-width: 645px;
  --top-bar-height: 50px;
  --bottom-bar-height: 50px;
}
* {
  box-sizing: border-box;
}
body {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: var(--text-color);
  background: var(--white-color);
  position: relative;
}
body {
  margin: 0;
  padding: 0;
}
.title {
  font-size: 2em;
}
.heading-container {
  margin-bottom: 10px;
  padding-top: 10px;
}

h1,
.heading1 {
  font-size: 2em;
  font-weight: bold;
}
h2,
.heading2 {
  font-size: 1.5em;
  font-weight: semi-bold;
}
h3,
.heading3 {
  font-size: 1.17em;
  font-weight: medium;
}
h4,
.heading4 {
  font-size: 1em;
}
.buttons {
  display: flex;
  justify-content: left;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}
body button,
body [type="button"],
body [type="reset"],
body [type="submit"],
body [role="button"],
body .button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: inline-block;
  font-size: 1em;
  border-radius: 50px;
  background-color: var(--accent-color);
  padding: 5px 13px;
  color: var(--white-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
body button:hover,
body .button:hover,
body a:hover button,
body a:hover .button {
  text-decoration: none;
  background-color: var(--accent-color-dark);
  color: var(--white-color);
}
body .button.good,
body button.good {
  background-color: var(--action-good);
}
body .button.good:hover,
body button.good:hover {
  background-color: var(--action-good-dark);
}
body .button.bad,
body button.bad {
  background-color: var(--action-bad);
}
body button:disabled {
  background-color: var(--grey-color-dark);
}
body button.small {
  font-size: 0.8em;
  padding: 3px 8px;
}
body button.blank {
  background: none;
  color: var(--primary-color);
  padding: 0;
}
body button.blank:hover {
  background: none;
  color: var(--primary-color-dark);
}
.icon {
  height: 20px;
}
.button .icon,
button .icon {
  margin-right: 5px;
  vertical-align: middle;
}
a {
  color: var(--primary-color);
  text-decoration: none;
}
a.button:visited {
  color: var(--white-color);
}
a:hover {
  text-decoration: underline;
  color: var(--primary-color-dark);
}
a:visited {
  color: var(--primary-color-dark);
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin-bottom: 10px;
  padding-left: 10px;
}
.text {
  font-size: 1em;
}
.text.small {
  font-size: 0.9em;
}

.loader {
  display: inline-block;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  margin-right: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input-group {
  margin-bottom: 10px;
  align-items: center;
  max-width: 100%;
}
label {
  display: block;
  width: 100%;
  font-size: 1em;
  color: var(--text-color);
  font-weight: 500;
  margin-right: 5px;
}
body input,
body textarea {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  box-shadow: none;
  font: inherit;
  color: inherit;

  border: 1px solid var(--grey-color);
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
}
.error-message {
  color: var(--action-bad);
  margin-bottom: 10px;
}
.success-message {
  color: var(--action-good);
  margin-bottom: 10px;
}

.non-fixed-content {
  margin-top: var(--top-bar-height);
  /* padding-top: 15px; */
  margin-bottom: var(--bottom-bar-height);
  /* padding-bottom: 15px; */
}
.outer-wrapper {
  margin: 0 auto;
  max-width: var(--outer-wrapper-width);
}
.inner-wrapper {
  padding: 0 15px;
}
.clear {
  clear: both;
}
.inline {
  display: inline-block;
}
.spacer {
  margin-bottom: 15px;
}
.spacer.small {
  margin-bottom: 10px;
}
.spacer.large {
  margin-bottom: 20px;
}
.sub-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.carousel .carousel__next,
.carousel .carousel__prev {
  width: 30px;
  height: 30px;
  background-color: var(--accent-color) !important;
  color: white !important;
  padding: 0 !important;
}

/* Reused on multiple components */
body .login-button {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

/* Banner */
.banner {
  background-color: var(--secondary-color);
  color: var(--white-color);
  padding: 10px;
  text-align: center;
  font-size: 0.8em;
  font-weight: 500;
  /* margin-bottom: 10px; */
}
.banner.red {
  background-color: var(--primary-color);
}
.banner .outer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  text-align: left;
}
.banner .signup {
  margin-left: auto;
}

/* Loading splash */
.loading-splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: var(--background-color);
}
.loading-splash .centered {
  position: absolute;
  max-width: 90%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
}
.loading-splash .centered img {
  max-width: 100%;
}
.loading-splash .centered .brand-name {
  display: inline-block;
  width: 100%;
  font-size: 2em;
  font-weight: bold;
}

/* Headings with content and loaders inline */
.items.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.items.heading-container :last-child:not(:only-child) {
  margin-left: auto;
}

/* Flashcards review */
.flashcard-buttons {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: 66px;
}

.flashcard-buttons .loading {
  width: 100%;
  text-align: center;
  height: 42px;
}
.flashcard-buttons .flashcard-button {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #4caf50;
  border: none;
  color: white;
  text-align: center;
  border-radius: 0;
  font-weight: 500;
}
.flashcard-buttons .result {
  width: 100%;
  display: flex;
}
.flashcard-buttons .next-review {
  display: block;
  font-size: 0.6em;
  font-weight: normal;
}
.flashcard-buttons .flashcard-button:hover {
  background-color: #45a049;
}

.flashcard-buttons .flashcard-button.fail {
  background-color: #f44336;
}
.flashcard-buttons .flashcard-button.fail:hover {
  background-color: #e53935;
}

.flashcard-buttons .flashcard-button.next {
  background-color: #4435e5;
}
.flashcard-buttons .flashcard-button.next:hover {
  background-color: #3324da;
}

/* Login global styles */
.login-container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.login-container form {
  text-align: center;
}
.login-container .input-group {
  text-align: left;
  display: inline-block;
}
.login-container .text {
  margin-bottom: 10px;
}
</style>
