// rollbar.config.js
export default {
  accessToken: 'd3d0f293a9514cfbb98900b1be9c10fe',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.VITE_APP_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
      }
    }
  },
};
