<template>
  <v-bottom-sheet v-model="localShowLookup">
    <v-card
      class="text-center"
      id="lookup-container"
      :height="heightValue"
    >
      <v-card-text ref="contentWrapper" class="outer-wrapper">
        <a @click="hideLookup" class="close">
          <img src="/src/assets/navigation/back.png" alt="Down Arrow" /> Close
        </a>

        <div>
          <div class="outer-wrapper">
            <div v-if="translationLoading && !lookup">
              <div class="loader"></div>
              Loading
            </div>
            <div v-else-if="!translation && !translationLoading && loading">
              Lookup loading
            </div>
            <div v-else-if="(lookup || translation) && localShowLookup">
              <DefinitionTranslation
                :translation="translation"
                :sourceLanguage="lookup ? lookup.definition.language : false"
                :definition="lookup ? lookup.definition : false"
                :relatedDefinition="lookup ? lookup.related_definition : false"
                :definitionTranslationID="lookup ? lookup.definition_translation_id : false"
                :displayTutorials="true"
                :sentences="lookup ? [lookup.sentence] : false"
                :notes="lookup ? lookup.notes : false"
                :tips="lookup ? lookup.tips : false"
                @update="updateHeight"
              />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import DefinitionTranslation from '@/components/DefinitionTranslation.vue';
import { VBottomSheet } from 'vuetify/labs/VBottomSheet' // Labs Vuetify component for bottom-sheet

export default {
  props: [
    
  ],
  components: {
    DefinitionTranslation,
    VBottomSheet,
  },
  data() {
    return {
      localShowLookup: false,
      heightValue: "auto",
    };
  },
  computed: {
    ...mapState({
      voice: 'voice'
    }),
    ...mapGetters({
      lookup: 'lookup/lookup',
      translation: 'lookup/quickTranslation',
      lookupLoadingCount: 'lookup/lookupLoadingCount',
      translationsLoadingCount: 'lookup/translationsLoadingCount',
      showLookup: 'lookup/showLookup',
    }),
    loading() {
      return this.lookupLoadingCount > 0;      
    },
    translationLoading() {
      return this.translationsLoadingCount > 0;
    }
    
  },
  methods: {
    ...mapActions('voice', ['speech', 'setShouldAutoPlay', 'setAutoPlayComponentID']),
    ...mapActions('lookup', ['hideLookup']),
    updateHeight() {
      this.$nextTick(() => {
        if (this.$refs.contentWrapper && this.$refs.contentWrapper.$el) {
          const contentHeight = this.$refs.contentWrapper.$el.clientHeight;
          this.heightValue = contentHeight;
        }
      });
   },
  },
  watch: {
    loading(newVal) {
      if (!newVal && this.lookup) { // Check if loading has changed to false and if lookup is defined, and if the modal is showing (in case it was exited before it played)
        this.setShouldAutoPlay(false);
        this.setAutoPlayComponentID(null);
        this.speech( { id: `${this.lookup.definition.word.word}_${this.lookup.definition.language}`, text: this.lookup.definition.word.word, languageCode: this.lookup.definition.language });
      }
    },

    // Watch the content variables and update the height when they change, to trigger transition
    translation(newVal, oldVal) {
      this.updateHeight();
    },
    lookup(newVal, oldVal) {
      this.updateHeight();
    },
    loading(newVal, oldVal) {
      this.updateHeight();
    },
    translationLoading(newVal, oldVal) {
      this.updateHeight();
    },
    showLookup(newVal) {
      if (newVal) {
        this.heightValue = "auto";
      }

      this.localShowLookup = newVal;
    },    
    localShowLookup(newVal) {
      if (!newVal) {
        this.hideLookup();
        this.heightValue = "auto";
      }
    },
    
  },
}
</script>

<style scoped>
#lookup-container {
  position: relative;
}
.v-card {
  transition: height 100ms; /* Adjust timing as needed */
}
.v-card .outer-wrapper {
  padding-bottom: 20px;
  width: 100%;
}
.close {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  color: var(--text-color);
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: none;
  width: 100%;
}
.close img {
  height: 20px;
  transform: rotate(-90deg);
  margin-right: 10px;
}



.loader {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
</style>
