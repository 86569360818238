<template>
	<div>
		<transition name="fade" @after-leave="afterLeave" v-on:before-enter="beforeEnter" v-on:enter="entered"
			v-on:before-leave="beforeLeave" v-on:leave="left">
			<div v-show="show" class="target-overlay" :style="overlayStyle"></div>
		</transition>
		<transition name="scale-fade" v-on:before-enter="beforeEnter" v-on:enter="entered" v-on:before-leave="beforeLeave"
			v-on:leave="left">
			<div v-show="show" class="tooltip" :style="{ top: position.top + 'px', left: position.left + 'px' }">
				<div v-html="message"></div>
				<div class="tooltip-footer">
					<button class="button small blank" @click="hideTutorial">Hide Tutorial</button>
					<button class="button good" @click="dismiss">Next</button>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import helpers from '@/helpers/helpers.js';

export default {
	props: {
		tutorialId: String,
		message: String,
		targetSelector: String,
		tutorialDone: Boolean,
		containerSelector: String,
		delay: { // Optional delay for displaying the tooltip
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			show: false,
			beginTransition: false,
			position: { top: 0, left: 0 },
			overlayStyle: {},
		}
	},
	emits: ['dismissed', 'hide-tutorial'],
	computed: {
	},
	methods: {
		beforeEnter(el) {
			el.style.opacity = '0';
		},
		entered(el) {
			// Delay the transition slightly to ensure the positioning logic has run
			setTimeout(() => {
				el.style.opacity = '1';
			}, 50);  // You can adjust this delay if needed
		},
		beforeLeave(el) {
			el.style.opacity = '1';
		},
		left(el) {
			setTimeout(() => {
				el.style.opacity = '0';
			}, 50);  // You can adjust this delay if needed
		},

		afterLeave() {
			this.$emit('dismissed', this.tutorialId);
		},
		onResize: helpers.debounce(function () {
			this.setPosition();
		}, 500),
		dismiss() {
			this.show = false; // This will remove the element from the DOM after the transition completes.

			// next tick
			// this.$nextTick(() => {
			// 	this.$emit('dismissed', this.tutorialId);
			// });
			// 	setTimeout(() => {
			//     this.$emit('dismissed', this.tutorialId);
			// }, 0);
		},
		setPosition() {
			// Define a constant for the padding to ensure the tooltip is positioned away from the edges and the target
			const EDGE_PADDING = 10;
			const TARGET_PADDING = 10;

			// Default to the body as the container unless a different container is specified
			let container = document.body;
			if (this.containerSelector) {
				container = document.querySelector(this.containerSelector);
			}
			const containerRect = container.getBoundingClientRect();

			// If no target is specified, position the tooltip in the center of the container
			if (!this.targetSelector) {
				const relativeWidth = container.clientWidth;
				const relativeHeight = container.clientHeight;

				this.position = {
					top: containerRect.top + (relativeHeight - 300) / 2,  // Center the tooltip vertically relative to the container
					left: containerRect.left + (relativeWidth - 300) / 2  // Center the tooltip horizontally relative to the container
				};
				return;
			}

			// If a target is specified, adjust the tooltip position relative to the target element
			const targetElement = document.querySelector(this.targetSelector);

			if (targetElement) {
				const rect = targetElement.getBoundingClientRect();
				let leftPos = rect.left - containerRect.left;  // Adjust the tooltip's left position for the container
				let topPos = rect.top - containerRect.top;    // Adjust the tooltip's top position for the container

				// Ensure the tooltip width does not exceed 90% of the container's width or 300px
				const tooltipWidth = Math.min(300, 0.9 * containerRect.width);

				// Check for potential overflow on the right side and adjust if necessary
				if (leftPos + tooltipWidth + EDGE_PADDING > containerRect.width) {
					leftPos = containerRect.width - tooltipWidth - EDGE_PADDING;
				}

				// Ensure the tooltip does not start before the container's left side
				leftPos = Math.max(leftPos, EDGE_PADDING);

				// Temporarily render the tooltip off-screen to measure its height
				this.position = {
					top: -9999,
					left: -9999
				};

				// Wait for the next DOM update to complete
				this.$nextTick(() => {
					const paddingValue = 5;
					// Set the overlay style with added padding
					this.overlayStyle = {
						top: (rect.top - containerRect.top) - paddingValue + 'px',
						left: (rect.left - containerRect.left) - paddingValue + 'px',
						width: rect.width + paddingValue * 2 + 'px',
						height: rect.height + paddingValue * 2 + 'px'
					};

					const tooltipElement = this.$el.querySelector('.tooltip');
					const tooltipHeight = tooltipElement ? tooltipElement.offsetHeight : 0;

					// If positioning the tooltip below the target element causes overflow, position it above the target
					if (rect.bottom + tooltipHeight + TARGET_PADDING + EDGE_PADDING > window.innerHeight) {
						topPos = (rect.top - containerRect.top) - tooltipHeight - TARGET_PADDING - EDGE_PADDING;
					} else {
						topPos = (rect.bottom - containerRect.top) + TARGET_PADDING + EDGE_PADDING;
					}

					// Update the tooltip position
					this.position = { top: topPos, left: leftPos };
				});
			}
		},

		hideTutorial() {
			this.$emit('hide-tutorial');
		}
	},
	mounted() {
		if (!this.tutorialDone) {
			setTimeout(() => {
				this.show = true;
				this.setPosition();
				this.visible = true;
			}, this.delay);
		}

		// Add the event listener
		window.addEventListener('resize', this.onResize);
	},
	beforeUnmount() {
		// Remove the event listener
		window.removeEventListener('resize', this.onResize);
	},
}
</script>


<style>
.target-overlay {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.4);
	border-radius: 5px;
}

.tooltip {
	position: absolute;
	z-index: 1001;
	background-color: var(--grey-color-light);
	border: 1px solid var(--grey-color);
	padding: 10px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	max-width: 300px;
	width: 90%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 1);
	opacity: 0;
	text-align: left;
}

.tooltip-footer {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	align-items: flex-end;
	/* Adds a little space between the message and the buttons */
}


.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
	{
	opacity: 0;
}

.scale-fade-enter-active,
.scale-fade-leave-active {
	transition: opacity 0.5s, transform 0.5s;
}

.scale-fade-enter,
.scale-fade-leave-to

/* .scale-fade-leave-active in <2.1.8 */
	{
	transform: scale(0.95);
	opacity: 0;
}
</style>