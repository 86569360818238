<template>
  <div class="top-nav">
    <div class="outer-wrapper top-nav-inner">
      <div class="left positioned">
        <router-link
          class="back"
          :to="backRoute"
        >
         <img src="/src/assets/navigation/back.png" alt="Back Button">
        </router-link>
      </div>

      <div class="middle positioned">
        <slot></slot>
      </div>

      <div class="right positioned">
        <LanguageDropdown v-if="selectedLanguage" :selected="selectedLanguage" @language-selected="updateLanguage" />

        <router-link
          class="settings"
          v-if="settingsRoute"
          :to="settingsRoute"
        >
          <img class="settings icon" src="/src/assets/navigation/settings.png" alt="Settings Icon">
        </router-link>

      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import LanguageDropdown from '@/components/LanguageDropdown.vue';

export default {
  components: {
    LanguageDropdown,
  },
  props: {
    backRoute: {
      type: Object,
      default: () => ({ name: 'home' }),
    },
    settingsRoute: { // Route Object but default false doesn't show settings button
      default: false,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('settings', ['selectedLanguage', 'supportedLanguages']),
  },
  methods: {
    ...mapActions({
      setSelectedLanguage: 'settings/setSelectedLanguage',
    }),
    updateLanguage(language) {
      this.showDropdown = false; // close the dropdown

      // Update local language immediately but keep the previous language in case the update fails
      this.previousSelectedLanguage = this.selectedLanguage;
      this.localSelectedLangauge = language;

      this.setSelectedLanguage(language)
        .then(() => {
          // this.localSelectedLangauge = this.selectedLanguage;
        })
        .catch(() => {
          // If the update fails, revert to the previous language
          this.localSelectedLangauge = this.previousSelectedLanguage;
        });
    },
  },
  watch: {

  },
  created() {

  },
}

</script>

<style scoped>
.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--grey-color-light);
  border-top: 1px solid var(--grey-color);
  z-index: 100;
}
.top-nav-inner {
  display: flex;
  justify-content: space-between;
  height: var(--top-bar-height);
  flex-grow: 0;
  flex-shrink: 1;
}

.top-nav .positioned {
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-nav .left {
  padding-left: 5px;
}
.top-nav a {
  width: 30px;
}
.top-nav .left img {
  height: 20px;
  display: block;
  margin: 0 auto;
}
.top-nav .middle {
  overflow: hidden;
}
.top-nav .right {
  padding-right: 10px;
}
.top-nav .right img {
  display: block;
  margin: 0 auto;
}
.top-nav .settings.icon {
  height: 20px;
}

</style>
