const STACK_LIMIT = 10;

const state = {
  latestNonSettingsRoute: null,
  settingsRouteStack: [],
  navigatedFromSettings: false
};

const getters = {
  latestNonSettingsRoute: (state) => state.latestNonSettingsRoute,
  latestSettingsRoute: (state) => state.settingsRouteStack.length > 0 ? state.settingsRouteStack[state.settingsRouteStack.length - 1] : null,
  navigatedFromSettings: (state) => state.navigatedFromSettings
};

const actions = {
  updateLatestNonSettingsRoute({ commit }, route) {
    commit('setLatestNonSettingsRoute', route);
  },
  pushSettingsRoute({ commit }, route) {
    commit('pushSettingsRoute', route);
  },
  popSettingsRoute({ commit }) {
    commit('popSettingsRoute');
  },
  updateNavigatedFromSettings({ commit }, value) {
    commit('setNavigatedFromSettings', value);
  },
  clearSettingsRouteStack({ commit }) {
    commit('setSettingsRouteStack', []);
  }
};

const mutations = {
  setLatestNonSettingsRoute(state, route) {
    state.latestNonSettingsRoute = route;
  },
  pushSettingsRoute(state, route) {
    if (state.settingsRouteStack.length >= STACK_LIMIT) {
      state.settingsRouteStack.shift(); // Remove the oldest route
    }
    state.settingsRouteStack.push(route);
  },
  popSettingsRoute(state) {
    state.settingsRouteStack.pop();
  },
  setNavigatedFromSettings(state, value) {
    state.navigatedFromSettings = value;
  },
  setSettingsRouteStack(state, value) {
    state.settingsRouteStack = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
