<template>
  <div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper">
      <div class="heading-container">
        <h1>404 - Page not found!</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <p>If you think this is an error, please contact support at <a href="mailto:support@polyglotpal.co.uk">support@polyglotpal.co.uk</a></p>
      </div>
    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<script>
import BottomNav from '@/components/BottomNavigation.vue';
import TopNav from '@/components/TopNavigation.vue';

export default {
  components: {
    BottomNav,
    TopNav,
  },
	data() {
		return {

		};
	},
  methods: {

	},
	created() {

	},
};
</script>

<style scoped>

</style>
