const state = {
  errors: [],
};

const getters = {
  errors: (state) => state.errors,
};

const actions = {
  addError({ commit }, error) {
    commit('addError', error);
  },
  removeError({ commit }, error) {
    commit('removeError', error);
  },
};

const mutations = {
  addError(state, error) {
    const isDuplicate = state.errors.some(
      e => e.type === error.type && e.message === error.message && e.status === error.status
    );
    if (!isDuplicate) {
      state.errors.push(error);
    } else {
      console.error('Duplicate error not displayed:', error);
    }
  },
  removeError(state, error) {
    state.errors = state.errors.filter(e => e !== error);
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
