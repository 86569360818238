<template>
  <div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper">
      <div class="items heading-container">
        <h1>Library</h1>
        <div v-if="textsLoadingCount > 0" class="loader"></div>
      </div>
      <nav class="sub-nav spacer large">
        <RouterLink class="button" id="generate" :to="{ name: 'generate-text' }" @click="dismissTutorial({ viewName: 'HomeView', tutorialId: 'generate' })">
          <img class="icon" src="/src/assets/icons/book-white.png" alt="Generate Icon" />
          Generate Story
        </RouterLink>
        <RouterLink class="button" :to="{ name: 'upload-text' }" id="upload" @click="dismissTutorial({ viewName: 'HomeView', tutorialId: 'upload' })">
          <img class="icon" src="/src/assets/icons/upload-white.png" alt="Upload Icon" />
          Upload Text
        </RouterLink>
      </nav>

      <div>
        <div v-if="allTexts.length > 0" class="text-container">
          <TextCard
            class="text"
            v-for="text in allTexts" :key="text.id"
            :text="text"
          />
        </div>
      </div>


    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<script>
import apiClient from '@/helpers/api.js';
import BottomNav from '@/components/BottomNavigation.vue';
import TopNav from '@/components/TopNavigation.vue';
import TextCard from '@/components/TextCard.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BottomNav,
    TopNav,
    TextCard,
  },
	data() {
		return {
		};
	},
  computed: {
    ...mapState('texts', ['textsLoadingCount']),
    ...mapGetters('texts', ['filteredTexts', 'allTexts']),
    ...mapState('settings', ['selectedLanguage']),
  },
  methods: {
    ...mapActions('texts', ['fetchTexts']),
    ...mapActions('tutorial', ['dismissTutorial']),
	},
	async created() {
		await this.fetchTexts({ language: this.selectedLanguage });
	},
};
</script>

<style scoped>
.text-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.text {
  width: 100%;
}
@media (min-width: 645px) {
  .text {
    width: 300px;
  }
}
</style>
