<template>
  <div class="definitionTranslation">
    <div v-if="definitionLoadingCount > 0">
      <div class="loader"></div>
    </div>
    <div v-else>
      <div class="section" >
        <div class="definition">
          <div class="translation" v-if="!relatedDefinition && translation">
            <strong>{{ translation.translation }}</strong>
          </div>
          <div class="translation" :class="genderOutput(relatedDefinition.gender)" v-else>
              <strong>
                <VoiceButton :content="relatedDefinition.word.word" :languageCode="relatedDefinition.language" /> 
                {{ relatedDefinition.word.word }} 
              </strong> <span class="type" v-if="relatedDefinition.type && relatedDefinition.type != ''">({{ relatedDefinition.type }})</span>
              <div class="base" v-if="relatedDefinition.base != relatedDefinition.word.word || definition.base != definition.word.word">{{ relatedDefinition.base }}</div> 
              <div class="gender" v-if="relatedDefinition.gender || definition.gender">{{ genderOutput(relatedDefinition.gender) }}</div>
              <div class="expression" v-if="relatedDefinition.expression && relatedDefinition.expression != ''">"{{ relatedDefinition.expression }}"</div>
          </div>

          <div class="original" v-if="!definition && translation">
            <strong>{{ translation.original }}</strong>
          </div>
          <div class="original" :class="genderOutput(definition.gender)" v-else>
            <strong>
              <VoiceButton :content="definition.word.word" :languageCode="definition.language" />
              {{ definition.word.word }}
            </strong> <span class="type" v-if="definition.type && definition.type != ''">({{ definition.type }})</span>
            <div class="base" v-if="definition.base != definition.word.word || relatedDefinition.base != relatedDefinition.word.word">{{ definition.base }} </div>
            <div class="gender" v-if="definition.gender">{{ genderOutput(definition.gender) }}</div>
            <div class="expression" v-if="definition.expression && definition.expression != ''">"{{ definition.expression }}"</div>
          </div>
        </div>
        <div v-if="!definition || !relatedDefinition">
          <div class="loader"></div>
          Loading more information...
        </div>
        <div v-else>
          <div class="examples" v-for="(sentence) in sentences" :key="sentence.id">
            <!-- {{ sentence.id }} -->
            <div class="sentence">
              <SelectableTextOutput
                  v-if="sentence"
                  :isSelectable="true"
                  :content="sentence.language == sourceLanguage ? sentence.sentence : sentence.translations[0].sentence"
                  :languageCode="sourceLanguage"
                  :highlightPhrase="sentence.language == sourceLanguage ? definition.word.word : relatedDefinition.word.word"
                  @selectWord="(selectedWord, fullSentence, chunkId) => this.$emit('selectWord', selectedWord, fullSentence, chunkId)"
                />
            </div>
            <div class="sentence-translation spacer large">
               <SelectableTextOutput
                  v-if="sentence"
                  :content="sentence.translations[0].language != sourceLanguage ? sentence.translations[0].sentence : sentence.sentence"
                  :languageCode="sentence.translations[0].language != sourceLanguage ? sentence.translations[0].sentence : sentence.sentence"
                  :highlightPhrase="sentence.language != sourceLanguage ? definition.word.word : relatedDefinition.word.word"
                  :isSelectable="false"
                  @selectWord="(selectedWord, fullSentence, chunkId) => this.$emit('selectWord', selectedWord, fullSentence, chunkId)"
                />
             </div>

            <div class="extra">
              <div class="titles">
                <span class="section-title" v-if="notes" @click="toggleSection('notes', 'tips')" :class="{ active: showNotes }">
                  <img class="icon" src="/src/assets/icons/note.png" alt="Notes Icon" />
                  Summary
                </span>
                <span class="section-title" v-if="tips" @click="toggleSection('tips', 'notes')" :class="{ active: showTips }">
                  <img class="icon" src="/src/assets/icons/sun.png" alt="Tips Icon" />
                  Memory Trick
                </span>
              </div>

              <div class="notes" v-show="notes && showNotes">{{ notes }}</div>
              <div class="tips" v-show="tips && showTips">
                <div class="warning spacer small">Notice - these memory tricks may be silly, ridiculous and/or completely useless.</div>
                {{ tips }}
              </div>
            </div>

          </div>
          <v-bottom-sheet inset v-model="showChatArea" class="chat-sheet">
            <v-card
              class="text-center"
              :height="heightValue"
            >
              <div ref="contentWrapper">
                  <ChatComponent
                    v-show="showChatArea"
                    :thread="thread"
                    @close="showChatArea = false"
                    @data-update="updateHeight"
                  />
              </div>
            </v-card>
          </v-bottom-sheet>
          
          <div class="buttons">
            <button id="chat" @click="dismissTutorial({ viewName: 'DefinitionTranslation', tutorialId: 'chat' }); startChat(relatedDefinition.language == sourceLanguage ? relatedDefinition.id : definition.id, relatedDefinition.language != sourceLanguage ? relatedDefinition.id : definition.id)" class="button good">
              <img class="icon" src="/src/assets/icons/chat-square-white.png" alt="Send Icon" />
              More Information
            </button>
            <button class="bad" v-if="showDeleteButton" @click="showDeleteModal = true">
              <img class="icon" src="/src/assets/icons/trash-white.png" alt="Delete Icon" />
              Delete Definition
            </button>
          </div>

          <PopupModal :showModal="showDeleteModal" @close="showDeleteModal = false">
              <h3>Are you sure you want to delete this definition?</h3>
              <p>If this is the only definition for this word, then you will no longer be shown the flashcard.</p>
              <div class="buttons">
                <button class="button bad" @click="deleteTranslation">Delete</button>
                <button class="button neutral" @click="showDeleteModal = false">Cancel</button>
              </div>
          </PopupModal>
          
          <div v-for="tutorial in tutorials" v-if="showTutorial && displayTutorials">
            <TooltipOverlay
              :key="tutorial.id"
              v-if="!tutorial.done && arePreviousTutorialsDone(tutorial.id)"
              :tutorialId="tutorial.id"
              :message="tutorial.message"
              :targetSelector="tutorial.selector"
              :containerSelector="'#lookup-container'"
              :delay="3500"
              @dismissed="dismissTutorial({ viewName: 'DefinitionTranslation', tutorialId: tutorial.id })"
              @hideTutorial="hideTutorial"
            ></TooltipOverlay>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import SelectableTextOutput from '@/components/SelectableTextOutput.vue';
  import ChatComponent from '@/components/ChatComponent.vue';
  import apiClient from "@/helpers/api.js";
  import VoiceButton from '@/components/VoiceButton.vue';
  import { VBottomSheet } from 'vuetify/labs/VBottomSheet' // Labs Vuetify component for bottom-sheet
  import language from '@/helpers/language.js';
  import TooltipOverlay from '@/components/TooltipOverlay.vue';
  import PopupModal from '@/components/PopupModal.vue';

  export default {
    props: ['translation', 'definition', 'relatedDefinition', 'definitionTranslationID', 'sentences', 'notes', 'tips', 'sourceLanguage', 'showDeleteButton', 'displayTutorials'],
    data() {
      return {
        showChatArea: false,
        chatLoadingCount: 0,
        definitionLoadingCount: 0,
        thread: false,
        heightValue: "auto",

        showNotes: false, 
        showTips: false,

        showDeleteModal: false,
      }
    },
    components: {
      SelectableTextOutput,
      ChatComponent,
      VoiceButton,
      VBottomSheet,
      TooltipOverlay,
      PopupModal,
    },
    computed: {
      tutorials() {
        return this.$store.getters['tutorial/getTutorialsForView']('DefinitionTranslation');
      },
      ...mapState('tutorial', ['showTutorial']),
      arePreviousTutorialsDone() {
        return (tutorialId) => this.$store.getters['tutorial/arePreviousTutorialsDone']('DefinitionTranslation', tutorialId);
      },
    },
    methods: {
      ...mapActions('openChats', ['incrementOpenCount', 'decrementOpenCount']),
      ...mapActions('voice', ['speech']),
      ...mapActions('tutorial', ['loadTutorialStatuses', 'dismissTutorial', 'hideTutorial', 'addTutorial', 'removeTutorial']),
      toggleSection(sectionToShow, sectionToHide) {
        if (sectionToShow === 'notes') {
          this.showNotes = true;
          this.showTips = false;
        } else if (sectionToShow === 'tips') {
          this.showTips = true;
          this.showNotes = false;
        } else {
          this.showNotes = false;
          this.showTips = false;
        }
        this.$emit('update');
      },
      updateHeight() {
        this.$nextTick(() => {
          if (this.$refs.contentWrapper) {
            const contentHeight = this.$refs.contentWrapper.clientHeight;
            this.heightValue = contentHeight;
          }
        });
      },
      genderOutput(g) {
        return language.genderOutput(g);
      },
      startChat(definitionID, relatedDefinitionID) {
        // Start a new chat
        this.chatLoadingCount++;
        this.showChatArea = true;

        apiClient
          .post(`/api/chat/threads`, {
            definition_id: definitionID,
            rel_definition_id: relatedDefinitionID,
          })
          .then((response) => {
            this.thread = response.data.thread;
          })
          .catch((error) => {
            console.error(error);
            this.showChatArea = false;
          })
          .finally(() => {
            this.chatLoadingCount--;
          });
      },
      async deleteTranslation() {
        this.definitionLoadingCount++;

        try {
          let response = await apiClient.delete(`/api/translations/${this.definitionTranslationID}`);
          if (response.status === 204) {
            // Hide this component
            this.$emit('deleted', this.definitionTranslationID);
          } else {
            console.error('Error deleting text:', response);
            alert('Issue deleting text. Please try again later.');
          }
          this.definitionLoadingCount--;

        } catch (error) {
          console.error('Error deleting text:', error);
          this.definitionLoadingCount--;
          alert('Issue deleting text. Please try again later.');
        }
      },
    },
    watch: {
      thread(newVal, oldVal) {
        this.updateHeight();
      },
    },

    created() {
      this.loadTutorialStatuses();
    },
    // Add a class to body to allow us to style the bottom sheet (bottom sheet overlay is a child of the body, outside of this component)
    mounted() {
      this.incrementOpenCount();
    },
    beforeUnmount() {
      this.decrementOpenCount();
    },

  }
</script>

<style>
/* Used with the class added on mounted and removed on beforeUnmount, to ensure this only applies to this component */
/* Must be global styles as the bottom sheet is a child of the body, not of this component */
.definition-translation-open .v-bottom-sheet--inset > .v-bottom-sheet__content.v-overlay__content {
  width: 600px;
  max-width: 100%;
}
</style>

<style scoped>
/* Chat area */
.v-card {
   transition: height 100ms ease-out; /* Adjust timing as needed */
   width: 600px;
   max-width: 100%;
   height: 2000px;
   max-height: 90vh;
   background: none;
}
/* end chat area */
.section {
  text-align: center;
}
.section strong {
  font-size: 1.1em;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
.section strong .type {
  font-size: 0.8rem;
  font-weight: normal;
  font-style: italic;
}

.definition {
  /* Other styles for the definition section */
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 0.9rem;
}

.section .definition .original {
  text-align: left;
  padding-left: 10px;
}
.section .definition .translation {
  text-align: right;
  padding-right: 10px;
  border-right: 1px solid #e5e5e5;
}
.section .examples {
  font-style: italic;
  margin-bottom: 5px;
  margin-top: 5px;

}
.section .examples .sentence {
  text-align: left;
  padding-right: 50px;
  margin-bottom: 10px;
  font-size: 0.9em;
}
.section .examples .sentence-translation {
  text-align: right;
  padding-left: 50px;
  font-size: 0.9rem;
}

.section .base {
}

.feminine {
  color: #ff69b4;
}
.masculine {
  color: #6495ed;
}
.neuter {
  color: #49bd6e;
}

.extra {
  font-style: initial;
  text-align: left;
  margin-top: 10px;
  font-size: 0.9em;
  text-align: center;
}
.extra .notes, .extra .tips {
  margin-bottom: 10px;
}
.extra .titles {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.extra .section-title {
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.extra .section-title.active {
  text-decoration: underline;
  color: var(--accent-color);
}
.extra .warning {
  font-size: 0.7rem;
  color: #424a45
}

.loader {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.modal-content .button {
  min-width: 100px;
}
.buttons {
  justify-content: center;
  flex-wrap: wrap;
}
.buttons .button {
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds ellipsis when text overflows */
  min-width: min-content; /* Sets the minimum width to the intrinsic minimum width */
}
</style>
