<template>
  <div class="language dropdown" v-if="!settingsLoading && selectedLanguageProperties">
    <div v-if="localSelectedLangauge" class="dropbtn" @click="showDropdown = !showDropdown">
      <img :src="`/flags/${localSelectedLangauge}-flag.webp`" :alt="selectedLanguageProperties.fullNameEnglish + ' Flag'" />
    </div>
    <div v-else-if="!settingsLoading" class="dropbtn" @click="showDropdown = !showDropdown">
      Select Language
    </div>
    <div class="dropdown-content" v-show="showDropdown">
      <div
        v-for="language in supportedLanguages"
        :key="language.code"
        @click="updateLanguage(language.code)"
        class="dropdown-item">
        <img :src="`/flags/${language.code}-flag.webp`" :alt="language.fullNameEnglish + ' Flag'" />
        <div class="language-name">{{ language.fullNameEnglish }} ({{ language.fullNameOriginal }})</div>
      </div>
    </div>
    <div v-if="showDropdown" class="overlay" @click="closeDropdown"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: ['selected'],
  data() {
    return {
      localSelectedLangauge: null,
      showDropdown: false,
    };
  },
  computed: {
    ...mapState('settings', ['supportedLanguages', 'selectedLanguage']),
    ...mapGetters({
      settingsLoading: 'settings/loading',
      selectedLanguageProperties: 'settings/selectedLanguageProperties',
    }),
  },
  methods: {
    ...mapActions({
      setSelectedLanguage: 'settings/setSelectedLanguage',
    }),
    closeDropdown() {
      this.showDropdown = false;
    },
    updateLanguage(language) {
      this.showDropdown = false;
      this.localSelectedLangauge = language;
      this.$emit('language-selected', language);
    },
  },
  watch: {
    selected(newVal) {
      this.localSelectedLangauge = newVal;
    },
  },
  created() {
    this.localSelectedLangauge = this.selected;
  },
}
</script>

<style scoped>

/* Dropdown */
.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}
.dropdown .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.dropdown-content {
  position: absolute;
  right: 5px;
  min-width: 160px;
  z-index: 2;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.dropdown-content .dropdown-item .language-name {
  text-align: right;
  margin-left: 10px;
}
.dropdown img {
  height: 20px;
  margin-right: 10px;
  display: block;
}
.dropdown-content .dropdown-item:hover {
  background-color: #f1f1f1;
}
</style>
