<template>
  <div>
    <div class="heading-container">
      <h1>Add payment method</h1>
    </div>

    <div v-if="isGuest">
      <p class="spacer">You are currently logged in as a guest.</p> 
      <p>Please <router-link class="button neutral" :to="{ name: 'login' }">login</router-link> or <router-link class="button good" :to="{ name: 'signup' }">sign up</router-link> to manage your subscription.</p>
    </div>

    <div v-else-if="loading">Loading...</div>

    <form @submit.prevent="handlePaymentMethod" v-show="!loading && !isGuest">
      <div id="card-element"></div>
      <div class="error-message" v-if="error">{{ error }}</div>
      <button type="submit">Save Card Details</button> You will not yet be charged.
    </form>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import apiClient from "@/helpers/api.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      stripe: null,
      card: null,
      error: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest',
    }),
  },
  async mounted() {
    this.loading = true;
    let stripeKey = await apiClient.get('/api/stripe-key');
    this.stripe = await loadStripe(stripeKey.data.key);
    const elements = this.stripe.elements();
    this.card = elements.create("card");
    this.card.mount("#card-element");
    this.loading = false;
  },
  methods: {
    async handlePaymentMethod() {
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.card,
      });

      if (error) {
        // Handle error
        this.error = error.message;
      } else {
        // Save payment method
        this.savePaymentMethod(paymentMethod.id);
      }
    },
    async savePaymentMethod(paymentMethod) {
      this.error = null;
      this.loading = true;
      try {
        await apiClient.post('/api/subscriptions/payment-methods', { payment_method: paymentMethod });
        // Handle successful save
        this.$router.push({ name: 'settings', params: { section: 'manage-subscription' } });
      } catch (error) {
        console.error("hit this");
        // Handle the error
        if (error.response && error.response.status === 500) {
            if (error.response.data.exception === "Stripe\\Exception\\CardException") {
                this.error = "Your card was declined. Please use a different card.";
            } else {
                this.error = "There was a problem saving your payment method. Please try again.";
            }
        } else {
            this.error = error.message;
        }
      }
      this.loading = false;
    },
  },
};
</script>


<style scoped>
#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 500px;
  margin-bottom: 10px;
}
</style>
