<template>
	<div class="outer-wrapper">
    <header>
      <TopNav />
    </header>
    <main class="inner-wrapper">
      <div class="heading-container">
        <h1>Privacy & Cookie Policy</h1>
      </div>

      <p class="spacer"><strong>Last Updated: 7th September 2023</strong></p>
      
      <h3>1. Introduction</h3>
      <p class="spacer">Welcome to Polyglot Pal. By using our platform and services, you agree to these terms and conditions. Please read them carefully.</p>

      <h3>2. Use of Our Platform</h3>
      <p class="spacer">You agree not to misuse our platform or services. You must only use the platform in a manner that complies with all applicable laws and regulations.</p>

      <h3>3. Prohibited Content</h3>
      <p class="spacer">You agree not to upload, share, or disseminate any copyrighted material unless you have the right to do so. It's against our terms to upload copyrighted material without authorization.</p>

      <h3>4. Termination of Access</h3>
      <p class="spacer">We reserve the right to remove or disable access to any content that violates our terms. We may also terminate or suspend your account if you breach any provision of these terms.</p>

      <h3>5. Changes to These Terms</h3>
      <p class="spacer">We may modify these terms at any time. If we do so, we will notify you. By continuing to use our platform after changes have been made, you agree to the revised terms.</p>

      <h3>6. Limitation of Liability</h3>
      <p class="spacer">Polyglot Pal and its affiliates will not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our platform or services.</p>

      <!-- <h3>7. Governing Law</h3>
      <p class="spacer">These terms are governed by the laws of [Your Jurisdiction/Country]. Any disputes arising out of or relating to these terms or our platform will be resolved in [Your Jurisdiction/Country].</p> -->

      <h3>9. Subscription Services</h3>
      <p class="spacer">Polyglot Pal offers a paid subscription service. By subscribing to our premium services, you agree to the following:</p>
      <ul>
        <li><strong>Billing:</strong> Subscriptions are billed on a monthly basis. Payments are due at the beginning of each billing cycle.</li>

        <li><strong>Automatic Renewal:</strong> Your subscription will automatically renew at the end of each billing cycle unless you cancel it. Your payment method will be charged the applicable subscription fee unless you cancel your subscription before the renewal date.</li>

        <li><strong>Refunds:</strong> All sales are final, and there will be no refunds for partially used periods.</li>

        <li><strong>Cancellation:</strong> You can cancel your subscription at any time through <router-link :to="{ name: 'settings', params: { section: 'manage-subscription' } }">the account settings on our platform</router-link>. Once you cancel, your subscription will not renew at the end of the billing cycle, but you will retain access until then. If you have any issues cancelling, please contact us at <a href="mailto:contact@polyglotpal.co.uk">contact@polyglotpal.co.uk</a></li>

        <li><strong>Changes to Pricing:</strong> We reserve the right to modify our subscription prices. We will notify you in advance of any price changes, and they will take effect at the start of the next billing cycle after the date of the price change.</li>

        <li><strong>Free Version:</strong> Without a subscription, the app can be used but with limits applied. Abuse of the free version, including attempts to bypass restrictions or excessive usage beyond defined limits, may result in temporary or permanent suspension of access to the free version of the app.</li>
      </ul>

      <h3>10. Disclaimers</h3>
      <p class="spacer">The services provided by Polyglot Pal are offered "as is" and "as available" without any warranties of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Furthermore, Polyglot Pal utilizes OpenAI's technology for generated content. As such, Polyglot Pal does not guarantee the accuracy, relevancy, or completeness of any content generated through OpenAI's platform. Users are advised to use their judgment and verify information before relying on it.</p>

      <h3>11. Limitation of Liability</h3>
      <p class="spacer">To the fullest extent permitted by applicable law, Polyglot Pal and its affiliates, officers, directors, employees, or agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the service; (d) any content, advice, or services obtained from or offered by the service, including those generated by OpenAI's platform; or (e) any use, reliance on, or interaction with any content, advice, or services generated by third-party platforms, including but not limited to OpenAI. Polyglot Pal is not responsible or liable for the accuracy, content, completeness, legality, or reliability of any information generated by third-party platforms like OpenAI.</p>


      <h3>8. Contact Us</h3>
      <p class="spacer">If you have any questions about these terms, please contact us at <a href="mailto:contact@polyglotpal.co.uk">contact@polyglotpal.co.uk</a>.</p>

    </main>
    <footer>
      <BottomNav />
    </footer>
  </div>
</template>

<script>
import TopNav from '@/components/TopNavigation.vue'
import BottomNav from '@/components/BottomNavigation.vue'

export default {
  name: 'TermsConditions',
  components: {
    TopNav,
    BottomNav
  }
}
</script>