<template>
  <span class="voice" @click="playAudio()">
    <!-- Loading symbol -->
    <img v-if="buttonState.isLoading" class="icon" src="/sound/loading.png"/>
    <!-- <span v-if="buttonState.isLoading || voice.isLoading">⏳</span> -->
    <img v-else-if="buttonState.isPlaying" class="icon" src="/sound/stop.png" alt="Play Icon"/>
    <img v-else class="icon" src="/sound/play.png" alt="Play Icon"/>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    content: String,
    languageCode: String,
    shouldAutoPlay: {
      type: Boolean,
      default: false,
    },
    autoPlayComponentID: { // Used to ensure only the correct component will autoplay audio
      type: String,
      default: null,
    }
  },
  computed: {
    ...mapState({
      voice: 'voice'
    }),
    buttonState() {
      return this.voice.buttons[`${this.content}_${this.languageCode}`] || {};
    },
  },
  methods: {
    ...mapActions('voice', ['speech', 'setShouldAutoPlay', 'setAutoPlayComponentID']),
    async playAudio() {
      this.setShouldAutoPlay(this.shouldAutoPlay);
      this.setAutoPlayComponentID(this.autoPlayComponentID);
      this.speech({ id: `${this.content}_${this.languageCode}`, text: this.content, languageCode: this.languageCode });
    },
  },
};
</script>

<style scoped>
.voice {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  height: auto;
}
.voice .icon {
  height: 20px;
  display: block;
  margin: 0 auto;
}
</style>
