<template>
  <div>
    <div class="heading-container">
      <h1>Subscribe</h1>
    </div>

    <div v-if="isGuest">
      <p class="spacer">You are currently logged in as a guest.</p> 
      <p>Please <router-link class="button neutral" :to="{ name: 'login' }">login</router-link> or <router-link class="button good" :to="{ name: 'signup' }">sign up</router-link> to manage your subscription.</p>
    </div>

    <div v-else-if="subscription && subscription.status == 'active'">
      <p class="spacer">You already have a subscription!</p> 
      <p>Manage your subscription <router-link class="button neutral" :to="{ name: 'settings', params: { section: 'manage-subscription' } }">here.</router-link></p>
    </div>

    <div v-else-if="loading">Loading...</div>
    <form v-else-if="plan" @submit.prevent="createSubscription">
      <h2>Plan: {{ plan.name }} (£{{ plan.amount }} {{ plan.currency.toUpperCase() }})</h2>
      <p class="spacer">You will be charged monthly as a recurring payment. You are free to cancel at any time!</p>
      <button type="submit button good">Confirm &amp; Subscribe</button>
    </form>
  </div>
</template>
<script>
import apiClient from "@/helpers/api.js";
import SubTopNav from "@/components/SubTopNavigation.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    SubTopNav,
  },
  data() {
    return {
      loading: false,
      hasPaymentMethod: false,
      subscription: null,
      plan: null,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest',
    }),
  },
  methods: {
    async checkPaymentMethod() {
      const response = await apiClient.get('/api/subscriptions/has-payment-method');
      this.hasPaymentMethod = response.data.hasPaymentMethod;
    },
    async getPlanDetails() {
      const response = await apiClient.get('/api/subscriptions/plan-details');
      this.plan = response.data;
    },
    async fetchSubscription() {
      try {
        const response = await apiClient.get('/api/subscriptions/current');
        this.subscription = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Handle the case where the user does not have a subscription - this is fine
          console.error("No active subscription found");
        } else {
          // Handle other errors
          console.error(error);
        }
      }
    },
    async createSubscription() {
      this.loading = true;
      if (!this.hasPaymentMethod) {
        // Alert the user to add a payment method first
        console.error("No payment method");
        this.loading = false;
        return;
      }
      if (this.subscription && this.subscription.status == 'active') {
        // Alert the user to add a payment method first
        console.error("You already have a subscription. If it is paused, please resume it.");
        this.loading = false;
        return;
      }
      await apiClient.post('/api/subscriptions', { plan: this.plan.id });
      // Handle successful subscription
      // Redirect to manage
      this.$router.push({ name: 'settings', params: { section: 'manage-subscription' } });
      this.loading = false;
    },
  },
  async beforeMount() {
    this.loading = true;
    await Promise.all([this.checkPaymentMethod(), this.getPlanDetails(), this.fetchSubscription()]);
    this.loading = false;
  },
};
</script>
