// This state is used purely for determining how many chats are open at any given time,
// so that we can have a class on the body element to style the page differently when a chat is open.
// This is used for the definitionTranslation component, so that the inset bottom-slider component is correctly styled for width
// (specifically, the overlay matches the width of the chat window, so that clicking outside the chat window closes the chat properly)

// This is meant to ensure that the width global styling for the inset bottom-sliders only applies to chat components,
// however if another bottom-slider is open on the page at the same time, it will apply to this too.
// Doesn't matter at the moment, as we only have one bottom-slider that uses inset

const state = {
  openCount: 0
};

const mutations = {
  INCREMENT_OPEN_COUNT(state) {
    state.openCount++;
    if (state.openCount === 1) {
      document.body.classList.add('definition-translation-open');
    }
  },
  DECREMENT_OPEN_COUNT(state) {
    if (state.openCount > 0) state.openCount--;
    if (state.openCount === 0) {
      document.body.classList.remove('definition-translation-open');
    }
  }
};

const actions = {
  incrementOpenCount({ commit }) {
    commit('INCREMENT_OPEN_COUNT');
  },
  decrementOpenCount({ commit }) {
    commit('DECREMENT_OPEN_COUNT');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
