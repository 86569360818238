const state = {
  tutorials: {
    'HomeView': [
      { id: 'welcome', message: 'Welcome to Polyglot Pal! This is a tool to help you learn languages by reading. <br><br> I\'ll just give you a brief introduction, or you can quit the tutorial if you prefer.', selector: '', done: false },
      { id: 'upload', message: 'Click here to upload your own text. This could be anything that you want to read in your target language.', selector: '#upload', done: false },
      { id: 'generate', message: 'Or if you don\'t have anything specific, click here to generate a story! You can customise it to your tastes.', selector: '#generate', done: false },
      // Flashcards tutorial is added dynamically when content is loaded
      // ... other HomeView tutorials
    ],
    'ViewText': [
      // Tutorial is added dynamically when content is loaded
    ],
    'DefinitionTranslation': [
      // Tutorial is added dynamically when content is loaded
      { id: 'chat', message: 'You can find out lots more information about a word or sentence by clicking here and chatting with the AI Assistant.', selector: '#chat', done: false },
    ],
    'ReviewFlashcards': [
      { id: 'review', message: 'This is a flashcard. Try to remember the translation! <br><br> If you can\'t, that\'s okay. That\'s why we do flashcards in the first place.', selector: '.flashcard-container', done: false },
      { id: 'flip', message: 'Click "Show" to flip the flashcard and see the answer.', selector: '#show-button', done: false },
    ]
    // ... tutorials for other views can be added here
  },
  showTutorial: localStorage.getItem('showTutorial') === 'false' ? false : true,
};


const getters = {
  getTutorialsForView: (state) => (viewName) => {
    return state.tutorials[viewName] || [];
  },
  showTutorial: (state) => state.showTutorial,
  arePreviousTutorialsDone: (state) => (viewName, tutorialId) => {
    if (!state.tutorials[viewName]) {
      return true; // If tutorials for the given view are not defined, default to true
    }
    const tutorialsForView = state.tutorials[viewName];
    const index = tutorialsForView.findIndex(tutorial => tutorial.id === tutorialId);
    if (index === -1) return true; // If tutorialId is not found, default to true
    return tutorialsForView.slice(0, index).every(tutorial => tutorial.done);
  },
};

const actions = {
  loadTutorialStatuses({ commit }) {
    const savedDoneStatuses = JSON.parse(localStorage.getItem('tutorialDoneStatuses')) || {};
    commit('setTutorialStatuses', savedDoneStatuses);
  },
  dismissTutorial({ commit }, { viewName, tutorialId }) {
    commit('setTutorialDone', { viewName, tutorialId });
    const savedDoneStatuses = JSON.parse(localStorage.getItem('tutorialDoneStatuses')) || {};
    savedDoneStatuses[tutorialId] = true;
    localStorage.setItem('tutorialDoneStatuses', JSON.stringify(savedDoneStatuses));
  },
  hideTutorial({ commit }) {
    commit('setShowTutorial', false);
    localStorage.setItem('showTutorial', 'false');
  },
  addTutorial({ commit }, { viewName, tutorial }) {
    const savedDoneStatuses = JSON.parse(localStorage.getItem('tutorialDoneStatuses')) || {};
    tutorial.done = savedDoneStatuses[tutorial.id] || false;
    commit('addTutorialToView', { viewName, tutorial });
  },
  // We have to remove dynamically added tutorials when the user navigates away from the view, to prevent them from being added again when the user navigates back to the view, and having duplicates
  // TODO: Perhaps change the add/remove mechanism, such that they are defined in state always, and are simply TRIGGERED from the view when it's needed.
  removeTutorial({ commit }, { viewName, tutorialId }) {
    commit('removeTutorialFromView', { viewName, tutorialId });
  },

  resetTutorial() {
    localStorage.removeItem('tutorialDoneStatuses');
    localStorage.removeItem('showTutorial');
  },

  resetTutorial({ commit }) {
    commit('resetTutorials');
    commit('resetShowTutorial');
    localStorage.removeItem('tutorialDoneStatuses');
    localStorage.removeItem('showTutorial');
  }
};

const mutations = {
  setTutorialStatuses(state, statuses) {
    Object.keys(state.tutorials).forEach(viewName => {
      state.tutorials[viewName].forEach(tutorial => {
        if (statuses.hasOwnProperty(tutorial.id)) {
          tutorial.done = statuses[tutorial.id];
        }
      });
    });
  },
  setTutorialDone(state, { viewName, tutorialId }) {
    if (!state.tutorials[viewName]) {
      console.warn(`Tutorials for view "${viewName}" are not defined.`);
      return; // Exit the mutation if there are no tutorials for the specified view
    }

    const tutorial = state.tutorials[viewName].find(tut => tut.id === tutorialId);
    if (tutorial) {
      tutorial.done = true;
    }
  },

  setShowTutorial(state, value) {
    state.showTutorial = value;
  },
  addTutorialToView(state, { viewName, tutorial }) {
    if (!state.tutorials[viewName]) {
      Vue.set(state.tutorials, viewName, []);
    }
    const index = state.tutorials[viewName].findIndex(tut => tut.id === tutorial.id);
    if (index >= 0) {
      state.tutorials[viewName][index] = tutorial;
    } else {
      state.tutorials[viewName].push(tutorial);
    }
  },
  removeTutorialFromView(state, { viewName, tutorialId }) {
    if (state.tutorials[viewName]) {
      const index = state.tutorials[viewName].findIndex(tut => tut.id === tutorialId);
      if (index !== -1) {
        state.tutorials[viewName].splice(index, 1);
      }
    }
  },

  resetTutorials(state) {
    Object.keys(state.tutorials).forEach(viewName => {
      state.tutorials[viewName].forEach(tutorial => {
        tutorial.done = false;
      });
    });
  },
  resetShowTutorial(state) {
    state.showTutorial = true; // or whatever default value you want to set
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
